import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { Card, Col, Row, Popover } from 'antd';
import Dropdown, { useDropdown } from '../Dropdown';
import AscIcon from '../../assets/svg/Ascend';
import DescIcon from '../../assets/svg/Descend';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import { useWindowWidth, useToggle } from '../../hooks';
import { FilterButtonBlock } from './style';
import AddButton from '../Button/AddButton';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple, secondary } from '../../style/color';
import Filter from '../../assets/svg/Filter';

export const useFilterButton = () => {
  const [state, setState] = useState('');

  const handler = (val) => {
    setState(val);
  };

  return {
    value: state,
    handler
  };
};

const FilterButton = ({ items, currentFilter, handler }) => {
  const { t } = useContext(LanguageContext);
  const { constants } = useContext(MainContext);

  const onSelect = value => (e) => {
    handler(value);
  };

  useEffect(() => {
    if (!currentFilter) {
      handler(_.get(items, [0, 'value']));
    }
  }, [items]);

  const content = (
    <div
      style={{
        width: '160px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '-12px -16px'
      }}
    >
      {_.map(items, (o, i) => (
        <AddButton
          key={i}
          onClick={onSelect(_.get(o, ['value']))}
          style={{
            width: '100%',
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              currentFilter === _.get(o, ['value']) ? secondary : null
          }}
        >
          <TextBold
            fontSize="15px"
            color={
              currentFilter === _.get(o, ['value'])
                ? '#fff'
                : _.get(o, ['textColor'])
            }
          >
            {_.get(o, ['title'])}
          </TextBold>
        </AddButton>
      ))}
    </div>
  );

  return (
    <Popover
      placement="bottom"
      content={content}
      trigger="click"
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      <AddButton
        style={{
          width: '130px',
          height: '50px',
          backgroundColor: purple
        }}
      >
        <Filter style={{ marginRight: '15px' }} />
        <TextBold fontSize="15px" color="#FFF">
          Filter By
        </TextBold>
      </AddButton>
    </Popover>
  );
};

FilterButton.defaultProps = {
  items: [
    {
      value: 'all',
      title: 'ALL',
      textColor: '#000'
    },
    {
      value: 'pending',
      title: 'Pending Confirm',
      textColor: '#9c3b24'
    },
    {
      value: 'confirm',
      title: 'Confirm',
      textColor: '#279c24'
    },
    {
      value: 'show',
      title: 'Show',
      textColor: '#24619c'
    },
    {
      value: 'no_show',
      title: 'No Show',
      textColor: '#9c3b24'
    },
    {
      value: 'cancel',
      title: 'Cancel Booking',
      textColor: '#0005'
    }
  ],
  handler: () => {}
};

export default FilterButton;
