import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Input, Spin, Button, Modal, Select } from 'antd';
import { MainContext } from '../../context/main';
import { TextNormal } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import { isPassword, isUsername } from '../../utils';
import { LanguageContext } from '../../context/language';

const { Option } = Select;

const DynamicFieldSet = ({ form, open, toggle }) => {
  const { t } = useContext(LanguageContext);

  const [confirmDirty, setConfirmDirty] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { request, constants } = useContext(MainContext);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const handleConfirmBlur = (e) => {
    const value = _.get(e, ['target', 'value']);
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('new_password')) {
      callback(t(constants.ERROR_CONFIRM_NEW_PASSWORD));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm_new_password'], { force: true });
    }
    callback();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        delete values.confirm_new_password;

        const body = _.omitBy({ ...values }, v => v === '');

        setIsSubmitLoading(true);

        const res = await request('/auth/change_password', {
          method: 'post',
          body
        });

        setIsSubmitLoading(false);
        toggle();
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      width={650}
      visible={open}
      title={t(constants.CHANGE_PASSWORD)}
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
        >
          {t(constants.SUBMIT)}
        </Button>
      ]}
    >
      <Form>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.OLD_PASSWORD)}</TextNormal>
          }
          hasFeedback
        >
          {getFieldDecorator('old_password', {
            validateTrigger: [],
            rules: [
              {
                required: true,
                message: t(constants.ERROR_INPUT_OLD_PASSWORD)
              }
            ]
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.NEW_PASSWORD)}</TextNormal>
          }
          hasFeedback
        >
          {getFieldDecorator('new_password', {
            validateTrigger: [],
            rules: [
              {
                pattern: isPassword,
                message: t(constants.ERROR_NEW_PASSWORD)
              },
              {
                required: true,
                message: t(constants.ERROR_INPUT_NEW_PASSWORD)
              },
              {
                validator: validateToNextPassword
              }
            ]
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item
          label={(
            <TextNormal color={purple}>
              {t(constants.CONFIRM_NEW_PASSWORD)}
            </TextNormal>
)}
          hasFeedback
        >
          {getFieldDecorator('confirm_new_password', {
            validateTrigger: [],
            rules: [
              {
                pattern: isPassword,
                message: t(constants.ERROR_NEW_PASSWORD)
              },
              {
                required: true,
                message: t(constants.ERROR_INPUT_CONFIRM_NEW_PASSWORD)
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
