import React, { useContext } from 'react';
import _ from 'lodash';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';
import { MainContext } from '../../context/main';
import { SidebarStyle } from './style';
import NavButton from '../NavButton';
import UserProfile from '../../assets/svg/UserProfile';

const Sidebar = ({ children, history, location }) => {
  const { routes } = useContext(MainContext);

  const handleClick = path => e => history.push(path);

  return (
    <SidebarStyle>
      <NavButton
        onClick={handleClick('/dashboard/profile')}
        active={location.pathname === '/dashboard/profile'}
      >
        <UserProfile active={location.pathname === '/dashboard/profile'} />
        PROFILE
      </NavButton>
      {_.map(
        routes,
        route => route && (
        <NavButton
          key={route.path}
          onClick={handleClick(route.path)}
          active={location.pathname === route.path}
        >
          <route.icon active={location.pathname === route.path} />
          {route.name}
        </NavButton>
        )
      )}
    </SidebarStyle>
  );
};

export default withRouter(Sidebar);
