import React from 'react';

const LeftArrow = ({ width, height, onClick }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.49 22.14"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M10.27.33A1.12,1.12,0,0,1,11.86,1.9l-8,8H30.37a1.11,1.11,0,0,1,1.12,1.11,1.13,1.13,0,0,1-1.12,1.13H3.81l8,8a1.14,1.14,0,0,1,0,1.59,1.12,1.12,0,0,1-1.59,0L.32,11.86a1.13,1.13,0,0,1,0-1.58Z"
          fill="#561747"
        />
      </g>
    </g>
  </svg>
);

LeftArrow.defaultProps = {
  width: '25.604px',
  height: '18px'
};

export default LeftArrow;
