import React, { useContext, useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Popover,
  Button,
  Modal,
  Select,
  Table,
  Divider,
  Popconfirm,
  Tag,
  Spin
} from 'antd';
import { MainContext } from '../../context/main';
import { isEndpointsPath, getPermissionColor, isAllow } from '../../utils';
import { LanguageContext } from '../../context/language';
import { ButtonAnimate } from '../../components/Button/style';
import { TagContainer } from './style';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

const PopOverTag = ({ type, endpointData, permissionID, afterSelect }) => {
  const currentStatus = _.get(endpointData, ['allow', type]);
  const color = getPermissionColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request, constants } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible(prevState => !prevState);
  };

  const handleSelect = newStatus => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {};

    body.id = permissionID;
    body.endpoints_path = _.get(endpointData, 'path');

    switch (type) {
      case 'GET':
        body.endpoints_allow_GET = newStatus;
        break;
      case 'POST':
        body.endpoints_allow_POST = newStatus;
        break;
      case 'PUT':
        body.endpoints_allow_PUT = newStatus;
        break;
      case 'DELETE':
        body.endpoints_allow_DELETE = newStatus;
        break;
      default:
        break;
    }

    setIsLoading(true);

    const res = await request('/permissions', {
      method: 'put',
      body
    });

    setIsLoading(false);
    afterSelect();
    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonAnimate onClick={handleSelect('no')} className="popover_tag">
              <Tag color={currentStatus === 'no' ? 'grey' : 'volcano'}>no</Tag>
            </ButtonAnimate>
            <ButtonAnimate
              onClick={handleSelect('same_branch')}
              className="popover_tag"
            >
              <Tag color={currentStatus === 'same_branch' ? 'grey' : 'green'}>
                same_branch
              </Tag>
            </ButtonAnimate>
            <ButtonAnimate
              onClick={handleSelect('all')}
              className="popover_tag"
            >
              <Tag color={currentStatus === 'all' ? 'grey' : 'geekblue'}>
                all
              </Tag>
            </ButtonAnimate>
          </TagContainer>
        </Spin>
)}
      title="Allow Status"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonAnimate>
        <Tag color={color}>{currentStatus}</Tag>
      </ButtonAnimate>
    </Popover>
  );
};

const DynamicFieldSet = ({
  form,
  open,
  toggle,
  permissionId,
  allow,
  onClose
}) => {
  const { t } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [initPath, setInitPath] = useState('');
  const [initName, setInitName] = useState('');

  const { request, constants } = useContext(MainContext);

  const getData = async () => {
    if (!permissionId) return;
    setIsGetDataLoading(true);
    const res = await request('/permissions', {
      query: {
        id: permissionId
      }
    });

    const referenceData = _.get(res, ['reference_data', 0], []);

    setIsGetDataLoading(false);
    setData(referenceData);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      setData([]);
      getData();
      setIsUpdated(false);
    }
  }, [open]);

  const validateExistPath = (rule, value, callback) => {
    const existPath = _.get(data, 'endpoints');
    const isExist = _.find(existPath, o => o.path === value);

    if (isExist && !isEdit) {
      callback(t(constants.ERROR_ENDPOINT_PATH_EXIST));
    } else {
      callback();
    }
  };

  const setEditEndpoint = ({ path, name }) => (e) => {
    setInitName(name);
    setInitPath(path);
    setIsEdit(true);
  };

  const clearForm = () => {
    setIsEdit(false);
    setInitName('');
    setInitPath('');
  };

  const handleUpdateEndpoints = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setIsSubmitLoading(true);
        const res = await request('/permissions', {
          method: 'put',
          body: {
            id: permissionId,
            endpoints_path: _.get(values, 'endpoints_path'),
            endpoints_name: _.get(values, 'endpoints_name')
          }
        });

        setIsSubmitLoading(false);
        clearForm();
        form.resetFields();
        if (!isUpdated) setIsUpdated(true);
        getData();
      }
    });
  };

  const handleDelete = endpoint => async (e) => {
    const endpointPath = _.get(endpoint, 'path');
    const res = await request('/permissions', {
      method: 'put',
      body: {
        id: permissionId,
        endpoints_path: endpointPath,
        is_remove: true
      }
    });

    if (!isUpdated) setIsUpdated(true);
    getData();
  };

  const afterSelect = () => {
    if (!isUpdated) setIsUpdated(true);
    getData();
  };

  const onCancel = () => {
    toggle();
    if (isUpdated) {
      onClose();
    }
  };

  const { getFieldDecorator } = form;

  const columns = [
    {
      title: t(constants.ENDPOINT),
      dataIndex: 'path',
      key: 'path',
      render: text => <a href="javascript:;">{text}</a>
    },
    {
      title: t(constants.ENDPOINT_NAME),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t(constants.GET),
      key: t(constants.GET),
      render: o => (
        <PopOverTag
          type="GET"
          endpointData={o}
          permissionID={permissionId}
          afterSelect={afterSelect}
        />
      )
    },
    {
      title: t(constants.POST),
      key: t(constants.POST),
      render: o => (
        <PopOverTag
          type="POST"
          endpointData={o}
          permissionID={permissionId}
          afterSelect={afterSelect}
        />
      )
    },
    {
      title: t(constants.PUT),
      key: t(constants.PUT),
      render: o => (
        <PopOverTag
          type="PUT"
          endpointData={o}
          permissionID={permissionId}
          afterSelect={afterSelect}
        />
      )
    },
    {
      title: t(constants.DELETE),
      key: t(constants.DELETE),
      render: o => (
        <PopOverTag
          type="DELETE"
          endpointData={o}
          permissionID={permissionId}
          afterSelect={afterSelect}
        />
      )
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <span>
          <a href="javascript:;" onClick={setEditEndpoint(o)}>
            {t(constants.CHANGE_ENDPOINT_NAME)}
          </a>
          <Divider type="vertical" />
          <Popconfirm
            title={t(constants.SURE_DELETE)}
            onConfirm={handleDelete(o)}
          >
            <a href="javascript:;">{t(constants.DELETE)}</a>
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <Modal
      width="90%"
      className="permission_form_modal"
      visible={open}
      title={t(constants.EDIT_ENDPOINT)}
      // onOk={handleSubmit}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(constants.CLOSE_BUTTON)}
        </Button>
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Form
          style={{
            visibility: allow.POST === 'all' || isEdit ? 'visible' : 'hidden'
          }}
        >
          <Form.Item
            {...formItemLayout}
            label={t(constants.ENDPOINT_PATH)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('endpoints_path', {
              initialValue: initPath,
              validateTrigger: [],
              rules: [
                {
                  required: true,
                  whitespace: false,
                  message: t(constants.ERROR_ENDPOINT_PATH)
                },
                {
                  pattern: isEndpointsPath,
                  message: t(constants.ERROR_ENDPOINT_PATH)
                },
                {
                  validator: validateExistPath
                }
              ]
            })(
              <Input
                disabled={isEdit}
                placeholder={t(constants.INPUT_ENDPOINT_PATH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t(constants.ENDPOINT_NAME)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('endpoints_name', {
              initialValue: initName,
              validateTrigger: [],
              rules: [
                {
                  required: true,
                  whitespace: false,
                  message: t(constants.ERROR_ENDPOINT_NAME)
                }
              ]
            })(<Input placeholder={t(constants.INPUT_ENDPOINT_NAME)} />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              loading={isSubmitLoading}
              type="primary"
              onClick={handleUpdateEndpoints}
            >
              {t(isEdit ? constants.PUT : constants.POST)}
            </Button>
            <Button
              key="back"
              style={{ margin: '0 0 0 15px' }}
              onClick={clearForm}
            >
              {t(constants.CANCEL)}
            </Button>
          </Form.Item>
        </Form>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={_.get(data, ['endpoints'])}
        />
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
