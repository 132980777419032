import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  DatePicker,
  Checkbox,
  Popconfirm,
} from 'antd';
import _, { endsWith } from 'lodash';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray, white } from '../../style/color';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import getDayOfWeek from '../../utils/getDayOfWeek';
import { isAllow, normDate } from '../../utils';
import { weekdaysInMonth } from '../../utils/weekDaysin';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';

const disabledDate = (dateData, current) => {
  const dataDayOff = _.reduce(
    _.get(dateData, 'dayOff'),
    (result, value) => {
      const dateOffList = weekdaysInMonth(
        value,
        'YYYY-MM-DD',
        dateData.currentMonth && dateData.currentMonth.clone()
      );
      return [...result, ...dateOffList];
    },
    []
  );
  _.remove(dataDayOff, o => _.includes(dateData.swapDayOff, o));
  const currentDateString = current.format('YYYY-MM-DD');
  return (
    current < moment().endOf('day')
    || !_.includes(dataDayOff, currentDateString)
  );
};

const PlaningDayModalForm = ({
  allow,
  visible,
  onCancel,
  onCreate,
  onRemove,
  form,
  allowPermission,
  breakPeriod,
  loading,
  allBranches,
}) => {
  const { t } = useContext(LanguageContext);
  const { constants, currentBranch } = useContext(MainContext);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const { getFieldDecorator } = form;
  const dayOfValue = form.getFieldValue('day_off');

  const handleSeleteType = value => () => {
    const newValue = dayOfValue === value ? '' : value;
    form.setFieldsValue({
      day_off: newValue,
    });
  };

  const timeSelectChange = setState => (val) => {
    setState(val);
  };

  useEffect(() => {
    setStart(form.getFieldValue('break_period_start'));
    setEnd(form.getFieldValue('break_period_end'));
  }, [visible]);

  return (
    <Modal
      visible={!!visible}
      title={_.get(visible, 'title', '')}
      okText={t(constants.ADD)}
      onCancel={onCancel}
      onOk={onCreate}
      footer={[
        <Popconfirm
          disabled={!isAllow(allow.DELETE, allowPermission.DELETE)}
          title={t(constants.SURE_DELETE)}
          onConfirm={onRemove}
        >
          <Button
            key="remove"
            type="danger"
            loading={loading}
            style={{ display: _.get(visible, '_id') ? 'inline-block' : 'none' }}
          >
            <TextBold fontSize="14px" color={white}>
              {t(constants.DELETE)}
            </TextBold>
          </Button>
        </Popconfirm>,
        <Button loading={loading} key="back" onClick={onCancel}>
          {t(constants.CANCEL)}
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={onCreate}
        >
          {_.get(visible, '_id') ? t(constants.PUT) : t(constants.POST)}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('id')(<Input />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: '0px' }}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              // help={`${t({
              //   en: 'Select Shift',
              //   th: 'เลือกกะทำงาน',
              // })}`}
              style={{ marginBottom: '0px' }}
              label={(
                <>
                  {/* <TextBold fontSize="14px" color={gray}>
              {t(constants.SHIFT_INFO)}
          </TextBold> */}
                  <TextNormal fontSize="16px" color={purple}>
                    {t(constants.SHIFT_INFO)}
                  </TextNormal>
                </>
)}
            >
              {getFieldDecorator('work_shift', {
                rules: [
                  {
                    required: !form.getFieldValue('day_off'),
                    message: 'เลือกกะทำงาน',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="morning">
                    {t(constants.MORNING)}
                  </Radio.Button>
                  <Radio.Button value="afternoon">
                    {t(constants.AFTERNOON)}
                  </Radio.Button>
                  <Radio.Button value="evening">
                    {t(constants.EVENING)}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: '0px',
                // display: 'flex',
                // alignItems: 'center',
                // paddingTop: '32px',
              }}
              label={(
                <TextNormal fontSize="16px" color={purple}>
                  {t(constants.INPUT_BREAK_PERIOD)}
                </TextNormal>
)}
            >
              <div style={{ display: 'flex' }}>
                <Form.Item>
                  {getFieldDecorator('break_period_start', {
                    rules: [
                      {
                        required: !form.getFieldValue('day_off') || !!end,
                        message: 'เลือกเวลาพัก',
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: '80px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                      // placeholder={t(constants.INPUT_BREAK_PERIOD)}
                      placeholder={t(constants.INPUT_BREAK_PERIOD_START)}
                      onChange={timeSelectChange(setStart)}
                    >
                      <Option key="INPUT_BREAK_PERIOD_START" value={null}>
                        {t(constants.INPUT_BREAK_PERIOD_START)}
                      </Option>
                      {_.map(
                        breakPeriod,
                        (o, i) => i !== _.get(breakPeriod, ['length']) - 1 && (
                        <Option
                          key={o.value}
                          disabled={end ? o.value > end - 60 : false}
                          value={o.value}
                        >
                          {o.title}
                        </Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
                <div
                  style={{
                    marginLeft: '5px',
                    marginRight: '5px',
                    alignItems: 'center',
                    /* justifyContent: "center", */
                    display: 'flex',
                  }}
                >
                  -
                </div>
                <Form.Item>
                  {getFieldDecorator('break_period_end', {
                    rules: [
                      {
                        required: !form.getFieldValue('day_off') || !!start,
                        message: 'เลือกเวลาพัก',
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: '80px',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                      // placeholder={t(constants.INPUT_BREAK_PERIOD)}
                      placeholder={t(constants.INPUT_BREAK_PERIOD_END)}
                      onChange={timeSelectChange(setEnd)}
                    >
                      <Option key="INPUT_BREAK_PERIOD_END" value={null}>
                        {t(constants.INPUT_BREAK_PERIOD_END)}
                      </Option>
                      {_.map(
                        breakPeriod,
                        (o, i) => i !== 0 && (
                        <Option
                          key={o.value}
                          disabled={start ? o.value < start + 60 : false}
                          value={o.value}
                        >
                          {o.title}
                        </Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('is_reception', {
            valuePropName: 'checked',
          })(
            <Checkbox>
              <TextNormal fontSize="14px" color={purple}>
                {t(constants.RECEPTION)}
              </TextNormal>
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item
          label={(
            <TextNormal fontSize="16px" color={purple}>
              {t(constants.CHANGE_BRANCH)}
            </TextNormal>
)}
        >
          {getFieldDecorator('branch_change')(
            <Select placeholder={t(constants.INPUT_USER_BRANCH)}>
              <Option key="INPUT_USER_BRANCH" value="">
                {t(constants.INPUT_USER_BRANCH)}
              </Option>
              {_.map(
                allBranches,
                o => o.id !== 'all'
                  && o.id !== currentBranch && (
                    <Option key={o.id} value={o.id}>
                      {t(o.title)}
                    </Option>
                )
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={(
            <>
              {/* <TextBold fontSize="14px" color={gray}>
              {t(constants.DAY_OFF)}
            </TextBold> */}
              <TextNormal fontSize="16px" color={purple}>
                {t(constants.DAY_OFF)}
              </TextNormal>
            </>
)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleSeleteType('office')}
              type={`${dayOfValue === 'office' ? 'primary' : ''}`}
            >
              {t(constants.GO_OFFICE)}
            </Button>
            <Button
              onClick={handleSeleteType('sick_leave')}
              type={`${dayOfValue === 'sick_leave' ? 'primary' : ''}`}
            >
              {t(constants.SICK_LEAVE)}
            </Button>
            <Button
              onClick={handleSeleteType('holiday')}
              type={`${dayOfValue === 'holiday' ? 'primary' : ''}`}
            >
              {t(constants.HOLIDAY)}
            </Button>
            <Button
              onClick={handleSeleteType('day_off')}
              type={`${dayOfValue === 'day_off' ? 'primary' : ''}`}
            >
              {t(constants.DAYOFF)}
            </Button>
          </div>
          <div style={{ display: 'none' }}>
            {getFieldDecorator('day_off')(
              <Radio.Group>
                <Radio.Button value="office">
                  {t(constants.GO_OFFICE)}
                </Radio.Button>
                <Radio.Button value="sick_leave">
                  {t(constants.SICK_LEAVE)}
                </Radio.Button>
                <Radio.Button value="holiday">
                  {t(constants.HOLIDAY)}
                </Radio.Button>
                <Radio.Button value="day_off">
                  {t(constants.DAYOFF)}
                </Radio.Button>
              </Radio.Group>
            )}
          </div>
        </Form.Item>

        <Form.Item
          style={{ display: dayOfValue === 'holiday' ? 'block' : 'none' }}
          label={(
            <TextBold fontSize="14px" color={gray}>
              {t(constants.REMARK)}
            </TextBold>
)}
        >
          {getFieldDecorator('holiday_detail')(
            <TextArea autosize={{ minRows: 2, maxRows: 6 }} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(PlaningDayModalForm);
