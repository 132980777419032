import React, { useState, useRef, useContext } from 'react';
import { Table, Button, Divider, Popconfirm, Icon } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import ModalForm from './ModalForm';
import { MainContext } from '../../context/main';
import { isAllow } from '../../utils';
import { LanguageContext } from '../../context/language';

const TablePlaning = ({
  currentMonth,
  dataSource,
  getData,
  allow,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const { request, constants, currentBranch } = useContext(MainContext);

  const { t } = useContext(LanguageContext);

  const columns = [
    {
      title: 'Full Name',
      key: 'full_name',
      width: '200px',
      render: obj => _.get(obj, ['full_name'], '')
    },
    {
      title: 'Nickname',
      key: 'nickname',
      width: '200px',
      render: obj => _.get(obj, ['nickname'], '')
    },
    {
      title: 'Branch',
      key: 'branch',
      render: obj => _.get(obj, ['branch', 'name'], '')
    }
  ];

  // const staff = _.map(_.get(dataSource, ['staff'], []), o => ({
  //   ...o,
  //   key: o.id
  // }));
  const staff = _.map(dataSource, o => ({
    ...o,
    key: o.id
  }));

  // const planingOfMonth = _.get(dataSource, 'planingOfMonth', []);

  const onSelectChange = (dataOfselectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', dataOfselectedRowKeys);
    setSelectedRowKeys(dataOfselectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  // console.log({ staff, planingOfMonth });

  return (
    <>
      <Table rowSelection={rowSelection} dataSource={staff} columns={columns} />
    </>
  );
};

export default TablePlaning;
