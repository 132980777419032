import styled from 'styled-components';
import posed from 'react-pose';
import {
  yellow,
  lightBlue,
  darkPurple,
  grapePurple,
  barneyPurple,
  purpleishBlue,
  lighterPurple,
  greenblue,
  white,
  brick
} from '../../style/color';

const ButtonAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: ({ disabled }) => (disabled ? 0.2 : 1) },
  press: {
    scale: ({ disabled }) => (disabled ? 1 : 0.8),
    opacity: ({ disabled }) => (disabled ? 0.2 : 0.5)
  }
});

export const CardBlock = styled.div`
  position: relative;
  width: 150px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
`;
export const CardActivityBlock = styled(ButtonAnimate)`
  width: 140px;
  height: 55px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: ${({ disable }) => (disable ? '' : 'pointer')};
  display: flex;
  flex-flow: column;
  padding: 0 15px;
  align-items: flex-start;
  justify-content: center;

  background-color: ${props => (!props.isReception
    && (props.actionType === 'morning'
      || props.actionType === 'evening'
      || props.actionType === 'afternoon')
    ? white
    : '#eaeaea')};

  border-left: ${(props) => {
    if (props.actionType === 'swap_of_day_off') {
      return `3px solid ${yellow}`;
    }
    if (props.actionType === 'holiday') {
      return `3px solid ${lightBlue}`;
    }
    if (props.actionType === 'sick_leave') {
      return `3px solid ${darkPurple}`;
    }
    if (props.actionType === 'office') {
      return `3px solid ${grapePurple}`;
    }
    if (props.actionType === 'branch_change') {
      return `3px solid ${barneyPurple}`;
    }
    if (props.actionType === 'morning') {
      return `3px solid ${purpleishBlue}`;
    }
    if (props.actionType === 'evening') {
      return `3px solid ${lighterPurple}`;
    }
    if (props.actionType === 'afternoon') {
      return `3px solid ${greenblue}`;
    }
    if (props.actionType === 'dayOff') {
      return `3px solid ${brick}`;
    }
    return 'none';
  }};

  .staff_planning_pencil {
    position: absolute;
    top: 5px;
    right: 15px;
  }
`;

export const PlusBlock = styled.div`
  line-height: 0;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
`;
