import React, { useEffect } from 'react';
import PT from 'prop-types';
import { AddButtonStyle } from './style';

const AddButton = ({ onClick, children, active, disabled, style }) => {
  useEffect(() => {
    // Update the document title using the browser API
  });
  return (
    <AddButtonStyle
      style={style}
      active={active}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
    >
      {children}
    </AddButtonStyle>
  );
};

AddButton.propTypes = {
  onClick: PT.func,
  children: PT.node.isRequired,
  active: PT.bool,
  disabled: PT.bool
};

AddButton.defaultProps = {
  onClick: () => undefined,
  active: false,
  disabled: false
};

export default AddButton;
