import React, { useState } from 'react';
import PT from 'prop-types';
import { Tag, Popover } from 'antd';
import { TagContainer } from '../../screen/PermissionScreen/style';
import { isAllow } from '../../utils';
import { ButtonAnimate } from '../Button/style';

const PopOverTag = ({
  id,
  status,
  onChange,
  allow,
  activeTextItem,
  inactiveTextItem,
  activeTextStatus,
  inactiveTextStatus
}) => {
  const [visible, setVisible] = useState();

  const onVisibleChange = (e) => {
    setVisible(prevState => !prevState);
  };

  const handleChange = () => {
    onChange(id);
    setVisible(prevState => !prevState);
  };

  return (
    <Popover
      content={(
        <TagContainer>
          <ButtonAnimate
            onClick={status ? () => {} : handleChange}
            className="popover_tag"
          >
            <Tag color={status ? 'gray' : 'green'}>{activeTextItem}</Tag>
          </ButtonAnimate>
          <ButtonAnimate
            onClick={!status ? () => {} : handleChange}
            className="popover_tag"
          >
            <Tag color={!status ? 'gray' : 'volcano'}>{inactiveTextItem}</Tag>
          </ButtonAnimate>
        </TagContainer>
)}
      title="Status"
      trigger="click"
      visible={isAllow(allow.PUT) ? visible : false}
      onVisibleChange={isAllow(allow.PUT) ? onVisibleChange : () => {}}
    >
      <ButtonAnimate>
        <Tag color={status ? 'green' : 'volcano'}>
          {status
            ? activeTextStatus || activeTextItem
            : inactiveTextStatus || inactiveTextItem}
        </Tag>
      </ButtonAnimate>
    </Popover>
  );
};

PopOverTag.propTypes = {
  id: PT.string.isRequired,
  status: PT.bool.isRequired,
  onChange: PT.func.isRequired,
  allow: PT.shape({
    GET: PT.string,
    POST: PT.string,
    PUT: PT.string,
    DELETE: PT.string
  }).isRequired,
  activeTextItem: PT.string,
  activeTextStatus: PT.string,
  inactiveTextItem: PT.string,
  inactiveTextStatus: PT.string
};

PopOverTag.defaultProps = {
  activeTextItem: 'Active',
  activeTextStatus: '',
  inactiveTextItem: 'Inactive',
  inactiveTextStatus: ''
};

export default PopOverTag;
