import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="512px"
    viewBox="0 -23 512 512"
    width="512px"
    className=""
  >
    <g>
      <path
        d="m55.734375 41.101562c-1.203125-2.863281-4.089844-4.769531-7.210937-4.6875-3.105469.078126-5.929688 2.105469-6.96875 5.035157-1.066407 3-.121094 6.414062 2.332031 8.4375 2.546875 2.101562 6.296875 2.28125 9.042969.453125 3-2.003906 4.136718-5.90625 2.804687-9.238282zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m115.625 41.101562c-2.539062-6.328124-11.886719-6.015624-14.179688.347657-1.109374 3.085937-.039062 6.644531 2.570313 8.625 2.484375 1.886719 5.976563 2.03125 8.617187.382812 3.097657-1.933593 4.414063-5.976562 2.992188-9.355469zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m85.675781 41.101562c-2.5625-6.054687-11.515625-6.179687-14.042969 0-1.257812 3.070313-.355468 6.675782 2.203126 8.785157 2.445312 2.019531 5.988281 2.269531 8.707031.652343 3.191406-1.894531 4.578125-6 3.132812-9.4375zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m512 75.722656c0-.027344-.003906-.054687-.003906-.082031v-36.996094c0-21.308593-17.335938-38.644531-38.644532-38.644531h-434.707031c-21.308593 0-38.644531 17.335938-38.644531 38.644531v387.730469c0 21.308594 17.335938 38.644531 38.644531 38.644531h7.535157c4.199218 0 7.601562-3.40625 7.601562-7.605469 0-4.199218-3.402344-7.601562-7.601562-7.601562h-7.535157c-12.921875 0-23.4375-10.515625-23.4375-23.4375v-343.050781h481.582031v343.050781c0 12.921875-10.515624 23.433594-23.4375 23.433594h-396.753906c-4.199218 0-7.605468 3.40625-7.605468 7.605468 0 4.199219 3.40625 7.605469 7.605468 7.605469h396.753906c21.308594 0 38.644532-17.335937 38.644532-38.644531v-350.574219c0-.027343.003906-.054687.003906-.078125zm-496.792969-7.605468v-29.472657c0-12.921875 10.515625-23.4375 23.4375-23.4375h434.707031c12.921876 0 23.4375 10.515625 23.4375 23.4375v29.472657zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m173.78125 409.074219v-294.019531c0-4.199219-3.402344-7.605469-7.605469-7.605469h-121.058593c-4.203126 0-7.605469 3.40625-7.605469 7.605469v294.019531c0 4.199219 3.402343 7.601562 7.605469 7.601562h121.058593c4.203125 0 7.605469-3.402343 7.605469-7.601562zm-15.207031-7.605469h-105.855469v-278.808594h105.855469zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m316.53125 416.675781c4.199219 0 7.601562-3.402343 7.601562-7.601562v-294.019531c0-4.199219-3.402343-7.605469-7.601562-7.605469h-121.0625c-4.203125 0-7.605469 3.40625-7.605469 7.605469v294.019531c0 4.199219 3.402344 7.601562 7.605469 7.601562zm-113.460938-294.015625h105.855469v278.808594h-105.855469zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m466.878906 214.519531c4.203125 0 7.605469-3.40625 7.605469-7.605469v-91.859374c0-4.199219-3.402344-7.605469-7.605469-7.605469h-121.058594c-4.203124 0-7.605468 3.40625-7.605468 7.605469v294.019531c0 4.199219 3.402344 7.601562 7.605468 7.601562h121.058594c4.203125 0 7.605469-3.402343 7.605469-7.601562v-171.742188c0-4.199219-3.402344-7.605469-7.605469-7.605469-4.199218 0-7.601562 3.40625-7.601562 7.605469v164.136719h-105.855469v-278.808594h105.855469v84.253906c0 4.199219 3.402344 7.605469 7.601562 7.605469zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m80.011719 302.378906c0 2.957032 2.601562 6.125 6.46875 6.125 2.273437 0 4.414062-1.191406 5.210937-2.898437l39.859375-81.648438c.386719-.769531.46875-1.625.46875-2.207031 0-3.667969-3.519531-6.128906-6.808593-6.128906-2.226563 0-4.003907 1.058594-4.871094 2.902344l-39.75 81.644531c-.367188.738281-.578125 1.542969-.578125 2.210937zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m79.800781 267.171875c5.148438 0 9.230469-1.164063 12.140625-3.464844 3.300782-2.613281 4.972656-6.632812 4.972656-11.949219v-16.082031c0-5.3125-1.671874-9.332031-4.972656-11.945312-2.90625-2.300781-6.992187-3.46875-12.140625-3.46875-14.144531 0-17.113281 8.382812-17.113281 15.414062v16.082031c0 7.035157 2.96875 15.414063 17.113281 15.414063zm5.535157-15.414063c0 3.476563-1.914063 5.308594-5.535157 5.308594-3.597656 0-5.421875-1.785156-5.421875-5.308594v-16.078124c0-3.523438 1.824219-5.308594 5.421875-5.308594 3.621094 0 5.535157 1.835937 5.535157 5.308594zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m114.664062 272.140625v16.082031c0 7.03125 2.972657 15.414063 17.113282 15.414063 5.148437 0 9.234375-1.164063 12.140625-3.46875 3.300781-2.609375 4.972656-6.632813 4.972656-11.945313v-16.082031c0-5.3125-1.671875-9.332031-4.972656-11.945313-2.90625-2.300781-6.992188-3.46875-12.140625-3.46875-14.144532 0-17.113282 8.382813-17.113282 15.414063zm11.691407 0c0-3.523437 1.824219-5.308594 5.421875-5.308594 3.621094 0 5.535156 1.835938 5.535156 5.308594v16.082031c0 3.472656-1.914062 5.308594-5.535156 5.308594-3.597656 0-5.421875-1.785156-5.421875-5.308594zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m385.855469 308.503906c2.269531 0 4.414062-1.191406 5.207031-2.898437l39.863281-81.648438c.382813-.769531.464844-1.625.464844-2.207031 0-3.667969-3.519531-6.128906-6.808594-6.128906-2.226562 0-4 1.058594-4.871093 2.902344l-39.746094 81.644531c-.367188.738281-.578125 1.542969-.578125 2.210937 0 2.957032 2.597656 6.125 6.46875 6.125zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m431.152344 303.636719c5.148437 0 9.230468-1.164063 12.136718-3.46875 3.304688-2.609375 4.976563-6.632813 4.976563-11.945313v-16.082031c0-5.3125-1.671875-9.332031-4.976563-11.945313-2.902343-2.300781-6.988281-3.46875-12.136718-3.46875-14.144532 0-17.113282 8.382813-17.113282 15.414063v16.082031c0 7.03125 2.96875 15.414063 17.113282 15.414063zm5.535156-15.414063c0 3.472656-1.914062 5.308594-5.535156 5.308594-3.597656 0-5.421875-1.785156-5.421875-5.308594v-16.082031c0-3.523437 1.824219-5.308594 5.421875-5.308594 3.621094 0 5.535156 1.835938 5.535156 5.308594zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m396.285156 251.757812v-16.078124c0-5.316407-1.671875-9.335938-4.972656-11.949219-2.90625-2.300781-6.992188-3.46875-12.140625-3.46875-14.144531 0-17.113281 8.382812-17.113281 15.417969v16.078124c0 7.035157 2.96875 15.414063 17.113281 15.414063 5.148437 0 9.234375-1.164063 12.140625-3.464844 3.300781-2.613281 4.972656-6.632812 4.972656-11.949219zm-22.535156-16.078124c0-3.523438 1.824219-5.308594 5.421875-5.308594 3.625 0 5.539063 1.835937 5.539063 5.308594v16.078124c0 3.476563-1.914063 5.308594-5.539063 5.308594-3.597656 0-5.421875-1.785156-5.421875-5.308594zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m271.679688 322.378906c-7.144532 0-12.816407 2.425782-17.34375 7.410156-3.902344 4.296876-6.308594 9.75-8.429688 14.5625-2.667969 6.054688-4.777344 10.835938-8.691406 10.835938-2.941406 0-6.085938-2.683594-6.085938-10.222656 0-5.070313 1.410156-7.792969 2.546875-9.980469.742188-1.4375 1.386719-2.679687 1.386719-4.195313 0-4.570312-5.703125-6.546874-8.613281-6.546874-3.539063 0-6.132813 2.804687-7.703125 8.332031-1.417969 5.003906-1.53125 10.75-1.53125 12.390625 0 7.074218 1.746094 13.527344 4.914062 18.171875 3.769532 5.527343 9.523438 8.449219 16.636719 8.449219 14.167969 0 19.441406-11.34375 23.675781-20.460938 3.078125-6.625 5.734375-12.34375 10.894532-12.34375 4.949218 0 6.703124 5.339844 6.703124 9.910156 0 5.582032-2.640624 9.015625-4.765624 11.769532-1.550782 2.019531-2.894532 3.761718-2.894532 5.824218 0 4.089844 5.351563 7.167969 8.820313 7.167969 7.109375 0 13.582031-11.949219 13.582031-25.070313 0-7.609374-2.050781-14.058593-5.929688-18.648437-4.066406-4.8125-10.003906-7.355469-17.171874-7.355469zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m222.6875 299.339844 63.140625 19.253906c.410156.101562.839844.210938 1.167969.210938 3.738281 0 6.75-5.734376 6.75-10.476563 0-2.957031-1.070313-4.824219-3.09375-5.402344l-12.84375-3.492187v-21.644532l12.84375-3.492187c2.023437-.578125 3.09375-2.445313 3.09375-5.402344 0-4.746093-3.011719-10.476562-6.75-10.476562-.328125 0-.753906.109375-1.175782.214843l-63.132812 19.351563c-4.039062 1.269531-5.472656 6.546875-5.472656 10.679687 0 4.132813 1.433594 9.40625 5.472656 10.675782zm15.320312-10.675782 27.128907-7.390624v14.777343zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m222.828125 251.632812h65.824219c2.453125 0 5.09375-2.242187 5.09375-7.164062v-32.703125c0-2.304687-2.242188-4.785156-7.164063-4.785156s-7.164062 2.480469-7.164062 4.785156v23.46875h-56.589844c-2.304687 0-4.992187 2.148437-4.992187 8.199219 0 6.050781 2.6875 8.199218 4.992187 8.199218zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m222.929688 199.675781h65.722656c2.453125 0 5.09375-2.242187 5.09375-7.164062v-36.84375c0-2.484375-2.183594-4.992188-7.0625-4.992188-4.277344 0-7.265625 2.050781-7.265625 4.992188v27.609375h-17.34375v-15.398438c0-1.59375-.710938-2.914062-2.058594-3.816406-1.113281-.746094-2.632813-1.175781-4.171875-1.175781-3.203125 0-6.441406 1.714843-6.441406 4.992187v15.398438h-17.238282v-27.609375c0-2.941407-2.988281-4.992188-7.269531-4.992188-4.878906 0-7.058593 2.507813-7.058593 4.992188v36.84375c0 4.921875 2.640624 7.164062 5.09375 7.164062zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
    </g>
  </svg>
);
