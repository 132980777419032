import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="512px"
    viewBox="0 -19 419.34799 419"
    width="512px"
    className=""
  >
    <g>
      <path
        d="m401.355469 40.117188c-41.402344-16.570313-86.8125-20.382813-130.398438-10.945313l-249.984375 54.054687c-4.34375.9375-8.878906.070313-12.570312-2.398437l-1.09375-.734375c-5.570313-3.714844-8.328125-10.4375-6.964844-16.992188 1.363281-6.554687 6.574219-11.621093 13.164062-12.800781l265.527344-47.648437c50.796875-9.113282 102.839844 7.207031 139.335938 43.695312l.976562.984375zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m372.554688 381.226562v-224h32v224zm0-256h32v16h-32zm32-56v40h-32v-56zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
      <path
        d="m325.355469 223.292969-.6875-2.730469c6.820312-5.703125 13.179687-11.9375 19.015625-18.644531l26.984375-31.480469-12.160157-10.402344-26.976562 31.488282c-13.167969 15.386718-29.289062 27.96875-47.40625 37.007812l-1.359375.679688c-21.101563 10.507812-44.351563 15.984374-67.921875 16h-24.34375c-47.808594-.003907-92.824219-22.515626-121.511719-60.757813l-3.632812-4.824219c-10.605469-14.066406-27.183594-22.355468-44.800781-22.402344v16c12.585937.03125 24.425781 5.953126 32 16l3.632812 4.800782c.984375 1.320312 2.074219 2.535156 3.097656 3.816406l-2.570312 5.136719c-6.257813 12.617187-17.3125 22.203125-30.6875 26.617187l5.058594 15.199219c17.152343-5.667969 31.375-17.875 39.582031-33.96875 6.625 6.816406 13.816406 13.054687 21.503906 18.648437-5.054687 18.683594-14.90625 35.722657-28.574219 49.421876l-.683594.671874 11.28125 11.347657.695313-.691407c14.460937-14.476562 25.210937-32.230468 31.335937-51.75 8.308594 4.796876 17.007813 8.875 26.007813 12.191407-.71875 17.933593-5.199219 35.515625-13.148437 51.609375l-1.691407 3.375 14.320313 7.152344 1.679687-3.375c8.351563-16.78125 13.355469-35.027344 14.730469-53.722657 12.867188 3.363281 26.085938 5.175781 39.382812 5.402344l-6.886718 55.125 15.871094 1.984375 7.128906-56.992188h11.199218c4.871094 0 9.734376-.285156 14.589844-.710937l7.203125 57.703125 15.871094-1.984375-7.199219-57.847656c8.84375-1.632813 17.546875-3.964844 26.023438-6.976563 3.957031 19.378906 11.636718 37.804688 22.617187 54.257813l13.308594-8.878907c-10-14.96875-16.953125-31.761718-20.46875-49.417968l-.441406-2.199219c1.191406-.558594 2.398437-1.078125 3.589843-1.664063l1.363282-.679687c6.820312-3.488281 13.394531-7.4375 19.679687-11.816406 5.492188 18.363281 17.21875 34.230469 33.160157 44.871093l8.878906-13.3125c-13.785156-9.191406-23.644532-23.199218-27.640625-39.277343zm0 0"
        className="active-path"
        fill={active ? white : secondary}
      />
    </g>
  </svg>
);
