import React, { useReducer } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import PT from 'prop-types';
import moment from 'moment';
import 'moment/locale/th';

const CHANGE = 'changeLanguage';

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE:
      return { ...state, lang: action.payload };
    default:
      return { ...state };
  }
};

const initialState = { lang: 'th' };
const LanguageContext = React.createContext();

const availableLanguages = ['en', 'th'];

const LanguageProvider = ({ children }) => {
  const [{ lang }, dispatch] = useReducer(reducer, initialState);

  moment.locale(lang);

  const setLang = (language) => {
    if (!_.includes(availableLanguages, language)) {
      dispatch({ type: CHANGE, payload: availableLanguages[0] });
    } else {
      dispatch({ type: CHANGE, payload: language });
    }
  };

  const translate = (content) => {
    let message = _.get(content, lang);
    if (!message) {
      message = _.get(content, 'en', content || '');
    }

    return message;
  };

  return (
    <LanguageContext.Provider value={{ lang, setLang, t: translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PT.node.isRequired
};

LanguageProvider.defaultProps = {};

export { LanguageContext, LanguageProvider };
