import styled from 'styled-components';

export const TextBold = styled.span`
  font-family: 'Kanit';
  font-size: ${props => props.fontSize || '18px'};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color || '#000000'};
`;

export const TextNormal = styled.span`
  font-family: 'Kanit';
  font-size: ${props => props.fontSize || '18px'};
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color || '#000000'};
`;
