const imageExists = (imageUrl) => {
  // eslint-disable-next-line no-undef
  try {
    const http = new XMLHttpRequest();

    http.open('HEAD', imageUrl, false);
    http.send();

    http.onerror = e => false;

    return http.status !== 404;
  } catch (error) {
    return false;
  }
};

export const getImagePath = (filename, location) => {
  const prefix = process.env.NODE_ENV === 'development' ? '' : '.';
  let imageUrl = `${prefix}/content/medias/${filename}`;

  if (!location) {
    return imageUrl;
  }
  const isImageExists = imageExists(imageUrl);
  if (isImageExists && filename !== '') {
    return imageUrl;
  }
  if (location === 'location-mbk') {
    imageUrl = `${prefix}/logo/default-mbk-logo.jpg`;
  } else if (location === 'location-alaart') {
    imageUrl = `${prefix}/logo/default-alaart-logo.jpg`;
  }
  return imageUrl;
};

export const getFilePath = fileName => (process.env.NODE_ENV === 'development' ? `/${fileName}` : `/${fileName}`);
