import React, { useState, useContext, useEffect, useRef } from 'react';
import { Layout, Drawer, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import Dashboard from '../../components/Dashboard';
import { useWindowWidth } from '../../hooks';
import { getTime } from '../../utils';
import { LanguageContext } from '../../context/language';
import { MainContext } from '../../context/main';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock,
} from '../../style/main';
import MonthPickerComponent from '../../components/MonthPickerComponent';
import AddButton from '../../components/Button/AddButton';
import Plus from '../../assets/svg/Plus';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple } from '../../style/color';
import TablePlaning from './TablePlaning';
import PlaningOfDay from './PlaningOfDay';
import { ColorBlock, ColorBlockWrapper } from './style';

const { Content } = Layout;

const StaffPlaningScreen = ({ allow, isBranchesFilter, allowPermission }) => {
  const formRef = useRef(null);

  const { t, lang } = useContext(LanguageContext);
  const { request, constants, currentBranch } = useContext(MainContext);
  // const { request, constants } = useContext(MainContext);

  // const currentBranch = '5d70af2c7cf9b342455ef178';

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [allStaff, setAllStaff] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [managePlaning, setManagePlaning] = useState(false);
  const [managePlaningLoading, setManagePlaningLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const resStaff = await request('/staff', {
      query: {
        branch: currentBranch || 'all',
        per_page: 100,
      },
    });

    const planingOfMonth = await request('/month-plan', {
      query: {
        branch: currentBranch || 'all',
        month: moment(currentMonth).format('YYYY-MM-DD'),
      },
    });

    const staffPlaningOfMonth = _.map(_.get(planingOfMonth, ['staffs']), o => _.get(o, ['staff', 'id']));
    setSelectedRowKeys(staffPlaningOfMonth);

    // console.log({
    //   staff: _.get(resStaff, ['reference_data'], []),
    //   planingOfMonth,
    //   staffPlaningOfMonth,
    // });

    setAllStaff(_.get(resStaff, ['reference_data'], []));

    // setData({
    //   staff: _.get(resStaff, ['reference_data'], []),
    //   planingOfMonth,
    // });
    setData(planingOfMonth);
    setIsLoading(false);
  };

  const onMount = () => {
    if (currentBranch === 'all') {
      setData([]);
      setSelectedRowKeys([]);
      return;
    }
    if (currentBranch && currentBranch !== 'all') {
      getData();
    }
  };

  useEffect(() => {
    onMount();
  }, [currentBranch, currentMonth]);

  const handleAddStaffPlaningInMonth = () => {
    setManagePlaning(true);
  };

  const closeDrawerManagePlaning = () => {
    setManagePlaning(false);
  };

  const handleSetPlan = async () => {
    const planingOfMonthId = _.get(data, ['id']);

    const staffSelectedWithServe = _.map(
      _.get(data, ['staffs']),
      o => _.get(o, ['staff', 'id'])
    );
    const addStaff = _.difference(selectedRowKeys, staffSelectedWithServe);
    const removeStaff = _.difference(staffSelectedWithServe, selectedRowKeys);

    const body = {
      staffs: addStaff,
    };
    if (planingOfMonthId) {
      _.set(body, 'id', planingOfMonthId);
      _.set(body, 'remove_staffs', removeStaff);
    } else {
      _.set(body, 'branch_id', currentBranch);
      _.set(body, 'month', moment(currentMonth).format('YYYY-MM-DD'));
    }

    setManagePlaningLoading(true);
    const res = await request('/month-plan', {
      method: planingOfMonthId ? 'put' : 'post',
      body: planingOfMonthId ? body : _.omit(body, ['id']),
    });
    setManagePlaningLoading(false);
    if (_.get(res, ['success'])) {
      // console.log({ res });
      getData();
      closeDrawerManagePlaning();
    }
  };

  return (
    <>
      <HeaderContentBlock style={{ paddingBottom: '30px' }}>
        <MonthPickerComponent
          current={currentMonth}
          setCurrent={setCurrentMonth}
        />
        <AddButtonBlock responsive>
          <AddButton onClick={handleAddStaffPlaningInMonth}>
            <Plus style={{ marginRight: '15px' }} />
            <TextBold color={purple}>{t(constants.MANAGE_PLANING)}</TextBold>
          </AddButton>
        </AddButtonBlock>
      </HeaderContentBlock>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px' }}>
            <div
              style={{
                background: '#fff',
                minHeight: 380,
              }}
            >
              <PlaningOfDay
                allowPermission={allowPermission}
                allow={allow}
                isLoading={isLoading}
                getData={getData}
                currentMonth={currentMonth}
                planingOfMonth={data}
                setPlaningOfMonth={setData}
                // planingOfMonth={_.get(data, 'planingOfMonth', [])}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px' }}>
            <div
              style={{
                // background: 'salmon',
                display: 'flex',
                flexFlow: 'row wrap',
                // marginTop: '10px',
                justifyContent: 'space-around',
                alignItems: 'center',
                height: '100px',
              }}
            >
              {/* <ColorBlockWrapper>
                <ColorBlock type="swap_of_day_off" />
                <TextNormal color={purple}>
                  {t(constants.SWAP_OF_DAY_OFF)}
                </TextNormal>
              </ColorBlockWrapper> */}
              <ColorBlockWrapper>
                <ColorBlock type="holiday" />
                <TextNormal color={purple}>{t(constants.HOLIDAY)}</TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="dayOff" />
                <TextNormal color={purple}>
                  {t(constants.MAIN_HOLIDAY)}
                </TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="sick_leave" />
                <TextNormal color={purple}>
                  {t(constants.SICK_LEAVE)}
                </TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="branch_change" />
                <TextNormal color={purple}>
                  {t(constants.CHANGE_BRANCH)}
                </TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="morning" />
                <TextNormal color={purple}>
                  {t(constants.MORNING_SHIFT)}
                </TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="afternoon" />
                <TextNormal color={purple}>
                  {t(constants.AFTERNOON_SHIFT)}
                </TextNormal>
              </ColorBlockWrapper>
              <ColorBlockWrapper>
                <ColorBlock type="evening" />
                <TextNormal color={purple}>
                  {t(constants.EVENING_SHIFT)}
                </TextNormal>
              </ColorBlockWrapper>
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Drawer
        width="calc(100% - 180px)"
        title={<TextBold>{t(constants.MANAGE_STAFF_PLANING)}</TextBold>}
        placement="right"
        closable
        onClose={closeDrawerManagePlaning}
        visible={managePlaning}
        // visible
      >
        <TablePlaning
          isLoading={isLoading}
          // dataSource={data}
          dataSource={allStaff}
          getData={getData}
          currentMonth={currentMonth}
          allow={allow}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 2,
          }}
        >
          <Button loading={managePlaningLoading} onClick={closeDrawerManagePlaning} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button loading={managePlaningLoading} onClick={handleSetPlan} type="primary">
            Submit
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default StaffPlaningScreen;
