import React, { useState, useContext, useEffect } from 'react';
import { Layout, Table, Icon, Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock
} from '../../style/main';
import { TextBold } from '../../style/fontStyle';
import { purple } from '../../style/color';
import AddButton from '../../components/Button/AddButton';
import Filter, { useFilter } from '../../components/Filter';
import { useDropdown } from '../../components/Dropdown';
import Plus from '../../assets/svg/Plus';

import { MainContext } from '../../context/main';
import {
  formatPhoneNumber,
  isAllow,
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage,
  getTime
} from '../../utils';
import { LanguageContext } from '../../context/language';
import { useToggle, useDebounce } from '../../hooks';
import Form from './form';

const { Content } = Layout;

const BranchScreen = ({ allow, isBranchesFilter, allowPermission }) => {
  const { t } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchId, setBranchId] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const { open, toggle } = useToggle();
  const {
    request,
    currentBranch,
    constants,
    refreshBranchFilter,
    isDesktop
  } = useContext(MainContext);
  const [isSwitchLoading, setIsSwitchLoading] = useState('');
  const { open: desc, toggle: changeDesc } = useToggle();
  const { value: currentSortKey, handleSelect: handleSortKey } = useDropdown();
  const {
    value: currentFilterKey,
    handleSelect: handleFilterKey
  } = useDropdown();

  const { value: currentFilterValue, handler } = useFilter();

  const getData = async (params) => {
    if (!currentBranch && currentBranch === '') {
      return;
    }
    setIsLoading(true);
    const res = await request('/branches', {
      query: {
        id: currentBranch,
        includes: ['hq', 'not_active'],
        ...params
      }
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);

    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [currentBranch]);

  const openForm = branch => (e) => {
    const id = _.get(branch, 'id');
    setBranchId(id);
    toggle();
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getData({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  const onSubmit = () => {
    getData();
    refreshBranchFilter();
  };

  const handleChangeActive = (id, currentStatus) => async () => {
    setIsSwitchLoading(id);
    try {
      const res = await request('/branches', {
        method: 'put',
        body: { id, is_active: !currentStatus }
      });

      if (res) {
        getData();
        refreshBranchFilter();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSwitchLoading('');
    }
  };

  const columns = [
    {
      title: t(constants.BRANCH_CODE),
      dataIndex: 'code',
      key: 'code',
      width: '20%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.BRANCH_CODE)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.BRANCH),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.BRANCH)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.BRANCH_LOCATION),
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.BRANCH_LOCATION)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.PHONE),
      dataIndex: 'telephones',
      key: 'telephones',
      width: '20%',
      render: telephones => _.map(telephones, p => <p key={p}>{formatPhoneNumber(p)}</p>)
    },
    {
      title: 'Active',
      key: 'Active',
      width: '10%',
      render: o => (
        <div>
          <Switch
            disabled={!isAllow(allow.PUT)}
            onChange={handleChangeActive(
              _.get(o, ['id']),
              _.get(o, ['is_active'])
            )}
            loading={isSwitchLoading === _.get(o, ['id'])}
            // defaultChecked={_.get(o, ['is_active'])}
            checked={_.get(o, ['is_active'])}
          />
        </div>
      )
    },
    {
      title: '',
      key: 'unique',
      width: '10%',
      render: o => (
        <span>
          <Icon
            onClick={openForm(o)}
            type={!isAllow(allow.PUT, allowPermission.PUT) ? 'zoom-in' : 'edit'}
            className="custom"
          />
        </span>
      )
    }
  ];

  const debouncedFilterValue = useDebounce(currentFilterValue, 500);

  useEffect(() => {
    if (currentSortKey && currentFilterKey && !_.isNil(debouncedFilterValue)) {
      getData({
        ...(debouncedFilterValue && {
          [currentFilterKey]: debouncedFilterValue
        }),
        sort: { id: currentSortKey, desc }
      });
    }
  }, [debouncedFilterValue, currentFilterKey]);

  useEffect(() => {
    if (currentSortKey) {
      getData({
        sort: { id: currentSortKey, desc }
      });
    }
  }, [desc, currentSortKey]);

  const sortItems = [
    { id: 'code', title: t(constants.BRANCH_CODE) },
    { id: 'name', title: t(constants.BRANCH_NAME) },
    { id: 'location', title: t(constants.BRANCH_LOCATION) }
  ];

  const filterItems = [
    { id: 'code', title: t(constants.BRANCH_CODE) },
    { id: 'name', title: t(constants.BRANCH_NAME) },
    { id: 'location', title: t(constants.BRANCH_LOCATION) }
  ];

  return (
    <>
      <HeaderContentBlock>
        <AddButtonBlock>
          {isAllow(allow.POST, allowPermission.POST) && (
            <AddButton onClick={openForm(null)}>
              <Plus style={{ marginRight: '15px' }} />
              <TextBold color={purple}>{`${t(constants.ADD_BRANCH)}`}</TextBold>
            </AddButton>
          )}
        </AddButtonBlock>
      </HeaderContentBlock>
      <Filter
        sortItems={sortItems}
        currentSortKey={currentSortKey}
        handleSortKey={handleSortKey}
        filterItems={filterItems}
        currentFilterKey={currentFilterKey}
        handleFilterKey={handleFilterKey}
        desc={desc}
        changeDesc={changeDesc}
        filterValue={currentFilterValue}
        onChange={handler}
      />
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
                dataSource={data}
                scroll={{ y: '35vh' }}
                // rowClassName={(record, index) => {
                //   console.log({
                //     record,
                //     index
                //   });
                //   return '';
                // }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form
        allowPermission={allowPermission}
        branchId={branchId}
        open={open}
        toggle={toggle}
        onSubmit={onSubmit}
        allow={allow}
      />
    </>
  );
};

export default BranchScreen;
