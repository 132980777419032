import styled from 'styled-components';
import { TextNormal } from '../../style/fontStyle';
import {
  yellow,
  lightBlue,
  darkPurple,
  grapePurple,
  barneyPurple,
  purpleishBlue,
  lighterPurple,
  greenblue,
  meduimGray,
  darkGray,
  white,
  brick
} from '../../style/color';

export const TableBlock = styled.div`
  .ant-table-body,
  .ant-table-thead {
    th {
      color: ${darkGray} !important;
      text-align: center;
      background-color: ${meduimGray} !important;
    }
    td {
      padding: 8px 5px;
      vertical-align: middle;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 8px 5px;
  }
`;

export const ColorBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorBlock = styled.div`
  width: 34px;
  height: 5px;
  margin: 0 10px 0 0;
  background: ${({ type }) => {
    switch (type) {
      case 'swap_of_day_off':
        return yellow;
      case 'holiday':
        return lightBlue;
      case 'sick_leave':
        return darkPurple;
      case 'office':
        return grapePurple;
      case 'branch_change':
        return barneyPurple;
      case 'morning':
        return purpleishBlue;
      case 'afternoon':
        return greenblue;
      case 'evening':
        return lighterPurple;
      case 'dayOff':
        return brick;
      default:
        return 'transparent';
    }
  }};
`;

export const ShiftBlock = styled(TextNormal)`
  margin: 0 0 0 20px;
  color: ${({ shift }) => {
    switch (shift) {
      case 'morning':
        return purpleishBlue;
      // return '#517cff';
      case 'afternoon':
        return greenblue;
      // return '#22bb85';
      case 'evening':
        return lighterPurple;
      // return '#a641f4';
      default:
        return 'transparent';
    }
  }};
`;
