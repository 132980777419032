export const primary = '#44193b';

export const secondary = '#9E4188';

export const white = '#fff';

export const lighterPurple = '#a641f4';
export const barneyPurple = '#c70aa5';
export const grapePurple = '#571948';
export const purple = '#561747';
export const darkPurple = '#6d24a8';

export const gray = '#bebebe';

export const meduimGray = '#e5e2e4';
export const darkGray = '#727272';

export const yellow = '#ffdb31';

export const greenblue = '#22bb85';
export const lightBlue = '#54a3ed';
export const purpleishBlue = '#414cf4';

export const brick = '#ba2222';
