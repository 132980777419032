import styled from 'styled-components';

export const ReportStyle = styled.div`
  /* width: 100%; */
  /* height: 380px; */
  /* display: flex; */
  /* justify-content: center; */
  /* justify-content: space-around; */
  /* align-items: center; */

  /* .report_button {
    width: 325px;
    height: 304px;
    font-size: 2.5rem;
    justify-content: space-around;
    padding: 3% 0 0;
    margin: 0 60px;
    border-radius: 10px;
    svg {
      transform: scale(2.5);
    }
  } */

  margin-top: 10px;

  label.ant-radio-button-wrapper {
    margin: 0px 10px !important;
  }
`;
