import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Button,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  Icon,
  Popover,
  Drawer,
  Spin,
  Popconfirm,
  Checkbox,
} from 'antd';
import _ from 'lodash';
import { TextNormal } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import { isAllow, isPhoneNumber } from '../../utils';
import { getOptionalCourseIdWithSpecialCourseSelected } from './func';

const { Option } = Select;
const { TextArea } = Input;

const ModalForm = ({
  allow,
  visible,
  onCancel,
  onCreate,
  onDelete,
  form,
  data,
  roomData,
  allowPermission,
  isFormLoading,
}) => {
  const { t } = useContext(LanguageContext);
  const { constants, request } = useContext(MainContext);

  const [fetchingCustomer, setFetchingCustomer] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [serchPopoverVisible, setSerchPopoverVisible] = useState(false);
  const [
    optionalCourseIdWithSpecialCourseSelected,
    setOptionalCourseIdWithSpecialCourseSelected,
  ] = useState([]);

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const isCreate = !getFieldValue('booking_id');

  const staffId = _.get(roomData, 'staffId');
  const staffData = _.chain(_.get(data, ['available_staff']))
    .filter((o) => {
      const result = _.some(o.service_periods, (staffPeriod) => {
        if (roomData.type === 'half') {
          return (
            moment(staffPeriod.time_format, 'HH:mm').format('HH')
              === moment()
                .startOf('day')
                .add(_.toSafeInteger(roomData.time), 'm')
                .format('HH')
            || (staffId && staffId === _.get(o, ['staff', '_id']))
          );
        }
        return (
          (roomData.time === staffPeriod.time
            && staffPeriod.duration === 60
            && staffPeriod.is_available)
          || (staffId && staffId === _.get(o, ['staff', '_id']))
        );
      });

      return result;
    })
    .value();

  const specialCourses = _.filter(
    data.courses,
    course => _.get(course, 'course.type') === 'special'
  );
  const mainCourses = _.filter(
    data.courses,
    course => _.get(course, 'course.type') === 'main'
  );
  const optionalCourses = _.filter(
    data.courses,
    course => _.get(course, 'course.type') === 'optional'
  );

  const setCourse = (courseId, type, removeOnly) => () => {
    let tempDataSelected = [];
    tempDataSelected = getFieldValue(type) || [];
    if (_.includes(tempDataSelected, courseId) || removeOnly) {
      const arrayOFcourseId = typeof courseId === 'string' ? [courseId] : courseId;
      _.remove(tempDataSelected, dataSelect => _.includes(arrayOFcourseId, dataSelect));
    } else {
      tempDataSelected.push(courseId);
    }
    setFieldsValue(
      {
        [type]: tempDataSelected,
      },
      () => {
        if (type === 'special') {
          const tempData = getOptionalCourseIdWithSpecialCourseSelected(
            specialCourses,
            getFieldValue('special')
          );
          setOptionalCourseIdWithSpecialCourseSelected(tempData);
          if (_.size(tempData) > 0) {
            setCourse(tempData, 'main', true)();
            setCourse(tempData, 'optional', true)();
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!visible) {
      setOptionalCourseIdWithSpecialCourseSelected([]);
    } else {
      const tempData = getOptionalCourseIdWithSpecialCourseSelected(
        specialCourses,
        getFieldValue('special')
      );
      setOptionalCourseIdWithSpecialCourseSelected(tempData);
      if (_.size(tempData) > 0) {
        setCourse(tempData, 'main', true)();
        setCourse(tempData, 'optional', true)();
      }
    }
  }, [visible]);

  const findCustomer = _.debounce(async (value) => {
    setFetchingCustomer(true);
    if (value) {
      const resPhone = request('/customers', {
        query: {
          telephone: value,
        },
        error_alert: 'none',
      });
      const resName = request('/customers', {
        query: {
          first_name: value,
        },
        error_alert: 'none',
      });
      const resLastname = request('/customers', {
        query: {
          last_name: value,
        },
        error_alert: 'none',
      });
      const resACNo = request('/customers', {
        query: {
          customer_no: value,
        },
        error_alert: 'none',
      });
      const res = await Promise.all([resPhone, resName, resLastname, resACNo])
        .then((response) => {
          const referenceData = _.reduce(
            response,
            (result, refValue) => {
              const getCustomerData = _.get(refValue, ['reference_data'], []);
              return [...result, ...getCustomerData];
            },
            []
          );
          return _.unionBy(referenceData, 'id');
        })
        .catch(err => console.log({ err }));
      // console.log({ res });
      setCustomerData(_.sortBy(res, ['first_name']));
    } else {
      setCustomerData([]);
    }
    setFetchingCustomer(false);
  }, 300);

  const handleSelectCustomer = (value) => {
    const customerId = _.get(value, 'key');
    const customer = _.find(customerData, cus => cus.id === customerId);

    setFieldsValue({
      searchCustomer: value,
      customer_id: _.get(customer, ['id']),
      first_name: _.get(customer, ['first_name']),
      last_name: _.get(customer, ['last_name']),
      phone: _.get(customer, ['telephone']),
      customer_no: _.get(customer, ['customer_no']),
    });

    setSerchPopoverVisible(false);
  };

  const renderCourse = (dataOfCourses, type, title) => {
    const selectValue = getFieldValue(type);
    return (
      <Row gutter={16}>
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator(type)(
            <Select mode="multiple" style={{ width: '100%' }}>
              <Option key="aa">aa</Option>
            </Select>
          )}
        </Form.Item>
        <Col>
          <TextNormal color={gray} fontSize="14px">
            {title}
          </TextNormal>
        </Col>
        <CourseContentBlock>
          {_.map(dataOfCourses, (course) => {
            const courseId = _.get(course, ['course', '_id']);
            const check = _.includes(selectValue, courseId);
            return (
              <Col key={courseId} lg={24} xl={12}>
                <CourseBlock
                  onClick={setCourse(courseId, type)}
                  disabled={_.includes(
                    optionalCourseIdWithSpecialCourseSelected,
                    courseId
                  )}
                >
                  <CourseImageBlock
                    src={_.get(course, ['course', 'picture'])}
                  />
                  <CourseDetailBlock>
                    <span>{t(_.get(course, ['course', 'title'], {}))}</span>
                    <span>{`${_.get(course, ['course', 'price'])} ฿`}</span>
                  </CourseDetailBlock>
                  <IconBlock>
                    {check ? (
                      <Icon
                        type="check-square"
                        style={{ fontSize: '25px', color: '#08c' }}
                      />
                    ) : (
                      <Icon
                        type="border"
                        style={{ fontSize: '25px', color: '#08c' }}
                      />
                    )}
                  </IconBlock>
                </CourseBlock>
              </Col>
            );
          })}
        </CourseContentBlock>
      </Row>
    );
  };

  const handleVisibleChange = (visibleChange) => {
    setSerchPopoverVisible(visibleChange);
  };

  const searchContent = (
    <SearchContentBlock>
      <Form.Item
        label={(
          <TextNormal color={purple} fontSize="14px">
            Search Customer
          </TextNormal>
)}
      >
        {getFieldDecorator('searchCustomer')(
          <Select
            showSearch
            labelInValue
            placeholder="Phone Number or Name or Customer No."
            notFoundContent={fetchingCustomer ? <Spin size="small" /> : null}
            showArrow={false}
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={findCustomer}
            onChange={handleSelectCustomer}
            style={{ width: '100%' }}
          >
            {_.map(customerData, customer => (
              <Option key={customer.id}>
                <Row>
                  <Col span={24}>
                    {`${customer.first_name} ${customer.last_name} / ${customer.telephone}`}
                  </Col>
                </Row>
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </SearchContentBlock>
  );

  // useEffect(() => {
  //   console.log({ roomData });
  // }, [roomData]);

  return (
    <Drawer
      width="calc(100% - 180px)"
      title={
        isCreate
          ? `${t(constants.POST)}${t(constants.BOOKING)}`
          : `${t(
            isAllow(allow.PUT, allowPermission.PUT)
              ? constants.PUT
              : constants.DETAIL
          )}${t(constants.BOOKING)}`
      }
      placement="right"
      closable
      onClose={onCancel}
      visible={visible}
    >
      <Form layout="vertical">
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('booking_id')(<Input />)}
        </Form.Item>
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('customer_id')(<Input />)}
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={(
                <TextNormal color={purple} fontSize="14px">
                  Staff
                </TextNormal>
)}
              hasFeedback
            >
              {getFieldDecorator('staff')(
                <Select
                  disabled={
                    !isAllow(allow.POST, allowPermission.POST)
                    || !isAllow(allow.PUT, allowPermission.PUT)
                    || (roomData.type !== 'half' && !!staffId)
                    // || !!getFieldValue('staff')
                    // !isAllow(allow.PUT, allowPermission.PUT) || !isCreate
                  }
                  placeholder="Staff"
                >
                  {!!isCreate && (
                    <Option key="staff-default" value={undefined}>
                      Select Staff
                    </Option>
                  )}
                  {_.map(staffData, staff => (
                    <Option
                      key={_.get(staff, ['staff', 'id'])}
                      value={_.get(staff, ['staff', 'id'])}
                    >
                      {`${_.get(staff, ['staff', 'full_name'])}
                       ( ${_.get(staff, ['staff', 'nickname'])} )`}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Row gutter={16}>
              {isCreate && (
                <Form.Item>
                  <Popover
                    placement="bottomLeft"
                    content={searchContent}
                    trigger="click"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    visible={serchPopoverVisible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <SearchButton>
                      <Icon
                        type="search"
                        style={{ fontSize: '30px', color: '#08c' }}
                      />
                      <TextNormal fontSize="16px" style={{ marginLeft: 8 }}>
                        Find Customer By Phone Number or Name or Customer No.
                      </TextNormal>
                    </SearchButton>
                  </Popover>
                </Form.Item>
              )}
              <Col span={12}>
                <Form.Item
                  label={(
                    <TextNormal color={purple} fontSize="14px">
                      {t(constants.FIRST_NAME)}
                    </TextNormal>
)}
                  hasFeedback
                >
                  {getFieldDecorator('first_name', {
                    rules: [
                      {
                        required: true,
                        message: t(constants.ERROR_FIRST_NAME),
                      },
                    ],
                  })(
                    <Input
                      disabled={
                        !isAllow(allow.PUT, allowPermission.PUT) || !isCreate
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={(
                    <TextNormal color={purple} fontSize="14px">
                      {t(constants.LAST_NAME)}
                    </TextNormal>
)}
                  hasFeedback
                >
                  {getFieldDecorator('last_name', {
                    rules: [
                      {
                        required: true,
                        message: t(constants.ERROR_LAST_NAME),
                      },
                    ],
                  })(
                    <Input
                      disabled={
                        !isAllow(allow.PUT, allowPermission.PUT) || !isCreate
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={(
                    <TextNormal color={purple} fontSize="14px">
                      {t(constants.PHONE)}
                    </TextNormal>
)}
                  hasFeedback
                >
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: t(constants.ERROR_PHONE),
                      },
                    ],
                  })(
                    <Input
                      disabled={
                        !isAllow(allow.PUT, allowPermission.PUT) || !isCreate
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {getFieldDecorator('is_new', {
                valuePropName: 'checked',
              })(
                <Checkbox>
                  <TextNormal fontSize="14px" color={purple}>
                    ลูกค้าใหม่
                  </TextNormal>
                </Checkbox>
              )}
            </Form.Item>
            <Col>Account</Col>
            <Form.Item
              labelCol={{ sm: { span: 8 } }}
              wrapperCol={{ sm: { span: 16 } }}
              label={(
                <TextNormal color={purple} fontSize="14px">
                  AC No.
                </TextNormal>
)}
              hasFeedback
            >
              {getFieldDecorator(
                'customer_no',
                {}
              )(<Input disabled={!isAllow(allow.PUT, allowPermission.PUT)} />)}
            </Form.Item>
            <Form.Item
              label={(
                <TextNormal color={purple} fontSize="14px">
                  Booked By
                </TextNormal>
)}
              hasFeedback
            >
              {getFieldDecorator('booking_by', {
                rules: [
                  {
                    required: true,
                    // message: t(constants.ERROR_FULL_NAME)
                  },
                ],
              })(
                <Select
                  disabled={
                    !isAllow(allow.PUT, allowPermission.PUT) || !isCreate
                  }
                  placeholder="Booked By"
                >
                  {_.map(
                    [
                      { value: 'shop', title: 'shop' },
                      { value: 'phone', title: 'Admin' },
                      { value: 'Online(web booking)', title: 'Online(web booking)' },
                      { value: 'Next Booking', title: 'New 1,600' },
                      // 'website', 'mobile'
                    ],
                    booked => (
                      <Option key={booked.value} value={booked.value}>
                        {`${_.upperFirst(booked.title)}`}
                      </Option>
                    )
                  )}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label={(
                <TextNormal color={purple} fontSize="14px">
                  STATUS INFO
                </TextNormal>
)}
            >
              {getFieldDecorator('status', {
                initialValue: isCreate ? 'confirm' : null,
              })(
                <Radio.Group
                  disabled={
                    !isAllow(allow.PUT, allowPermission.PUT)
                    // || isCreate
                  }
                >
                  {_.map(['pending', 'confirm', 'cancel'], status => (
                    <Radio.Button key={status} value={status}>
                      {`${_.upperFirst(status)}`}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              label={(
                <TextNormal color={purple} fontSize="14px">
                  Is the customer show at the shop?
                </TextNormal>
)}
            >
              {getFieldDecorator(
                'show_status',
                {}
              )(
                <Radio.Group
                  disabled={
                    !isAllow(allow.PUT, allowPermission.PUT)
                    // || isCreate
                  }
                >
                  {_.map(['show', 'no_show'], status => (
                    <Radio.Button key={status} value={status}>
                      {`${_.upperFirst(status)}`}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {renderCourse(specialCourses, 'special', 'Special Course')}
            {renderCourse(mainCourses, 'main', 'Main Course')}
            {renderCourse(optionalCourses, 'optional', 'Optional Course')}
            <Form.Item
              label={(
                <TextNormal color={gray} fontSize="14px">
                  NOTE
                </TextNormal>
)}
            >
              {getFieldDecorator('note', {})(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 2,
          }}
        >
          {!isCreate && (
            <Popconfirm title={t(constants.SURE_DELETE)} onConfirm={onDelete}>
              <Button
                loading={isFormLoading}
                type="danger"
                style={{ marginRight: 8 }}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
          <Button
            loading={isFormLoading}
            onClick={onCancel}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button loading={isFormLoading} onClick={onCreate} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

const CourseContentBlock = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 15px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CourseBlock = styled.div`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 11px 0;
`;
const CourseImageBlock = styled.img`
  width: 55px;
  height: 55px;
  flex: 0 0 auto;
`;

const CourseDetailBlock = styled.div`
  width: calc(100% - 90px);
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
`;

const IconBlock = styled.div`
  width: 27px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const SearchContentBlock = styled.div`
  width: 600px;
`;

const SearchButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default Form.create()(ModalForm);
