import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';

export const useToggle = (defaultValue) => {
  const [state, setState] = useState(defaultValue || false);

  const toggle = () => {
    setState(prevState => !prevState);
  };

  return {
    open: state,
    toggle
  };
};

export function useWindowWidth() {
  const [width, setWidth] = useState(null);
  useLayoutEffect(() => {
    const listener = () => setWidth(window.innerWidth);
    window.addEventListener('resize', listener);
    listener();
    return () => window.removeEventListener('resize', listener);
  }, []);
  return width;
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export function useCountDown() {
  const countInterval = useRef(null);
  const countDownToHome = () => {
    // const history = useHistory();
    if (countInterval.current) clearInterval(countInterval.current);
    let seconds = 0;
    countInterval.current = setInterval(() => {
      seconds += 1;
      console.log('interval =>>>>', seconds);
      if (seconds === 10) {
        clearInterval(countInterval.current);
        // history.push('/home');
      }
    }, 1000);
  };

  return countDownToHome;
}

// usage
function UiComponent(props) {
  const countDownToHome = useCountDown();
  useEffect(() => {
    countDownToHome();
  }, []);
  return <div>test</div>;
}
