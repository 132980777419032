import styled from 'styled-components';
import { white } from '../../style/color';

export const DashboardStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .dashboard_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      transform: scale(1.3);

      @media only screen and (max-width: 1024px) {
        transform: scale(1);
      }
    }
  }

  .sidebar_wrapper {
    flex: 0 0 auto;
    width: 180px;
    height: 100%;
    z-index: 20;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    @media only screen and (max-width: 1024px) {
      width: 140px;
    }
  }

  .content_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.3); */
    overflow-y: scroll;
  }
`;

export const ContentBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: ${props => props.padding || '140px 0 0'};
`;
