import React, { useState, useContext, useEffect, useRef } from 'react';
import { Layout, DatePicker, Table, Icon, Divider, Popconfirm } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import Dashboard from '../../components/Dashboard';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock
} from '../../style/main';
import LeftArrow from '../../assets/svg/LeftArrow';
import RightArrow from '../../assets/svg/RightArrow';
import { LanguageContext } from '../../context/language';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple } from '../../style/color';
import { MainContext } from '../../context/main';
import AddButton from '../../components/Button/AddButton';
import Plus from '../../assets/svg/Plus';
import ModalForm from './ModalForm';
import Filter, { useFilter } from '../../components/Filter';
import { useDropdown } from '../../components/Dropdown';
import { useToggle, useDebounce } from '../../hooks';
import {
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage,
  isAllow,
  isImage,
  getBase64,
  isValidRatio,
  isValidSize,
  formatPhoneNumber
} from '../../utils';
import { weekdaysInMonth } from '../../utils/weekDaysin';

const { MonthPicker } = DatePicker;

const { Content } = Layout;

const StaffScreen = ({ allow, isBranchesFilter, allowPermission }) => {
  const formRef = useRef(null);

  const { t, lang } = useContext(LanguageContext);
  const { request, constants, currentBranch, isDesktop } = useContext(
    MainContext
  );
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [pickerOpen, setPickerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10 });

  const { open: desc, toggle: changeDesc } = useToggle();
  const { value: currentSortKey, handleSelect: handleSortKey } = useDropdown();
  const {
    value: currentFilterKey,
    handleSelect: handleFilterKey
  } = useDropdown();

  const { value: currentFilterValue, handler } = useFilter();

  const getData = async (params) => {
    if (!currentBranch && currentBranch === '') {
      return;
    }
    setIsLoading(true);
    const res = await request('/staff', {
      query: {
        branch: currentBranch,
        ...params
      }
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);
    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  const handleAddStaff = () => {
    if (formRef.current) {
      const { resetFields } = formRef.current;
      resetFields();
      setPreviewImage(null);
    }
    setIsCreate(true);
    setOpenModal(true);
  };

  const handleEditData = (staffData, e) => {
    e.preventDefault();

    if (formRef.current) {
      const { setFieldsValue } = formRef.current;
      setFieldsValue({
        id: _.get(staffData, ['id']),
        full_name: _.get(staffData, ['full_name'], ''),
        nickname: _.get(staffData, ['nickname'], ''),
        telephone: _.get(staffData, ['telephone'], ''),
        email: _.get(staffData, ['email'], ''),
        branch_id: _.get(staffData, ['branch', '_id'], '')
      });

      setPreviewImage(_.get(staffData, ['picture'], null));
    }
    setIsCreate(false);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalCreate = () => {
    if (formRef.current) {
      const { validateFields, resetFields } = formRef.current;
      validateFields(async (err, values) => {
        if (!err) {
          const id = _.trim(_.get(values, 'id'));

          const body = new FormData();

          if (!id) {
            body.append('full_name', _.get(values, ['full_name']));
            body.append('nickname', _.get(values, ['nickname']));
            body.append('branch_id', _.get(values, ['branch_id']));

            if (_.get(values, ['telephone'])) {
              body.append('telephone', _.get(values, ['telephone']));
            }

            if (_.get(values, ['email'])) {
              body.append('email', _.get(values, ['email']));
            }

            if (_.get(values, ['picture', 0, 'originFileObj'])) {
              body.append(
                'picture',
                _.get(values, ['picture', 0, 'originFileObj'])
              );
            }
          } else {
            body.append('id', id);

            if (_.get(values, ['full_name'])) {
              body.append('full_name', _.get(values, ['full_name']));
            }

            if (_.get(values, ['nickname'])) {
              body.append('nickname', _.get(values, ['nickname']));
            }

            if (_.get(values, ['telephone'])) {
              body.append('telephone', _.get(values, ['telephone']));
            }

            if (_.get(values, ['email'])) {
              body.append('email', _.get(values, ['email']));
            }

            if (_.get(values, ['branch_id'])) {
              body.append('branch_id', _.get(values, ['branch_id']));
            }

            if (_.get(values, ['picture', 0, 'originFileObj'])) {
              body.append(
                'picture',
                _.get(values, ['picture', 0, 'originFileObj'])
              );
            }
          }

          const res = await request('/staff', {
            method: id ? 'put' : 'post',
            body
          });

          if (res) {
            resetFields();
            getData();
            setOpenModal(false);
          }
        }
      });
    }
  };

  const handleDelete = id => async (e) => {
    setIsLoading(true);
    const res = await request('/staff', {
      method: 'delete',
      query: {
        id
      }
    });
    setIsLoading(false);
    await getData();
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getData({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  useEffect(() => {
    getData();
  }, [currentBranch]);

  const columns = [
    {
      title: t(constants.FULL_NAME),
      dataIndex: 'full_name',
      key: 'full_name',
      width: '22.5%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.FULL_NAME)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.NICKNAME),
      dataIndex: 'nickname',
      key: 'nickname',
      width: '22.5%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.NICKNAME)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.TELEPHONE),
      dataIndex: 'telephone',
      key: 'telephone',
      width: '22.5%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.TELEPHONE)}`
        )),
      render: text => formatPhoneNumber(text)
    },
    {
      title: t(constants.USER_BRANCH),
      key: 'branch',
      width: '22.5%',
      render: objData => _.get(objData, ['branch', 'name'], '')
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <span>
          <Icon
            onClick={handleEditData.bind(this, o)}
            type={!isAllow(allow.PUT, allowPermission.PUT) ? 'zoom-in' : 'edit'}
          />
          <Divider type="vertical" />
          <Popconfirm
            disabled={!isAllow(allow.DELETE, allowPermission.DELETE)}
            title={t(constants.SURE_DELETE)}
            onConfirm={handleDelete(o.id)}
          >
            <Icon
              className={
                !isAllow(allow.DELETE, allowPermission.DELETE)
                  ? 'disabled'
                  : 'custom'
              }
              type="delete"
            />
          </Popconfirm>
        </span>
      )
    }
  ];

  function getMondays(date) {
    const d = date || new Date();
    const month = d.getMonth();
    const mondays = [];

    d.setDate(2);

    // Get the first Monday in the month
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    // Get all the other Mondays in the month
    while (d.getMonth() === month) {
      mondays.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    }

    return mondays;
  }

  const validateImage = async (rule, value, callback) => {
    try {
      const fileValue = _.get(value, [0, 'originFileObj']);
      if (!fileValue) {
        return;
        // if (previewImage) {
        //   return callback();
        // }
        // return callback(t(constants.ERROR_IMAGE));
      }

      setPreviewImage(null);

      const fileType = _.get(fileValue, ['type']);

      const validFileType = isImage(fileType);

      if (!validFileType) {
        callback(t(constants.ERROR_IMAGE_TYPE));
        return;
      }

      const fileSize = _.get(fileValue, ['size']);

      const validFileSize = isValidSize(fileSize);

      if (!validFileSize) {
        callback(t(constants.ERROR_IMAGE_LARGE));
        return;
      }

      const valid = await isValidRatio(fileValue, 1);

      if (!valid) {
        callback(
          `${t(constants.ERROR_IMAGE_RATIO)} = 1 : 1 (200pixel X 200pixel) ${t(
            constants.NOT_REQUIRED
          )}`
        );
        return;
      }

      getBase64(fileValue, imgUrl => setPreviewImage(imgUrl));
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const debouncedFilterValue = useDebounce(currentFilterValue, 500);

  useEffect(() => {
    if (currentSortKey && currentFilterKey && !_.isNil(debouncedFilterValue)) {
      getData({
        ...(debouncedFilterValue && {
          [currentFilterKey]: debouncedFilterValue
        }),
        sort: { id: currentSortKey, desc }
      });
    }
  }, [debouncedFilterValue, currentFilterKey]);

  useEffect(() => {
    if (currentSortKey) {
      getData({
        sort: { id: currentSortKey, desc }
      });
    }
  }, [desc, currentSortKey]);

  const sortItems = [
    { id: 'full_name', title: t(constants.FULL_NAME) },
    { id: 'nickname', title: t(constants.NICKNAME) }
  ];

  const filterItems = [
    { id: 'full_name', title: t(constants.FULL_NAME) },
    { id: 'nickname', title: t(constants.NICKNAME) },
    { id: 'telephone', title: t(constants.TELEPHONE) }
  ];

  return (
    <>
      <HeaderContentBlock>
        <AddButtonBlock>
          {isAllow(allow.POST, allowPermission.POST) && (
            <AddButton onClick={handleAddStaff}>
              <Plus style={{ marginRight: '15px' }} />
              <TextBold color={purple}>
                {`${t(constants.ADD)}${t(constants.STAFF)}`}
              </TextBold>
            </AddButton>
          )}
        </AddButtonBlock>
      </HeaderContentBlock>
      <Filter
        sortItems={sortItems}
        currentSortKey={currentSortKey}
        handleSortKey={handleSortKey}
        filterItems={filterItems}
        currentFilterKey={currentFilterKey}
        handleFilterKey={handleFilterKey}
        desc={desc}
        changeDesc={changeDesc}
        filterValue={currentFilterValue}
        onChange={handler}
      />
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px' }}>
            <div
              style={{
                background: '#fff',
                minHeight: 380
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                loading={isLoading}
                pagination={pagination}
                scroll={{ y: '40vh' }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <ModalForm
        allowPermission={allowPermission}
        isCreate={isCreate}
        allow={allow}
        ref={formRef}
        visible={openModal}
        onCancel={handleModalClose}
        onCreate={handleModalCreate}
        validateImage={validateImage}
        previewImage={previewImage}
      />
    </>
  );
};

export default StaffScreen;
