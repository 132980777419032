import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 289.667 289.667"
    style={{ enableBackground: 'new00289.667289.667' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="white"
        id="path34389"
        d="M5,39.798c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h190c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H5
       z M255.143,39.8c-1.688-0.051-3.273,0.814-4.143,2.262l-30,50c-1.326,2.21-0.609,5.077,1.601,6.403
       c0.725,0.435,1.554,0.665,2.399,0.665h25v145.668c-0.039,2.761,2.168,5.032,4.929,5.071c2.761,0.039,5.032-2.168,5.071-4.929
       c0.001-0.047,0.001-0.094,0-0.141V99.13h25c2.578,0,4.667-2.09,4.667-4.667c0-0.846-0.23-1.676-0.665-2.401l-30-50
       C258.184,40.7,256.73,39.848,255.143,39.8z M10,49.798h180v40H10V49.798z M255.002,53.532l21.758,36.264h-19.797
       c-0.643-0.276-1.337-0.414-2.037-0.404c-0.651,0.01-1.293,0.148-1.891,0.404h-19.793L255.002,53.532z M20,57.298
       c-1.381,0-2.5,1.119-2.5,2.5v15c-0.02,1.381,1.084,2.516,2.465,2.535c1.381,0.02,2.516-1.084,2.535-2.465c0-0.024,0-0.047,0-0.071
       v-12.5H45c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535c-0.024,0-0.047,0-0.071,0H20z M255.129,65.935
       c-0.913-0.04-1.775,0.421-2.248,1.203l-10,16c-0.739,1.167-0.392,2.711,0.775,3.45c1.167,0.739,2.711,0.392,3.45-0.775
       c0.005-0.008,0.01-0.016,0.015-0.025l10-16c0.749-1.16,0.415-2.708-0.745-3.456C256.003,66.091,255.572,65.954,255.129,65.935z
        M5,114.798c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h140c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H5z M10,124.798h130v40
       H10V124.798z M20,132.298c-1.381,0-2.5,1.119-2.5,2.5v15c-0.02,1.381,1.084,2.516,2.465,2.535s2.516-1.084,2.535-2.465
       c0-0.024,0-0.047,0-0.071v-12.5H45c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535
       c-0.024,0-0.047,0-0.071,0H20z M5,189.798c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h90c2.761,0,5-2.239,5-5v-50
       c0-2.761-2.239-5-5-5H5z M10,199.798h80v40H10V199.798z M20,207.298c-1.381,0-2.5,1.119-2.5,2.5v15
       c-0.02,1.381,1.084,2.516,2.465,2.535s2.516-1.084,2.535-2.465c0-0.024,0-0.047,0-0.071v-12.5H45
       c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535c-0.024,0-0.047,0-0.071,0H20z"
      />
    </g>
  </svg>
);
