import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Icon,
  Button,
  Modal,
  Select,
  Spin,
  Upload,
  Col,
  Row,
  TimePicker,
  InputNumber,
  DatePicker,
  Table,
  Popover,
  Checkbox
} from 'antd';
import moment from 'moment';
import { MainContext } from '../../context/main';
import {
  isBranchCode,
  isEmail,
  isPhoneNumber,
  dummyRequest,
  isAllow,
  isImage,
  getBase64,
  isValidRatio,
  getTime,
  minutesDuration,
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  isArraysEqual,
  isValidSize,
  normFile,
  beforeUpload,
  normDate,
  normTime
} from '../../utils';
import { LanguageContext } from '../../context/language';
import { TagContainer } from '../PermissionScreen/style';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray, white } from '../../style/color';
import PopOverTag from '../../components/PopOverTag';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const format = 'HH:mm';
const defaultTime = {
  sunday: { key: 0 },
  monday: { key: 1 },
  tuesday: { key: 2 },
  wednesday: { key: 3 },
  thursday: { key: 4 },
  friday: { key: 5 },
  saturday: { key: 6 }
};

const shifts = [
  { shift: 'morning', defaultStart: '09:00', defaultEnd: '18:00' },
  { shift: 'afternoon', defaultStart: '11:00', defaultEnd: '20:00' },
  { shift: 'evening', defaultStart: '12:30', defaultEnd: '21:30' }
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14, offset: 1 }
  }
};

const openItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12, offset: 1 }
  }
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 9 }
  }
};

const addPhoneButtonLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 9 }
  }
};

const addSpecialDayButtonLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 }
  }
};

const specialDayItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};

const removeSpecialDayItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 }
  }
};

const remarkLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const DynamicFieldSet = ({
  form,
  open,
  toggle,
  branchId,
  onSubmit,
  allow,
  allowPermission
}) => {
  const { t } = useContext(LanguageContext);

  const [courseData, setCourseData] = useState([]);
  const [activeCourse, setActiveCourse] = useState([]);
  const [removeActiveCourses, setRemoveActiveCourses] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 5 });
  const [telephoneKey, setTelephoneKey] = useState([]);
  const [branch, setBranch] = useState(null);
  const [specialDayKey, setSpecialDayKey] = useState([]);
  const [specialDayRemoveKey, setSpecialDayRemoveKey] = useState([]);
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [isGetCourseLoading, setIsGetCourseLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const { request, constants } = useContext(MainContext);
  const { getFieldDecorator } = form;

  const getCourseData = async (params) => {
    setIsGetCourseLoading(true);
    const coursesRes = await request('/courses', {
      query: {
        ...params
      }
    });

    const referenceCourseData = _.get(coursesRes, ['reference_data'], []);
    const totalCourseData = _.get(coursesRes, ['total_data'], 0);

    setCourseData(referenceCourseData);
    setPagination({ ...pagination, total: totalCourseData });
    setIsGetCourseLoading(false);
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getCourseData({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  const popOverChange = (id) => {
    if (_.includes(activeCourse, id)) {
      setActiveCourse(_.filter(activeCourse, i => i !== id));

      const oldCourse = _.find(
        _.get(branch, ['courses']),
        o => _.get(o, ['course', '_id']) === id
      );

      if (oldCourse) {
        setRemoveActiveCourses([...new Set([...removeActiveCourses, id])]);
      }

      return;
    }
    setActiveCourse([...new Set([...activeCourse, id])]);
  };

  const columns = [
    {
      title: t(constants.COURSE_TITLE),
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps(
        `${t(constants.SEARCH)}... ${t(constants.COURSE_TITLE)}`
      ),
      render: title => t(title)
    },
    {
      title: t(constants.COURSE_PRICE),
      dataIndex: 'price',
      key: 'price',
      sorter: () => {}
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <PopOverTag
          allow={allow}
          status={_.includes(activeCourse, o.id)}
          onChange={popOverChange}
          id={o.id}
        />
      )
    }
  ];

  const getData = async () => {
    if (!branchId) {
      setPreviewImage(null);
      setBranch(null);

      setTelephoneKey([_.uniqueId()]);
      setSpecialDayKey([]);
      setActiveCourse([]);
      return;
    }
    setIsGetDataLoading(true);
    const res = await request('/branches', {
      query: {
        id: branchId,
        includes: ['courses', 'hq', 'not_active']
      }
    });

    const referenceData = _.get(res, ['reference_data', 0], []);

    const picture = _.get(referenceData, ['picture']);

    const telephones = _.get(referenceData, 'telephones', [_.uniqueId()]);

    const specialDay = _.get(referenceData, ['special_days'], []);

    const branchCourses = _.get(referenceData, ['courses'], []);

    setIsGetDataLoading(false);
    setPreviewImage(picture || null);

    setBranch(referenceData);
    setSpecialDayKey(_.map(specialDay, o => o._id));
    setTelephoneKey(telephones);
    setActiveCourse(
      _.map(branchCourses, o => _.get(o, ['course', '_id'], null))
    );
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      setPreviewImage(null);
      getData();
      getCourseData();
      setSpecialDayRemoveKey([]);
      setRemoveActiveCourses([]);
      setPagination({ ...pagination, current: 1 });
    }
  }, [open]);

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setIsSubmitLoading(true);

        const body = new FormData();

        if (!branch) {
          body.append('code', values.code);
          body.append('name', values.name);
          body.append('location', values.location);

          body.append(
            'picture',
            _.get(values, ['picture', 0, 'originFileObj'])
          );

          body.append('open_detail_en', values.open_detail_en);
          body.append('open_detail_th', values.open_detail_th);

          if (values.email) {
            body.append('email', values.email);
          }

          if (values.detail_address) {
            body.append('detail_address', values.detail_address);
          }

          if (values.detail_district) {
            body.append('detail_district', values.detail_district);
          }

          if (values.detail_province) {
            body.append('detail_province', values.detail_province);
          }

          if (values.detail_zipcode) {
            body.append('detail_zipcode', values.detail_zipcode);
          }

          if (values.rooms) {
            body.append('rooms', values.rooms);
          }

          _.forEach(defaultTime, (day, i) => {
            const openTime = minutesDuration(
              _.get(values, [`open_${i}`, '_i'], '')
            );
            const closeTime = minutesDuration(
              _.get(values, [`close_${i}`, '_i'], '')
            );

            body.append(`open_${i}`, openTime);

            body.append(`close_${i}`, closeTime);
          });

          _.forEach(shifts, (o) => {
            const startTime = minutesDuration(
              _.get(values, [`${_.get(o, ['shift'])}_start`, '_i'], '')
            );
            const endTime = minutesDuration(
              _.get(values, [`${_.get(o, ['shift'])}_end`, '_i'], '')
            );

            body.append(`${_.get(o, ['shift'])}_start`, startTime);

            body.append(`${_.get(o, ['shift'])}_end`, endTime);
          });

          _.times(_.size(specialDayKey), (i) => {
            body.append(
              `new_special_days[${i}][date]`,
              _.get(values, [`special_date_${specialDayKey[i]}`, '_d'])
            );
            body.append(
              `new_special_days[${i}][open]`,
              minutesDuration(
                _.get(values, [`special_open_${specialDayKey[i]}`, '_i'])
              )
            );
            body.append(
              `new_special_days[${i}][close]`,
              minutesDuration(
                _.get(values, [`special_close_${specialDayKey[i]}`, '_i'])
              )
            );
            body.append(
              `new_special_days[${i}][remark]`,
              _.get(values, [`special_remark_${specialDayKey[i]}`])
            );
            body.append(
              `new_special_days[${i}][is_closed]`,
              _.get(values, [`special_is_closed_${i}`], false)
            );
          });

          _.times(_.size(activeCourse), (i) => {
            if (activeCourse[i]) body.append('new_courses[]', activeCourse[i]);
          });
        } else {
          body.append('id', branch.id);

          if (values.name !== branch.name) {
            body.append('name', values.name);
          }

          if (values.location !== branch.location) {
            body.append('location', values.location);
          }

          if (values.email !== branch.email) {
            body.append('email', values.email);
          }

          if (values.open_detail_en !== _.get(branch, ['open_detail', 'en'])) {
            body.append('open_detail_en', values.open_detail_en);
          }

          if (values.open_detail_th !== _.get(branch, ['open_detail', 'th'])) {
            body.append('open_detail_th', values.open_detail_th);
          }

          if (values.detail_address !== _.get(branch, ['detail', 'address'])) {
            body.append('detail_address', values.detail_address);
          }

          if (
            values.detail_district !== _.get(branch, ['detail', 'district'])
          ) {
            body.append('detail_district', values.detail_district);
          }

          if (
            values.detail_province !== _.get(branch, ['detail', 'province'])
          ) {
            body.append('detail_province', values.detail_province);
          }

          if (values.detail_zipcode !== _.get(branch, ['detail', 'zipcode'])) {
            body.append('detail_zipcode', values.detail_zipcode);
          }

          if (previewImage !== branch.picture) {
            body.append(
              'picture',
              _.get(values, ['picture', 0, 'originFileObj'])
            );
          }

          if (values.rooms !== _.toString(branch.rooms)) {
            body.append('rooms', values.rooms);
          }

          _.forEach(defaultTime, (day, i) => {
            const openTime = minutesDuration(
              _.get(values, [`open_${i}`, '_i'], '')
            );
            const closeTime = minutesDuration(
              _.get(values, [`close_${i}`, '_i'], '')
            );

            if (
              openTime !== _.toSafeInteger(_.get(branch, ['open', i, 'time']))
            ) {
              body.append(`open_${i}`, openTime);
            }

            if (
              closeTime !== _.toSafeInteger(_.get(branch, ['close', i, 'time']))
            ) {
              body.append(`close_${i}`, closeTime);
            }
          });

          _.forEach(shifts, (o) => {
            const startTime = minutesDuration(
              _.get(values, [`${_.get(o, ['shift'])}_start`, '_i'], '')
            );
            const endTime = minutesDuration(
              _.get(values, [`${_.get(o, ['shift'])}_end`, '_i'], '')
            );

            if (
              startTime
              !== _.toSafeInteger(
                _.get(branch, ['work_start', `${_.get(o, ['shift'])}_shift`])
              )
            ) {
              body.append(`${_.get(o, ['shift'])}_start`, startTime);
            }

            if (
              endTime
              !== _.toSafeInteger(
                _.get(branch, ['work_end', `${_.get(o, ['shift'])}_shift`])
              )
            ) {
              body.append(`${_.get(o, ['shift'])}_end`, endTime);
            }
          });

          _.times(_.size(specialDayRemoveKey), (i) => {
            body.append('remove_special_days[]', specialDayRemoveKey[i]);
          });

          const newSpecialDays = _.chain(specialDayKey)
            .map((k) => {
              if (!_.find(_.get(branch, ['special_days']), o => o._id === k)) {
                return {
                  date: moment
                    .utc(_.get(values, [`special_date_${k}`, '_d']))
                    .toISOString(),
                  open: minutesDuration(
                    _.get(values, [`special_open_${k}`, '_i'])
                  ),
                  close: minutesDuration(
                    _.get(values, [`special_close_${k}`, '_i'])
                  ),
                  remark: _.get(values, [`special_remark_${k}`], ''),
                  is_closed: _.get(values, [`special_is_closed_${k}`], false)
                };
              }

              return null;
            })
            .filter(o => o !== null)
            .value();

          const updateSpecialDays = _.chain(specialDayKey)
            .map((k) => {
              const oldSpecialDay = _.find(
                _.get(branch, ['special_days']),
                o => o._id === k
              );

              if (!oldSpecialDay) {
                return null;
              }

              const date = moment
                .utc(_.get(values, [`special_date_${k}`, '_d']))
                .toISOString();
              const open = minutesDuration(
                _.get(values, [`special_open_${k}`, '_i'])
              );
              const close = minutesDuration(
                _.get(values, [`special_close_${k}`, '_i'])
              );
              const remark = _.get(values, [`special_remark_${k}`]);
              const is_closed = _.get(
                values,
                [`special_is_closed_${k}`],
                false
              );

              if (
                oldSpecialDay.date === date
                && oldSpecialDay.remark === remark
                && oldSpecialDay.open === open
                && oldSpecialDay.close === close
                && oldSpecialDay.is_closed === is_closed
              ) {
                return null;
              }

              const updateObj = {};

              if (oldSpecialDay.date !== date) {
                updateObj.date = date;
              }

              if (oldSpecialDay.remark !== remark) {
                updateObj.remark = remark;
              }

              if (oldSpecialDay.open !== open) {
                updateObj.open = open;
              }

              if (oldSpecialDay.close !== close) {
                updateObj.close = close;
              }

              if (oldSpecialDay.is_closed !== is_closed) {
                updateObj.is_closed = is_closed;
              }

              return {
                id: oldSpecialDay._id,
                ...updateObj
              };
            })
            .filter(o => o !== null)
            .value();

          _.times(_.size(newSpecialDays), (i) => {
            const o = _.get(newSpecialDays, i);
            body.append(`new_special_days[${i}][date]`, _.get(o, ['date']));
            body.append(`new_special_days[${i}][open]`, _.get(o, ['open']));
            body.append(`new_special_days[${i}][close]`, _.get(o, ['close']));
            body.append(`new_special_days[${i}][remark]`, _.get(o, ['remark']));
            body.append(
              `new_special_days[${i}][is_closed]`,
              _.get(o, ['is_closed'])
            );
          });

          _.times(_.size(updateSpecialDays), (i) => {
            const o = _.get(updateSpecialDays, i);

            body.append(`update_special_days[${i}][id]`, o.id);

            if (_.get(o, ['date'])) {
              body.append(`update_special_days[${i}][date]`, o.date);
            }

            if (_.get(o, ['open'])) {
              body.append(`update_special_days[${i}][open]`, o.open);
            }

            if (_.get(o, ['close'])) {
              body.append(`update_special_days[${i}][close]`, o.close);
            }

            if (_.get(o, ['remark'])) {
              body.append(`update_special_days[${i}][remark]`, o.remark);
            }

            if (_.isBoolean(_.get(o, ['is_closed']))) {
              body.append(`update_special_days[${i}][is_closed]`, o.is_closed);
            }
          });

          const newCourses = _.chain(activeCourse)
            .filter(
              i => !_.find(
                _.get(branch, ['courses']),
                o => _.get(o, ['course', '_id']) === i
              )
            )
            .value();

          _.times(_.size(newCourses), (i) => {
            if (newCourses[i]) body.append('new_courses[]', newCourses[i]);
          });

          _.times(_.size(removeActiveCourses), (i) => {
            body.append('remove_courses[]', removeActiveCourses[i]);
          });
        }

        _.times(_.size(telephoneKey), (i) => {
          body.append(
            'telephones[]',
            _.get(values, [`tel_${telephoneKey[i]}`])
          );
        });

        const res = await request('/branches', {
          method: !branch ? 'post' : 'put',
          body
        });

        setIsSubmitLoading(false);

        if (res) {
          toggle();
          onSubmit();
        }
      }
    });
  };

  const removeTelephoneKey = index => () => {
    setTelephoneKey(prev => _.filter(prev, i => i !== index));
  };

  const addTelephoneKey = () => {
    setTelephoneKey(prev => [...prev, _.uniqueId()]);
  };

  const telephoneItem = _.map(telephoneKey, (elm, i) => (
    <React.Fragment key={i}>
      <Form.Item
        {...(i === 0 ? formItemLayoutHalf : formItemLayoutWithOutLabel)}
        label={
          i === 0 ? (
            <TextNormal color={purple}>{t(constants.PHONE)}</TextNormal>
          ) : (
            ''
          )
        }
        required={false}
      >
        {getFieldDecorator(`tel_${elm}`, {
          initialValue: _.find(
            _.get(branch, ['telephones']),
            e => e === telephoneKey[i]
          ),
          validateTrigger: ['onBlur'],
          rules: [
            {
              pattern: isPhoneNumber,
              required: true,
              whitespace: false,
              message: t(constants.ERROR_PHONE)
            }
          ]
        })(
          <Input
            disabled={!isAllow(allow.PUT, allowPermission.PUT)}
            placeholder={t(constants.INPUT_PHONE)}
            style={{
              width: telephoneKey.length > 1 && i !== 0 ? '75%' : '100%',
              marginRight: telephoneKey.length > 1 && i !== 0 ? 5 : 0
            }}
          />
        )}
        {telephoneKey.length > 1 && i !== 0 && (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={removeTelephoneKey(elm)}
          />
        )}
      </Form.Item>
    </React.Fragment>
  ));

  const validateImage = async (rule, value, callback) => {
    try {
      const fileValue = _.get(value, [0, 'originFileObj']);
      if (!fileValue) {
        if (previewImage === _.get(branch, ['picture'])) {
          return callback();
        }
        return callback(t(constants.ERROR_IMAGE));
      }

      setPreviewImage(null);

      const fileType = _.get(fileValue, ['type']);

      const validFileType = isImage(fileType);

      if (!validFileType) return callback(t(constants.ERROR_IMAGE_TYPE));

      const fileSize = _.get(fileValue, ['size']);

      const validFileSize = isValidSize(fileSize);

      if (!validFileSize) return callback(t(constants.ERROR_IMAGE_LARGE));

      const valid = await isValidRatio(fileValue, 1.8);

      if (!valid) {
        return callback(
          `${t(
            constants.ERROR_IMAGE_RATIO
          )} = 16 : 9 (1920pixel X 1080pixel) ${t(constants.REQUIRED)}`
        );
      }

      getBase64(fileValue, imgUrl => setPreviewImage(imgUrl));
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const validateTime = (key, compareKey, errMsg) => (rule, value, callback) => {
    const a = form.getFieldValue(key);
    const b = form.getFieldValue(compareKey);

    const aTime = minutesDuration(_.get(a, ['_i'], ''));
    const bTime = minutesDuration(_.get(b, ['_i'], ''));

    if (aTime > bTime) {
      callback(errMsg);
    }

    callback();
  };

  const addSpecialDayKey = () => {
    setSpecialDayKey([...specialDayKey, _.uniqueId()]);
  };

  const removeSpecialDayKey = index => () => {
    if (_.find(_.get(branch, ['special_days']), o => o._id === index)) {
      setSpecialDayRemoveKey([...new Set([...specialDayRemoveKey, index])]);
    }

    setSpecialDayKey(_.filter(specialDayKey, i => i !== index));
  };

  const setClosed = index => () => {
    form.setFieldsValue({
      [`special_is_closed_${index}`]: !form.getFieldValue(
        `special_is_closed_${index}`
      )
    });
  };

  const renderSpecialDay = _.times(_.size(specialDayKey), (i) => {
    const oldSpecial = _.find(
      _.get(branch, ['special_days']),
      o => o._id === specialDayKey[i]
    );

    return (
      <React.Fragment key={i}>
        <Row>
          <TextBold fontSize="14px" color={gray}>
            {`${t(constants.SPECIAL_DAY)} ${i + 1}`}
          </TextBold>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              {...specialDayItemLayout}
              label={(
                <TextNormal color={purple}>
                  {t(constants.SPECIAL_DAY_DATE)}
                </TextNormal>
)}
              required={false}
              hasFeedback
            >
              {getFieldDecorator(`special_date_${specialDayKey[i]}`, {
                initialValue: oldSpecial
                  ? moment(_.get(oldSpecial, ['date']))
                  : null,
                getValueFromEvent: normDate(dateFormat),
                rules: [
                  {
                    required: true,
                    message: t(constants.ERROR_SPECIAL_DAY_DATE)
                  }
                ]
              })(
                <DatePicker
                  disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                  style={{ width: '100%' }}
                  format={dateFormat}
                  suffixIcon={<div />}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...specialDayItemLayout}
              label={
                <TextNormal color={purple}>{t(constants.OPEN)}</TextNormal>
              }
              required={false}
              hasFeedback
            >
              {getFieldDecorator(`special_open_${specialDayKey[i]}`, {
                initialValue: oldSpecial
                  ? moment(getTime(_.get(oldSpecial, ['open'])), format)
                  : moment('10:00', format),
                getValueFromEvent: normTime(format),
                rules: [
                  {
                    required: true,
                    message: t(constants.ERROR_OPEN)
                  },
                  {
                    validator: validateTime(
                      `special_open_${specialDayKey[i]}`,
                      `special_close_${specialDayKey[i]}`,
                      t(constants.ERROR_TIME)
                    )
                  }
                ]
              })(
                <TimePicker
                  style={{
                    width: '100%'
                  }}
                  disabled={
                    !isAllow(allow.PUT, allowPermission.PUT)
                    || form.getFieldValue(`special_is_closed_${specialDayKey[i]}`)
                  }
                  minuteStep={30}
                  format={format}
                  suffixIcon={<div />}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...specialDayItemLayout}
              label={
                <TextNormal color={purple}>{t(constants.CLOSE)}</TextNormal>
              }
              required={false}
              hasFeedback
            >
              {getFieldDecorator(`special_close_${specialDayKey[i]}`, {
                initialValue: oldSpecial
                  ? moment(getTime(_.get(oldSpecial, ['close'])), format)
                  : moment('20:00', format),
                getValueFromEvent: normTime(format),
                rules: [
                  {
                    required: true,
                    message: t(constants.ERROR_CLOSE)
                  },
                  {
                    validator: validateTime(
                      `special_open_${specialDayKey[i]}`,
                      `special_close_${specialDayKey[i]}`,
                      t(constants.ERROR_TIME)
                    )
                  }
                ]
              })(
                <TimePicker
                  style={{
                    width: '100%'
                  }}
                  disabled={
                    !isAllow(allow.PUT, allowPermission.PUT)
                    || form.getFieldValue(`special_is_closed_${specialDayKey[i]}`)
                  }
                  minuteStep={30}
                  format={format}
                  suffixIcon={<div />}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              {...remarkLayout}
              label={
                <TextNormal color={purple}>{t(constants.REMARK)}</TextNormal>
              }
              required={false}
              hasFeedback
            >
              {getFieldDecorator(`special_remark_${specialDayKey[i]}`, {
                initialValue: _.get(oldSpecial, ['remark'])
              })(
                <Input
                  disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                  placeholder=""
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            {isAllow(allow.PUT, allowPermission.PUT) && (
              <Form.Item>
                <Button
                  type={
                    form.getFieldValue(`special_is_closed_${specialDayKey[i]}`)
                      ? ''
                      : 'dashed'
                  }
                  className={
                    form.getFieldValue(`special_is_closed_${specialDayKey[i]}`)
                      ? 'is_closed'
                      : ''
                  }
                  onClick={setClosed(specialDayKey[i])}
                >
                  {t({ en: 'closed', th: 'ปิดทำการ' })}
                </Button>
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            {isAllow(allow.PUT, allowPermission.PUT) && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={removeSpecialDayKey(specialDayKey[i])}
                  style={{ width: '70%' }}
                >
                  <Icon type="minus" />
                  {t({ en: 'remove', th: 'ลบ' })}
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator(`special_is_closed_${specialDayKey[i]}`, {
            initialValue: _.get(oldSpecial, ['is_closed'])
          })(<Checkbox />)}
        </Form.Item>
      </React.Fragment>
    );
  });

  return (
    <Modal
      width={650}
      visible={open}
      title={
        branch
          ? t(
            isAllow(allow.PUT, allowPermission.PUT)
              ? constants.EDIT_BRANCH
              : constants.DETAIL
          )
          : t(constants.ADD_BRANCH)
      }
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        isAllow(allow.PUT, allowPermission.PUT) && (
          <Button
            key="submit"
            type="primary"
            loading={isSubmitLoading}
            onClick={handleSubmit}
          >
            {t(constants.SUBMIT)}
          </Button>
        )
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Form>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.BRANCH_CODE)}
                  </TextNormal>
)}
                help={
                  !_.get(branch, 'code') ? t(constants.ERROR_BRANCH_CODE) : ''
                }
                required={false}
                hasFeedback
              >
                {getFieldDecorator('code', {
                  initialValue: _.get(branch, 'code'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      pattern: isBranchCode,
                      required: true,
                      whitespace: false,
                      message: t(constants.ERROR_BRANCH_CODE)
                    }
                  ]
                })(
                  <Input
                    disabled={
                      !!_.get(branch, 'code')
                      || !isAllow(allow.PUT, allowPermission.PUT)
                    }
                    placeholder={t(constants.INPUT_BRANCH_CODE)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.BRANCH_NAME)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('name', {
                  initialValue: _.get(branch, 'name'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: false,
                      message: t(constants.ERROR_BRANCH_NAME)
                    }
                  ]
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_BRANCH_NAME)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.BRANCH_LOCATION)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('location', {
                  initialValue: _.get(branch, 'location'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: false,
                      message: t(constants.ERROR_BRANCH_LOCATION)
                    }
                  ]
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_BRANCH_LOCATION)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.BRANCH_ROOMS)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('rooms', {
                  initialValue: _.toString(_.get(branch, 'rooms', 6)),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      message: t(constants.ERROR_BRANCH_ROOMS)
                    }
                  ]
                })(
                  <InputNumber
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    min={0}
                    placeholder={t(constants.INPUT_BRANCH_ROOMS)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.DETAIL_ADDRESS)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('detail_address', {
                  initialValue: _.get(branch, ['detail', 'address'])
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_DETAIL_ADDRESS)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.DETAIL_DISTRICT)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('detail_district', {
                  initialValue: _.get(branch, ['detail', 'district'])
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_DETAIL_DISTRICT)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.DETAIL_PROVINCE)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('detail_province', {
                  initialValue: _.get(branch, ['detail', 'province'])
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_DETAIL_PROVINCE)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={(
                  <TextNormal color={purple}>
                    {t(constants.DETAIL_ZIPCODE)}
                  </TextNormal>
)}
                required={false}
                hasFeedback
              >
                {getFieldDecorator('detail_zipcode', {
                  initialValue: _.get(branch, ['detail', 'zipcode'])
                })(
                  <InputNumber
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    min={0}
                    placeholder={t(constants.INPUT_DETAIL_ZIPCODE)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              {telephoneItem}
              {telephoneKey.length === 3
              || !isAllow(allow.PUT, allowPermission.PUT) ? null : (
                <Form.Item {...addPhoneButtonLayout}>
                  <Button
                    // disabled={id === 2 || !isAllow(allow.PUT,allowPermission.PUT)}
                    type="dashed"
                    onClick={addTelephoneKey}
                    style={{ width: '75%', padding: '0 10px' }}
                  >
                    <Icon type="plus" />
                    {t(constants.ADD_PHONE)}
                  </Button>
                </Form.Item>
                )}
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutHalf}
                label={
                  <TextNormal color={purple}>{t(constants.EMAIL)}</TextNormal>
                }
                required={false}
                hasFeedback
              >
                {getFieldDecorator('email', {
                  initialValue: _.get(branch, 'email'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      pattern: isEmail,
                      required: false,
                      whitespace: false,
                      message: t(constants.ERROR_EMAIL)
                    }
                  ]
                })(
                  <Input
                    disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                    placeholder={t(constants.INPUT_EMAIL)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            {...openItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.BRANCH_OPEN_DETAIL_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('open_detail_th', {
              initialValue: _.get(branch, ['open_detail', 'th']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_BRANCH_OPEN_DETAIL_TH)
                }
              ]
            })(
              <TextArea
                rows={2}
                disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                placeholder={t(constants.INPUT_BRANCH_OPEN_DETAIL_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...openItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.BRANCH_OPEN_DETAIL_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('open_detail_en', {
              initialValue: _.get(branch, ['open_detail', 'en']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_BRANCH_OPEN_DETAIL_EN)
                }
              ]
            })(
              <TextArea
                rows={2}
                disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                placeholder={t(constants.INPUT_BRANCH_OPEN_DETAIL_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label={(
              <TextNormal color={purple}>
                {t(constants.BRANCH_PICTURE)}
              </TextNormal>
)}
            help={`${t(
              constants.ERROR_IMAGE_RATIO
            )} = 16 : 9 (1920pixel X 1080pixel) ${t(constants.REQUIRED)}`}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('picture', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  validator: validateImage
                }
              ]
            })(
              <Upload
                disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                name="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                <Button>
                  <Icon type="upload" />
                  {t(constants.INPUT_BRANCH_PICTURE)}
                </Button>
                <div
                  style={{
                    width: '100%',
                    margin: '20px 0 0',
                    padding: '10px',
                    border: 'solid 1px #0005',
                    borderRadius: '10px'
                  }}
                >
                  <img
                    src={previewImage || '/content/img/placeholder.jpg'}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
              </Upload>
            )}
          </Form.Item>
          <Row>
            <TextNormal color={purple}>
              {`${t(constants.WORKING_HOURS)} :`}
            </TextNormal>
          </Row>
          {_.map(shifts, o => (
            <React.Fragment key={_.get(o, ['shift'])}>
              <Row>
                <TextBold fontSize="14px" color={gray}>
                  {t(constants[`${_.upperCase(_.get(o, ['shift']))}_SHIFT`])}
                </TextBold>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayoutHalf}
                    label={(
                      <TextNormal color={purple}>
                        {t(constants.WORKING_START)}
                      </TextNormal>
)}
                    required={false}
                    hasFeedback
                  >
                    {getFieldDecorator(`${_.get(o, ['shift'])}_start`, {
                      initialValue: _.get(branch, [
                        'work_start',
                        `${_.get(o, ['shift'])}_shift`
                      ])
                        ? moment(
                          getTime(
                            _.get(branch, [
                              'work_start',
                              `${_.get(o, ['shift'])}_shift`
                            ])
                          ),
                          format
                        )
                        : moment(_.get(o, ['defaultStart']), format),
                      getValueFromEvent: normTime(format),
                      rules: [
                        {
                          required: true
                        },
                        {
                          validator: validateTime(
                            `${_.get(o, ['shift'])}_start`,
                            `${_.get(o, ['shift'])}_end`,
                            t(constants.ERROR_WORKING_TIME)
                          )
                        }
                      ]
                    })(
                      <TimePicker
                        disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                        minuteStep={30}
                        format={format}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayoutHalf}
                    label={(
                      <TextNormal color={purple}>
                        {t(constants.WORKING_END)}
                      </TextNormal>
)}
                    required={false}
                    hasFeedback
                  >
                    {getFieldDecorator(`${_.get(o, ['shift'])}_end`, {
                      initialValue: _.get(branch, [
                        'work_end',
                        `${_.get(o, ['shift'])}_shift`
                      ])
                        ? moment(
                          getTime(
                            _.get(branch, [
                              'work_end',
                              `${_.get(o, ['shift'])}_shift`
                            ])
                          ),
                          format
                        )
                        : moment(_.get(o, ['defaultEnd']), format),
                      getValueFromEvent: normTime(format),
                      rules: [
                        {
                          required: true
                        },
                        {
                          validator: validateTime(
                            `${_.get(o, ['shift'])}_start`,
                            `${_.get(o, ['shift'])}_end`,
                            t(constants.ERROR_WORKING_TIME)
                          )
                        }
                      ]
                    })(
                      <TimePicker
                        disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                        minuteStep={30}
                        format={format}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          ))}
          <Form.Item
            label={(
              <TextNormal color={purple}>
                {t(constants.AVAILABLE_COURSE)}
              </TextNormal>
)}
            required={false}
          >
            <Table
              className="components-table-demo-nested"
              rowKey="id"
              columns={columns}
              loading={isGetCourseLoading}
              onChange={handleTableChange}
              pagination={pagination}
              dataSource={courseData}
            />
          </Form.Item>
          <Row>
            <TextNormal color={purple}>{t(constants.SERVICE_HOURS)}</TextNormal>
          </Row>
          {_.map(_.get(branch, 'open', defaultTime), (day, i) => {
            if (i === '_id') return null;
            return (
              <React.Fragment key={i}>
                <Row>
                  <TextBold fontSize="14px" color={gray}>
                    {moment()
                      .day(day.key)
                      .format('ddd')}
                  </TextBold>
                </Row>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayoutHalf}
                      label={(
                        <TextNormal color={purple}>
                          {t(constants.OPEN)}
                        </TextNormal>
)}
                      required={false}
                      hasFeedback
                    >
                      {getFieldDecorator(`open_${i}`, {
                        initialValue: _.get(day, 'time')
                          ? moment(getTime(_.get(day, 'time')), format)
                          : moment('10:00', format),
                        getValueFromEvent: normTime(format),
                        rules: [
                          {
                            required: true,
                            message: t(constants.ERROR_OPEN)
                          },
                          {
                            validator: validateTime(
                              `open_${i}`,
                              `close_${i}`,
                              t(constants.ERROR_TIME)
                            )
                          }
                        ]
                      })(
                        <TimePicker
                          disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                          minuteStep={30}
                          format={format}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayoutHalf}
                      label={(
                        <TextNormal color={purple}>
                          {t(constants.CLOSE)}
                        </TextNormal>
)}
                      required={false}
                      hasFeedback
                    >
                      {getFieldDecorator(`close_${i}`, {
                        initialValue: _.get(branch, ['close', i, 'time'])
                          ? moment(
                            getTime(_.get(branch, ['close', i, 'time'])),
                            format
                          )
                          : moment('20:00', format),
                        getValueFromEvent: normTime(format),
                        rules: [
                          {
                            required: true,
                            message: t(constants.ERROR_CLOSE)
                          },
                          {
                            validator: validateTime(
                              `open_${i}`,
                              `close_${i}`,
                              t(constants.ERROR_TIME)
                            )
                          }
                        ]
                      })(
                        <TimePicker
                          disabled={!isAllow(allow.PUT, allowPermission.PUT)}
                          minuteStep={30}
                          format={format}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
          {renderSpecialDay}
          {isAllow(allow.PUT, allowPermission.PUT) && (
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item {...addSpecialDayButtonLayout}>
                  <Button
                    type="dashed"
                    onClick={addSpecialDayKey}
                    style={{ width: '93%' }}
                  >
                    <Icon type="plus" />
                    {t(constants.ADD_SPECIAL_DAY)}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
