import React, { useState, useContext, useEffect } from 'react';
import { Layout, Table, Icon, Divider, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock
} from '../../style/main';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import AddButton from '../../components/Button/AddButton';
import Plus from '../../assets/svg/Plus';
import Dashboard from '../../components/Dashboard';
import Filter, { useFilter } from '../../components/Filter';
import { useToggle, useDebounce } from '../../hooks';
import { useDropdown } from '../../components/Dropdown';
import { MainContext } from '../../context/main';
import {
  formatPhoneNumber,
  isAllow,
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage,
  getTime
} from '../../utils';
import { LanguageContext } from '../../context/language';
import Form from './form';

const { Content } = Layout;

const CourseScreen = ({ allow, isBranchesFilter }) => {
  const { t } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const { open, toggle } = useToggle();
  const { request, constants, isDesktop } = useContext(MainContext);

  const { open: desc, toggle: changeDesc } = useToggle();
  const { value: currentSortKey, handleSelect: handleSortKey } = useDropdown();
  const {
    value: currentFilterKey,
    handleSelect: handleFilterKey
  } = useDropdown();

  const { value: currentFilterValue, handler } = useFilter();

  const getData = async (params) => {
    setIsLoading(true);
    const res = await request('/courses', {
      query: {
        ...params
      }
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);

    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const openForm = course => (e) => {
    const id = _.get(course, 'id');
    setCourseId(id);
    toggle();
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getData({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  const onSubmit = () => {
    getData();
  };

  const handleDelete = id => async (e) => {
    setIsLoading(true);
    const res = await request('/courses', {
      method: 'delete',
      query: {
        id
      }
    });
    setIsLoading(false);
    await getData();
  };

  const columns = [
    {
      title: t(constants.COURSE_TITLE),
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.COURSE_TITLE)}`
        )),
      render: title => (
        <div style={{ width: '200px' }}>
          <TextNormal fontSize="14px" color={purple}>
            {`${t(constants.COURSE_TITLE_EN)} : `}
          </TextNormal>
          <div>{`${title.en}`}</div>
          <TextNormal fontSize="14px" color={purple}>
            {`${t(constants.COURSE_TITLE_TH)} : `}
          </TextNormal>
          <div>{`${title.th}`}</div>
        </div>
      )
    },
    {
      title: t(constants.COURSE_PRICE),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.COURSE_TYPE),
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      ...(!isDesktop && { sorter: () => {} }),
      render: type => t(constants[`COURSE_${_.upperCase(type)}`])
    },
    {
      title: '',
      width: '10%',
      key: 'unique',
      render: o => (
        <span>
          <Icon
            onClick={openForm(o)}
            type={!isAllow(allow.PUT) ? 'zoom-in' : 'edit'}
            className="custom"
          />
          <Divider type="vertical" />
          <Popconfirm
            disabled={!isAllow(allow.DELETE)}
            title={t(constants.SURE_DELETE)}
            onConfirm={handleDelete(o.id)}
          >
            <Icon
              className={!isAllow(allow.DELETE) ? 'disabled' : 'custom'}
              type="delete"
            />
          </Popconfirm>
        </span>
      )
    }
  ];

  const debouncedFilterValue = useDebounce(currentFilterValue, 500);

  useEffect(() => {
    if (currentSortKey && currentFilterKey && !_.isNil(debouncedFilterValue)) {
      getData({
        ...(debouncedFilterValue && {
          [currentFilterKey]: debouncedFilterValue
        }),
        sort: { id: currentSortKey, desc }
      });
    }
  }, [debouncedFilterValue, currentFilterKey]);

  useEffect(() => {
    if (currentSortKey) {
      getData({
        sort: { id: currentSortKey, desc }
      });
    }
  }, [desc, currentSortKey]);

  const sortItems = [
    { id: 'type', title: t(constants.COURSE_TYPE) },
    { id: 'price', title: t(constants.COURSE_PRICE) }
  ];

  const filterItems = [{ id: 'title', title: t(constants.COURSE_TITLE) }];

  return (
    <>
      <HeaderContentBlock>
        <AddButtonBlock>
          {isAllow(allow.POST) && (
            <AddButton onClick={openForm(null)}>
              <Plus style={{ marginRight: '15px' }} />
              <TextBold color={purple}>{`${t(constants.ADD_COURSE)}`}</TextBold>
            </AddButton>
          )}
        </AddButtonBlock>
      </HeaderContentBlock>

      <Filter
        sortItems={sortItems}
        currentSortKey={currentSortKey}
        handleSortKey={handleSortKey}
        filterItems={filterItems}
        currentFilterKey={currentFilterKey}
        handleFilterKey={handleFilterKey}
        desc={desc}
        changeDesc={changeDesc}
        filterValue={currentFilterValue}
        onChange={handler}
      />
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
                dataSource={data}
                scroll={{ y: '35vh' }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form
        courseId={courseId}
        open={open}
        toggle={toggle}
        onSubmit={onSubmit}
        allow={allow}
      />
    </>
  );
};

export default CourseScreen;
