import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import PT from 'prop-types';
import { MaterialInputStyled } from './style';

const MaterialInput = ({ value, onChange, label, type, onClick }) => {
  const inputRef = useRef(null);

  return (
    <MaterialInputStyled valid={!!value}>
      <input
        ref={inputRef}
        id={label || _.uniqueId()}
        value={value}
        onChange={onChange}
        onClick={onClick}
        type={type}
      />
      <label htmlFor={label}>{label}</label>
      <span className="highlight" />
      <span className="bar" />
    </MaterialInputStyled>
  );
};

MaterialInput.propTypes = {
  type: PT.string,
  label: PT.string.isRequired,
  value: PT.string.isRequired,
  onChange: PT.func.isRequired,
  onClick: PT.func
};

MaterialInput.defaultProps = {
  type: 'text',

  onClick: () => {}
};
export default MaterialInput;
