import styled from 'styled-components';
import { white, primary } from '../../style/color';

export const SidebarStyle = styled.div`
  width: 180px;
  min-height: 100vh;
  background: ${primary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow: scroll; */

  @media only screen and (max-width: 1024px) {
    width: 140px;
  }
`;
