import React from 'react';

const RightArrow = ({ width, height, onClick }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.49 22.14"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M21.2.33A1.11,1.11,0,0,0,19.62,1.9l8.05,8H1.11A1.1,1.1,0,0,0,0,11.06a1.12,1.12,0,0,0,1.11,1.13H27.67l-8,8a1.14,1.14,0,0,0,0,1.59,1.11,1.11,0,0,0,1.58,0l10-9.95a1.1,1.1,0,0,0,0-1.57Z"
          fill="#561747"
        />
      </g>
    </g>
  </svg>
);

RightArrow.defaultProps = {
  width: '25.604px',
  height: '18px'
};
export default RightArrow;
