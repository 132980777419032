import React from 'react';

const Plus = ({ width, height, style, disabled }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 491.86 491.86"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M465.17,211.61H280.24V26.69C280.24,18.27,268.81,0,245.93,0s-34.32,18.27-34.32,26.69V211.61H26.69C18.27,211.61,0,223.05,0,245.93s18.27,34.31,26.69,34.31H211.61V465.17c0,8.42,11.44,26.69,34.32,26.69s34.32-18.27,34.32-26.69V280.24H465.17c8.42,0,26.69-11.43,26.69-34.31S473.59,211.61,465.17,211.61Z"
          fill={disabled ? '#727272' : '#561747'}
        />
      </g>
    </g>
  </svg>
);

Plus.defaultProps = {
  width: '17px',
  height: '17px'
};
export default Plus;
