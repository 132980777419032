import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import PT from 'prop-types';
import Axios from 'axios';
import { notification, Modal } from 'antd';
import { encode, decode, serialize } from '../utils/index';
import { useDropdown } from '../components/Dropdown';
import { getRoutes } from '../routes';
import { LanguageContext } from './language';
import { useWindowWidth } from '../hooks';

const MainContext = React.createContext();

const MainProvider = ({ children }) => {
  const { t } = useContext(LanguageContext);
  const [userProfile, setUserProfile] = useState(null);
  const [routes, setRoutes] = useState();
  const [mainLoading, setMainLoading] = useState(false);
  const [isAuth, setIsAuth] = useState();
  const [branches, setBranches] = useState([]);
  const [constants, setConstant] = useState({});

  const {
    value: currentBranch,
    handleSelect: handleSelectBranch
  } = useDropdown();

  const isAuthenticate = () => {
    const token = window.localStorage.getItem(
      process.env.REACT_APP_TOKEN_STORAGE
    );

    if (!token) {
      setRoutes(false); // manual set route
      setIsAuth(false);
      setUserProfile(null);
      return isAuth;
    }

    setIsAuth(true);
    return isAuth;
  };

  const storeToken = (token) => {
    const tokenObj = JSON.stringify(token);
    window.localStorage.setItem(
      process.env.REACT_APP_TOKEN_STORAGE,
      encode(tokenObj)
    );
  };

  const getToken = () => {
    const tokenObj = window.localStorage.getItem(
      process.env.REACT_APP_TOKEN_STORAGE
    );

    if (!tokenObj) return null;

    const token = decode(tokenObj);

    return JSON.parse(token);
  };

  const removeToken = () => {
    window.localStorage.removeItem(process.env.REACT_APP_TOKEN_STORAGE);

    isAuthenticate();
    setBranches([]);
    setUserProfile(null);
    setRoutes([]);
    handleSelectBranch(null);
  };

  const openNotification = (type = 'info', message) => {
    notification[type]({
      message: t(_.get(message, 'title', '')),
      description: t(_.get(message, 'detail', ''))
    });
  };

  const request = async (endpoint, config) => {
    try {
      const type = _.get(config, ['type']);
      const method = _.upperCase(_.get(config, 'method', 'get'));
      const query = _.get(config, 'query');
      const body = _.get(config, 'body', {});

      const path = `${process.env.REACT_APP_API_URL}${endpoint}${
        query ? `?${serialize(query)}` : ''
      }`;

      const token = getToken();

      if (!token) {
        const err = new Error();
        err.response = {
          status: 401,
          statusText: 'Unauthorized'
        };
        throw err;
      }

      const { token_type, access_token } = token;

      if (type === 'download') {
        const res = await Axios({
          url: path,
          method: 'GET',
          responseType: 'blob', // important
          headers: { Authorization: `${token_type} ${access_token}` }
        });

        const data = _.get(res, 'data');

        // console.log({ res, data });

        // return data;
        const fileName = _.get(config, ['fileName']);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        return;
      }

      const res = await Axios({
        method,
        headers: { Authorization: `${token_type} ${access_token}` },
        url: path,
        data: body
      });

      const data = _.get(res, 'data');
      console.log('main', { res });
      // console.log('request success', endpoint, '>>>>>', { data });

      if (_.includes(['POST', 'PUT', 'DELETE'], method)) {
        if (_.get(data, 'success')) {
          openNotification('success', {
            title: t(constants.SUCCESS),
            detail: _.get(data, 'message')
          });
        }
      }

      return data;
    } catch (error) {
      console.log({ error });
      const errorAlert = _.get(config, 'error_alert');
      let response = _.get(error, 'response');

      if (!response) {
        response = _.get(error, 'request');
      }

      // console.log('request error', endpoint, '>>>>>', { response });

      let title = _.get(response, ['data', 'title']);
      let detail = _.get(response, ['data', 'message']);

      const status = _.get(response, ['status']);

      if (!title) title = status === 0 ? 'Server Error' : _.get(response, ['status']);
      if (!detail) {
        detail = status === 0
          ? 'Server Not Response'
          : _.get(response, ['statusText']);
      }

      if (errorAlert !== 'none') {
        openNotification('error', {
          title,
          detail
        });
      }

      if (_.includes([401, 0], status)) {
        removeToken();

        return null;
      }

      return null;
    }
  };

  const login = async (loginData) => {
    setMainLoading(true);
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        loginData
      );

      const data = _.get(res, 'data');

      if (!data) {
        const err = new Error();
        err.response = {
          status: 401,
          statusText: 'Unauthorized'
        };
        throw err;
      }

      const profile = _.get(data, 'user');
      const token = _.get(data, 'token');

      if (!profile || !token) {
        const err = new Error();
        err.response = {
          status: 401,
          statusText: 'Unauthorized'
        };
        throw err;
      }

      storeToken(token);

      openNotification('success', {
        title: t(constants.SUCCESS),
        detail: _.get(data, 'message')
      });
    } catch (error) {
      let response = _.get(error, 'response');

      if (!response) {
        response = _.get(error, 'request');
      }

      let title = _.get(response, ['data', 'title']);
      let detail = _.get(response, ['data', 'message']);

      const status = _.get(response, ['status']);

      if (!title) title = status === 0 ? 'Server Error' : _.get(response, ['status']);
      if (!detail) {
        detail = status === 0
          ? 'Server Not Response'
          : _.get(response, ['statusText']);
      }

      openNotification('error', {
        title,
        detail
      });
    } finally {
      setMainLoading(false);
    }
  };

  const logout = () => {
    Modal.confirm({
      title: t(constants.SURE_LOGOUT_TITLE),
      content: t(constants.SURE_LOGOUT_DETAIL),
      onOk: async () => {
        // console.log('OK');
        const res = await request('/auth/logout');

        if (_.get(res, 'success')) {
          openNotification('success', {
            title: t(constants.SUCCESS),
            detail: _.get(res, 'message')
          });
        }

        removeToken();
      },
      onCancel() {
        // console.log('Cancel');
      }
    });
  };

  const getProfile = async () => {
    if (!isAuth) return;

    const profile = await request('/auth/profile');

    const endpoints = _.get(profile, ['permission', 'endpoints']);

    const availableRoutes = _.chain(endpoints)
      .map(o => getRoutes(o))
      .sortBy(o => _.get(o, 'order'))
      .value();

    setUserProfile(profile);
    setRoutes(availableRoutes);
  };

  const getBranches = async () => {
    if (!isAuth) return;
    const data = await request('/branches', {
      query: {
        includes: ['hq', 'not_active']
      }
    });

    const reference_data = _.get(data, ['reference_data']);

    const result = _.map(reference_data, o => ({ ...o, id: o.id, title: o.name }));

    if (_.toSafeInteger(_.get(result, ['length'])) > 1) {
      setBranches([
        { id: 'all', title: { en: 'All', th: 'ทั้งหมด' } },
        ...result
      ]);
    } else {
      setBranches(result);
    }
  };

  const getConstant = async () => {
    try {
      const res = await Axios.get('/content/json/language.json');

      setConstant(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!userProfile) {
      getProfile();
    }

    if (!_.get(branches, ['length'])) {
      getBranches();
    }
  }, [isAuth]);

  useEffect(() => {
    getConstant();
  }, []);

  return (
    <MainContext.Provider
      value={{
        userProfile,
        login,
        logout,
        isAuthenticate,
        request,
        mainLoading,
        setMainLoading,
        currentBranch,
        routes,
        branches,
        handleSelectBranch,
        openNotification,
        constants,
        refreshBranchFilter: getBranches,
        isDesktop: useWindowWidth() >= 1600,
        getToken,
        isAuth
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainProvider.propTypes = {
  children: PT.node.isRequired
};

MainProvider.defaultProps = {};

export { MainContext, MainProvider };
