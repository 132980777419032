import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 289.667 289.667"
    style={{ enableBackground: 'new00289.667289.667' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="white"
        id="path34461"
        d="M5,39.799c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h90c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H5z
        M254.926,39.799c-2.76,0.041-4.965,2.31-4.926,5.07v145.668h-25c-2.578,0.002-4.666,2.093-4.664,4.67
       c0.001,0.844,0.23,1.672,0.664,2.396l30,50c1.327,2.21,4.194,2.925,6.404,1.598c0.656-0.394,1.204-0.942,1.598-1.598l30-50
       c1.325-2.211,0.607-5.077-1.604-6.402c-0.725-0.434-1.553-0.664-2.398-0.664h-25V44.869c0.039-2.761-2.168-5.032-4.93-5.07
       C255.022,39.798,254.974,39.798,254.926,39.799z M10,49.799h80v40H10V49.799z M20,57.299c-1.381,0-2.5,1.119-2.5,2.5v15
       c-0.02,1.381,1.084,2.516,2.465,2.535c1.381,0.02,2.516-1.084,2.535-2.465c0-0.024,0-0.047,0-0.071v-12.5H45
       c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535c-0.024,0-0.047,0-0.071,0H20z M5,114.797
       c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h140c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H5z M10,124.797h130v40H10V124.797z
        M20,132.297c-1.381,0-2.5,1.119-2.5,2.5v15c-0.02,1.381,1.084,2.516,2.465,2.535s2.516-1.084,2.535-2.465c0-0.024,0-0.047,0-0.071
       v-12.5H45c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535c-0.024,0-0.047,0-0.071,0H20z M5,189.797
       c-2.761,0-5,2.239-5,5v50c0,2.761,2.239,5,5,5h190c2.761,0,5-2.239,5-5v-50c0-2.761-2.239-5-5-5H5z M10,199.797h180v40H10V199.797z
        M233.242,199.869h19.797c1.254,0.535,2.672,0.535,3.926,0h19.795l-21.758,36.266L233.242,199.869z M245.049,202.674
       c-1.38-0.037-2.529,1.053-2.566,2.433c-0.013,0.503,0.125,0.998,0.398,1.42l10,16c0.707,1.186,2.241,1.575,3.427,0.869
       c1.186-0.707,1.575-2.241,0.869-3.427c-0.018-0.03-0.037-0.06-0.056-0.09l-10-16C246.68,203.151,245.9,202.697,245.049,202.674z
        M20,207.297c-1.381,0-2.5,1.119-2.5,2.5v15c-0.02,1.381,1.084,2.516,2.465,2.535s2.516-1.084,2.535-2.465c0-0.024,0-0.047,0-0.071
       v-12.5H45c1.381,0.02,2.516-1.084,2.535-2.465c0.02-1.381-1.084-2.516-2.465-2.535c-0.024,0-0.047,0-0.071,0H20z"
      />
    </g>
  </svg>
);
