import styled from 'styled-components';
import posed from 'react-pose';
import { primary, white, purple } from '../../style/color';

export const ButtonAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: ({ disabled }) => (disabled ? 0.2 : 1) },
  press: {
    scale: ({ disabled }) => (disabled ? 1 : 0.8),
    opacity: ({ disabled }) => (disabled ? 0.2 : 0.5)
  }
});

export const ButtonStyle = styled(ButtonAnimate)`
  padding: 15px 30px;
  background: ${(props) => {
    if (props.variant === 'primary') {
      return primary;
    }

    return white;
  }};
  box-shadow: ${(props) => {
    if (!props.active && props.variant === 'primary') {
      return '0px 4px 10px 0px rgba(0, 0, 0, 0.1)';
    }
    return 'none';
  }};
  color: ${(props) => {
    if (props.variant === 'primary') {
      return white;
    }

    return '#000';
  }};
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
`;

export const IconButtonStyle = styled(ButtonStyle)``;

export const AddButtonStyle = styled(ButtonAnimate)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.09);
  color: ${purple};
  padding: 15px 18px;
  cursor: pointer;
`;
