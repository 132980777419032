import styled from 'styled-components';
import posed from 'react-pose';
import { secondary } from '../../style/color';

export const RoomsBlock = styled.div`
  height: ${props => (props.autoHeight ? 'auto' : '100%')};
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow-x: scroll;
  overflow-y: visible;
  cursor: pointer;
`;

export const HalfBlock = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  overflow-x: scroll;
  cursor: pointer;
`;

export const Column = styled.div`
  width: ${props => (props.width ? props.width : 174)}px;
  display: flex;
  flex-flow: column nowrap;
  border-right: 2px solid #afaeaf;
  flex: 1 0 auto;
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  &:last-child {
    border-right: none;
  }
  padding-bottom: 16px;
`;

export const IconExpand = styled.div`
  border-radius: 30px 0 0 30px;
  width: 50px;
  height: 100px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TableBlock = styled.div`
  width: 100%;
  position: relative;

  .ant-table-thead > tr > th {
    background: #f2f2f2 !important;
    color: #000 !important;
    text-align: center;
    /* .ant-table-header-column {
      display: block;
    } */
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px 8px;
    border-right: 2px solid #afaeaf;
    /* &:nth-last-child(4), */
    &:last-child {
      border-right: none;
    }
    /* &:last-child {
      border-right: none;
    } */
  }
`;

export const HeadRow = styled.div`
  width: 100%;
  padding: 15px 0;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBlock = styled.div`
  width: 100%;
  height: 190px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 2px solid #e9e8e9;
  /* flex: 0 0 auto; */
  background-color: ${({ active }) => (active ? '' : '#0001')};
  cursor: ${({ active }) => (active ? 'pointer' : '')};
  &:last-child {
    border-bottom: none;
  }
`;

export const TimeText = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`;

export const ButtonAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: ({ disabled }) => (disabled ? 0.2 : 1) },
  press: {
    scale: ({ disabled }) => (disabled ? 1 : 0.8),
    opacity: ({ disabled }) => (disabled ? 0.2 : 0.5),
  },
});
export const Card = styled(ButtonAnimate)`
  width: 100%;
  position: relative;
  cursor: pointer;
  /* background-color: #000; */
`;

export const CardActive = styled(ButtonAnimate)`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 6px 20px;

  border-radius: 3px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
  background-color: ${(props) => {
    if (props.highlight) return '#feffc6';
    if (props.disable) return '#dddddd';

    return '#fff';
  }};

  .booking_pencil {
    /* position: relative; */
    /* left: 95%; */
    position: absolute;
    top: 7px;
    left: 85%;
  }
`;

export const StaffLabel = styled.p`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #561847;
  margin-bottom: 0;
`;

export const AccountType = styled.p`
  font-family: 'Kanit';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => {
    switch (props.type) {
      case 'ac':
        return '#fe63bd';
      case 'confirm':
        return '#279c24';
      case 'new':
        return '#f7b158';
      case 'no_show':
      case 'pending':
        return '#9c3b24';
      case 'show':
        return '#24619c';
      case 'cancel':
        return '#0005';
      case 'is_new':
        return '#00C8FE';
      case 'black':
        return '#000';
      default:
        return '#fff';
    }
  }};
  margin-bottom: 3px;
`;


export const BookingBy = styled(AccountType)`
  font-size: 14px;
  white-space: normal;
  color: ${(props) => {
    switch (props.type) {
      // case 'shop':
      //   return '#fe63bd';
      // case 'phone':
      //   return '#279c24';
      // case 'online(web booking)':
      //   return '#f7b158';
      // case 'new 1,600':
      //   return '#00C8FE';
      default:
        return 'blue';
    }
  }};
`;

export const CustomerDetail = styled.p`
  font-family: 'Kanit';
  font-size: 12px;
  font-weight: 300;
  line-height: 1.17;
  color: #333333;
  margin-bottom: 0.3em;
  &.first-child {
    margin-bottom: 0;
  }
`;

export const DrawerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const DrawerContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow: visible hidden; */
  overflow: visible;
  padding: 25% 0 0;
`;

export const DrawerToggle = styled.div`
  position: absolute;
  width: ${({ active }) => (active ? 'calc(100% + 161px)' : 'calc(100% + 520px)')};
  height: 80px;
  transition: all 0.3s;
  /* z-index: -1; */
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1024px) {
    width: ${({ active }) => (active ? '125%' : '168%')};
  }
`;

export const DrawerContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  padding-left: 195px;
  padding-right: 15px;

  @media only screen and (max-width: 1024px) {
    padding-left: 155px;
  }
`;

export const DrawerContent = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentBlockWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  div:nth-child(odd) {
    background: #f2f2f2f2;

    &:hover {
      background: #0004;
    }
  }

  div:nth-child(even) {
    background: #fff;

    &:hover {
      background: #0004;
    }
  }
`;

export const ContentBlock = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 2px 0;
  border-radius: 2px;
  border: solid 1px #e1e1e1;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.09);
  padding: 0 20px;
`;

export const Underline = styled.hr`
  width: 100%;
  height: 2px;
  background: ${secondary};
`;

export const ContentBlockButton = styled(ButtonAnimate)`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent !important;
  cursor: pointer;
`;
