import React from 'react';

const LoginHead = () => (
  <svg width="279px" height="115px" viewBox="0 0 279 115" version="1.1">
    <title>Head</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <filter
        x="-6.8%"
        y="-7.2%"
        width="113.7%"
        height="114.3%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="8.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.31602382 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      id="Managing-staff-2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="signin-admin" transform="translate(-438.000000, -271.000000)">
        <g
          id="Group"
          filter="url(#filter-1)"
          transform="translate(453.000000, 286.000000)"
        >
          <g id="Head" transform="translate(2.000000, 0.000000)">
            <path
              d="M3,0 L209.07751,-4.4408921e-15 C210.249106,-1.61025637e-14 211.313505,0.68203447 211.803057,1.74644824 L245.607877,75.2469119 C246.300195,76.7521901 245.64116,78.5336934 244.135881,79.2260109 C243.74839,79.4042284 243.327352,79.4977734 242.90085,79.5004065 L3.0185207,80.9813646 C1.36169803,80.9915933 0.0102858725,79.6567652 5.71699593e-05,77.9999425 C1.90567439e-05,77.993769 3.55346973e-15,77.9875954 3.55271368e-15,77.9814218 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 Z"
              id="Rectangle-Copy-2"
              fill="#E9D0DD"
            />
            <text
              id="LOG-IN"
              fontFamily="Lora-Regular, Lora"
              fontSize="30"
              fontWeight="normal"
              line-spacing="17"
              letterSpacing="1.3125"
              fill="#561847"
            >
              <tspan x="44.8582292" y="50.8283019">
                LOG IN
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LoginHead;
