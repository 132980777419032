import React, { useState, useEffect, useContext } from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/language';
import { MainContext } from '../../context/main';

import {
  DropdownBlock,
  ActiveBlock,
  DropdownList,
  ParentDropdownList
} from './style';

import { getFilePath } from '../../utils/getRootPath';
import SortIcon from '../../assets/svg/Sort';

export const useDropdown = () => {
  const [state, setState] = useState('');

  const handleSelect = value => {
    setState(value);
  };

  return {
    value: state,
    handleSelect
  };
};

const Dropdown = ({
  data,
  onClick,
  current,
  withIcon,
  height,
  prefixCurrent
}) => {
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [list, setList] = useState([]);
  const { t } = useContext(LanguageContext);
  // const {  } = useContext(MainContext);
  const { pathname } = useLocation();

  const toggleDropdown = () => {
    setActiveDropDown(!activeDropDown);
  };
  const selectData = indexOf => {
    onClick(indexOf);
    setActiveDropDown(!activeDropDown);
  };
  const dataRemoveDataActive = _.omitBy(list, o => o.id === current);

  useEffect(() => {
    // if (!current) {
    //   const firstID = _.get(data, [0, 'id']);
    //   if (firstID) {
    //     onClick(firstID);
    //   }
    // }
    setList(_.filter(data, o => _.get(o, ['title']) !== 'สำนักงานใหญ่'));
  }, [data]);

  // useEffect(() => {
  //   if (!current) {
  //     const firstID = _.get(list, [0, 'id']);
  //     if (firstID) {
  //       onClick(firstID);
  //     }
  //   }
  // }, [list]);

  useEffect(() => {
    if (
      pathname === '/dashboard/staff-planing' ||
      pathname === '/dashboard/booking'
    ) {
      const notAll = _.find(list, o => _.get(o, ['id']) !== 'all');
      onClick(_.get(notAll, ['id']));
    } else if (!current) {
      const firstID = _.get(list, [0, 'id']);
      if (firstID) {
        onClick(firstID);
      }
    }
  }, [pathname, list]);

  return (
    <DropdownBlock
      icon={withIcon}
      className={`dropdown_block ${activeDropDown ? 'active' : ''}`}
      height={activeDropDown ? _.size(data) * height : height}
    >
      <ActiveBlock
        className="active_block"
        icon={withIcon}
        onClick={toggleDropdown}
        height={height}
      >
        {withIcon && (
          <img
            src={getFilePath(
              _.get(
                _.find(data, o => o.id === current),
                ['icon'],
                ''
              )
            )}
            alt=""
          />
        )}
        <h6>
          {`${prefixCurrent || ''}${t(
            _.get(
              _.find(data, o => o.id === current),
              ['title'],
              ''
            )
          )}`}
        </h6>
      </ActiveBlock>
      <ParentDropdownList
        icon={withIcon}
        childCount={_.get(data, 'length')}
        className="parent_dropdown_list"
        pose={activeDropDown ? 'open' : 'closed'}
        height={height}
      >
        {_.map(dataRemoveDataActive, o => {
          if (_.get(o, ['title']) === 'สำนักงานใหญ่') return null;

          return (
            <DropdownList
              className="dropdown_list"
              icon={withIcon}
              key={`item-${o.id}`}
              onClick={selectData.bind(this, o.id)}
              height={height}
            >
              {withIcon && (
                <img src={getFilePath(_.get(o, 'icon', ''))} alt="" />
              )}
              <h6>{t(_.get(o, 'title', ''))}</h6>
            </DropdownList>
          );
        })}
      </ParentDropdownList>
    </DropdownBlock>
  );
};

Dropdown.propTypes = {
  data: PT.arrayOf(PT.object),
  onClick: PT.func,
  current: PT.oneOfType([PT.string, PT.number]),
  withIcon: PT.bool,
  height: PT.number
};

Dropdown.defaultProps = {
  data: [],
  onClick: () => undefined,
  current: '',
  withIcon: false,
  height: 32
};

export default Dropdown;
