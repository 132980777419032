import React, { useContext } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import posed, { PoseGroup } from 'react-pose';
import LoginScreen from './screen/LogInScreen';
import HomeScreen from './screen/HomeScreen';
import Redirect from './screen/Redirect';
import { MainContext } from './context/main';
import DashBoard from './components/Dashboard';
import Logo from './assets/svg/NewLogo';
import { DashboardStyle } from './components/Dashboard/style';

function App() {
  const { routes, isAuthenticate } = useContext(MainContext);

  const isAuth = isAuthenticate();

  if (isAuth === undefined || routes === undefined) {
    return (
      <DashboardStyle>
        <div className="dashboard_logo">
          <Logo />
        </div>
      </DashboardStyle>
    );
  }

  return (
    <Router>
      {isAuth ? (
        <DashBoard>
          <Switch>
            <Route path="/dashboard/profile" exact component={HomeScreen} />
            {_.map(
              routes,
              route => route && (
              <Route
                key={route.path}
                path={route.path}
                exact
                    // component={route.component}
                render={routeProps => (
                  <route.component
                    {...routeProps}
                    allow={route.allow}
                    allowPermission={route.allowPermission}
                    isBranchesFilter={route.isBranchesFilter}
                  />
                )}
              />
              )
            )}
            <Route path="*" component={Redirect} />
          </Switch>
        </DashBoard>
      ) : (
        <Switch>
          <Route path="/" exact component={LoginScreen} />
          <Route path="*" component={Redirect} />
        </Switch>
      )}
    </Router>
  );
}

export default App;
