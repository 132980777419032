import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/font/stylesheet.css';
import './assets/font/fontello/css/fontello.css';
import * as serviceWorker from './serviceWorker';
import { MainProvider } from './context/main';
import { LanguageProvider } from './context/language';
import { SocketProvider } from './context/socket';

import 'antd/dist/antd.css';
import GlobalStyle from './style/global';

ReactDOM.render(
  <LanguageProvider>
    <MainProvider>
      <SocketProvider>
        <GlobalStyle />
        <App />
      </SocketProvider>
    </MainProvider>
  </LanguageProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
