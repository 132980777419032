import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  DatePicker,
  Checkbox,
  Upload,
  Icon
} from 'antd';
import _ from 'lodash';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import {
  dummyRequest,
  beforeUpload,
  normFile,
  isAllow,
  isPhoneNumber
} from '../../utils';

const { Option } = Select;

const ModalForm = ({
  isCreate,
  allow,
  visible,
  onCancel,
  onCreate,
  form,
  validateImage,
  previewImage,
  allowPermission
}) => {
  const { t } = useContext(LanguageContext);
  const { constants, branches } = useContext(MainContext);

  const { getFieldDecorator } = form;

  return (
    <Modal
      visible={visible}
      title={
        isCreate
          ? `${t(constants.POST)}${t(constants.STAFF)}`
          : `${t(
            isAllow(allow.PUT, allowPermission.PUT)
              ? constants.PUT
              : constants.DETAIL
          )}${t(constants.STAFF)}`
      }
      // okText={t(constants.POST)}
      // cancelText={t(constants.CANCEL)}
      onCancel={onCancel}
      // onOk={onCreate}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(constants.CANCEL)}
        </Button>,
        isAllow(allow.PUT, allowPermission.PUT) && (
          <Button key="submit" type="primary" onClick={onCreate}>
            {t(constants.SUBMIT)}
          </Button>
        )
      ]}
    >
      <Form layout="vertical">
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('id')(<Input />)}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.FULL_NAME)}</TextNormal>
          }
          help={t(constants.ERROR_FULL_NAME)}
          hasFeedback
        >
          {getFieldDecorator('full_name', {
            rules: [
              {
                required: true,
                message: t(constants.ERROR_FULL_NAME)
              }
            ]
          })(<Input disabled={!isAllow(allow.PUT, allowPermission.PUT)} />)}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.NICKNAME)}</TextNormal>
          }
          help={t(constants.ERROR_NICKNAME)}
          hasFeedback
        >
          {getFieldDecorator('nickname', {
            rules: [
              {
                required: true,
                message: t(constants.ERROR_NICKNAME)
              }
            ]
          })(<Input disabled={!isAllow(allow.PUT, allowPermission.PUT)} />)}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.TELEPHONE)}</TextNormal>
          }
          help={t(constants.ERROR_PHONE)}
          hasFeedback
        >
          {getFieldDecorator('telephone', {
            validateTrigger: ['onBlur'],
            rules: [
              {
                pattern: isPhoneNumber,
                whitespace: false,
                message: t(constants.ERROR_PHONE)
              }
            ]
          })(<Input disabled={!isAllow(allow.PUT, allowPermission.PUT)} />)}
        </Form.Item>
        <Form.Item
          label={<TextNormal color={purple}>{t(constants.EMAIL)}</TextNormal>}
          hasFeedback
        >
          {getFieldDecorator('email')(
            <Input disabled={!isAllow(allow.PUT, allowPermission.PUT)} />
          )}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.USER_BRANCH)}</TextNormal>
          }
          help={t(constants.ERROR_USER_BRANCH)}
          hasFeedback
        >
          {getFieldDecorator('branch_id', {
            validateTrigger: [],
            rules: [{ required: true, message: t(constants.ERROR_USER_BRANCH) }]
          })(
            <Select
              disabled={!isAllow(allow.PUT, allowPermission.PUT)}
              placeholder={t(constants.INPUT_USER_BRANCH)}
            >
              {_.map(
                branches,
                o => o.id !== 'all' && (
                <Option key={o.id} value={o.id}>
                  {t(o.title)}
                </Option>
                )
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.STAFF_PICTURE)}</TextNormal>
          }
          help={`${t(
            constants.ERROR_IMAGE_RATIO
          )} = 1 : 1 (200pixel X 200pixel) ${t(constants.NOT_REQUIRED)}`}
          required={false}
          hasFeedback
        >
          {getFieldDecorator('picture', {
            valuePropName: 'fileList',
            getValueFromEvent: normFile,
            rules: [
              {
                validator: validateImage
              }
            ]
          })(
            <Upload
              disabled={!isAllow(allow.PUT, allowPermission.PUT)}
              name="picture"
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <Button>
                <Icon type="upload" />
                {t(constants.INPUT_STAFF_PICTURE)}
              </Button>
              <div
                style={{
                  width: '100%',
                  margin: '20px 0 0',
                  padding: '10px',
                  border: 'solid 1px #0005',
                  borderRadius: '10px'
                }}
              >
                <img
                  src={previewImage || '/content/img/placeholder2.jpg'}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(ModalForm);
