import React, { useContext } from 'react';
import { SSEProvider } from 'react-hooks-sse';
import PT from 'prop-types';
import { DashboardStyle, ContentBlock } from './style';
import Logo from '../../assets/svg/NewLogo';
import { useWindowWidth } from '../../hooks';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { encode } from '../../utils';

import { MainContext } from '../../context/main';

const Dashboard = ({ children, contentPadding }) => (
  <DashboardStyle>
    {useWindowWidth() >= 1024 ? (
      <React.Fragment>
        <div className="sidebar_wrapper">
          <Sidebar />
        </div>
        <div className="content_wrapper">
          <Header />
          <ContentBlock padding={contentPadding} className="content">
            {children}
          </ContentBlock>
        </div>
      </React.Fragment>
    ) : (
      <div className="dashboard_logo">
        <Logo />
      </div>
    )}
  </DashboardStyle>
);

export default Dashboard;
