import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .popover_tag {
    width: 100%;
    padding: 5px;
    border-bottom: solid 1px #0001;

    div.ant-tag {
      cursor: pointer;
    }
  }
`;
