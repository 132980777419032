import React from 'react';
import { secondary, white } from '../../style/color';

const StaffPlaningIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 46"
    style={{ enableBackground: 'new00512512' }}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M28.5,11C22,11,18,14.39,18,19.87A1.12,1.12,0,0,0,19.1,21H37.9A1.11,1.11,0,0,0,39,19.87C39,14.4,35,11,28.5,11Zm-8.21,7.74c.73-5.06,6.4-5.48,8.21-5.48s7.48.42,8.21,5.48ZM28.5,0A5.27,5.27,0,0,0,23,5a5.29,5.29,0,0,0,5.5,5A5.27,5.27,0,0,0,34,5,5.27,5.27,0,0,0,28.5,0Zm0,7.89A3,3,0,0,1,25.32,5,3,3,0,0,1,28.5,2.11,3,3,0,0,1,31.68,5,3,3,0,0,1,28.5,7.89ZM10.5,36C4,36,0,39.39,0,44.87A1.12,1.12,0,0,0,1.11,46H19.9A1.12,1.12,0,0,0,21,44.87C21,39.4,17,36,10.5,36ZM2.29,43.74c.73-5.07,6.39-5.48,8.21-5.48s7.48.41,8.21,5.48ZM10.5,25A5.27,5.27,0,0,0,5,30a5.29,5.29,0,0,0,5.5,5A5.27,5.27,0,0,0,16,30,5.27,5.27,0,0,0,10.5,25Zm0,7.9a2.91,2.91,0,1,1,0-5.8,2.91,2.91,0,1,1,0,5.8Zm37,3.1C41,36,37,39.39,37,44.87A1.12,1.12,0,0,0,38.1,46H56.9A1.12,1.12,0,0,0,58,44.87C58,39.4,54,36,47.5,36Zm-8.21,7.74c.73-5.07,6.39-5.48,8.21-5.48s7.48.41,8.21,5.48ZM47.5,25A5.27,5.27,0,0,0,42,30a5.53,5.53,0,0,0,11,0A5.27,5.27,0,0,0,47.5,25Zm0,7.9A2.91,2.91,0,1,1,50.68,30,3,3,0,0,1,47.5,32.9ZM34.74,34.14l-4.6-4.59V23.09a1.11,1.11,0,0,0-2.21,0v6.46l-4.6,4.57a1.09,1.09,0,0,0,0,1.54h0a1.1,1.1,0,0,0,1.56,0L29,31.56l4.15,4.12a1.1,1.1,0,0,0,1.55-.12A1.08,1.08,0,0,0,34.74,34.14Z"
          fill={active ? white : secondary}
        />
      </g>
    </g>
  </svg>
);

export default StaffPlaningIcon;
