import styled from 'styled-components';
import posed from 'react-pose';
import { primary, white } from '../../style/color';

export const DropdownBlock = styled.div`
  background-color: ${white};
  position: relative;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); */
  width: 230px;
  height: ${({ height }) => height}px;
  border: solid 1px #0002;
  transition: all 0.3s;
  overflow: hidden;
  padding: 0 ${({ icon }) => (icon ? '25px' : '0')};
  color: ${primary};
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 180px;
  }

  :after {
    pointer-events: none;
    font-family: 'fontello';
    content: '\f176';
    font-size: 1.4rem;
    position: absolute;
    right: 10px;
    top: 0px;
  }
`;

const ActiveBlockAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: 1 },
  press: { scale: 0.8, opacity: 0.5 }
});

export const ActiveBlock = styled(ActiveBlockAnimate)`
  width: 100%;
  height: ${({ height }) => height}px;
  position: relative;
  display: flex;
  justify-content: ${({ icon }) => (icon ? 'flex-start' : 'flex-start')};
  align-items: center;
  padding: 0 15px;
  img {
    width: 15%;
  }

  h6 {
    margin: 0 0 0 ${({ icon }) => (icon ? '20px' : '0')};
  }
`;

const ParentDropdownListAnimate = posed.div({
  open: {
    x: '0%',
    delayChildren: 200,
    staggerChildren: ({ childCount }) => {
      if (!childCount || childCount <= 10) return 100;

      return 100 / (childCount * 0.1);
    }
  }
  // closed: { x: '-100%' }
});

export const ParentDropdownList = styled(ParentDropdownListAnimate)``;

const DropdownListAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: 1 },
  press: { scale: 0.8, opacity: 0.5 },
  open: { scale: 1, opacity: 1 },
  closed: { scale: 0.5, opacity: 0 }
});

export const DropdownList = styled(DropdownListAnimate)`
  width: 100%;
  height: ${({ height }) => height}px;
  position: relative;
  display: flex;
  justify-content: ${({ icon }) => (icon ? 'flex-start' : 'flex-start')};
  align-items: center;
  border-top: ${({ icon }) => (icon ? 'solid 1px #0001' : '')};
  padding: 0 15px;
  img {
    width: 15%;
  }

  h6 {
    margin: 0 0 0 ${({ icon }) => (icon ? '20px' : '0')};
  }
`;
