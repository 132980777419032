import styled from 'styled-components';
import { purple } from '../../style/color';

export const DateBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 362px;
  padding: 15px 30px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;

  svg {
    cursor: pointer;
  }

  .ant-calendar-picker {
    > div {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
`;

export const CalendarBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TextUnderLine = styled.div`
  border-bottom: 1px solid ${purple};
  cursor: pointer;
`;
