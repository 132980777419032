import { createGlobalStyle } from 'styled-components';
import { primary, white, secondary, purple } from './color';

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, h7, .h7, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	background: #f2f2f2;
	overflow: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
  all: unset;
}

/* custom */

body, p, input, button, div{
	font-family: 'Kanit';
    font-weight: 300;
	font-style: normal;
}

h1 {
	font-family: 'Kanit';
    font-weight: 500;
	font-style: normal;
	font-size: 24px;
}

h2 {
	font-family: 'Kanit';
  font-weight: 500;
	font-style: normal;
	font-size: 18px;
}

h3 {
	font-family: 'Kanit';
    font-weight: 500;
	font-style: normal;
	font-size: 14px;
}

h4 {
	font-family: 'Kanit';
    font-weight: 500;
	font-style: normal;
	font-size: 12px;
}

h5 {
	font-family: 'Kanit';
    font-weight: 300;
	font-style: normal;
	font-size: 12px;
}

.ant-spin-dot-item {
	background-color: ${primary} !important;
}

.ant-spin.ant-spin-lg {
	&.ant-spin-spinning {
		max-height : 100% !important;
		transform: scale(1.8);
	}
}

.ant-btn {
	font-family: 'Kanit';
    font-weight: 300;
	font-style: normal;
	border-color:  ${primary} !important;	
	color: ${primary} !important;

	&.is_closed {
		background-color: ${primary} !important;
		color: ${white} !important;

		&:active, &:hover, &.active {
			color: ${white} !important;
			background-color: ${secondary} !important;
		}
	}

	&.ant-btn-primary {
		background-color: ${primary} !important;
		color: ${white} !important;

		&:active, &:hover, &.active {
			color: ${white} !important;
			border-color: ${primary} !important;
		}
	}

	&.ant-btn-danger {
		color: ${white} !important;

		&:active, &:hover, &.active {
			color: ${white} !important;
		}
	}

	&:active, &:hover, &.active {
		color: ${primary} !important;
		border-color: ${primary} !important;
	}
}
.ant-pagination-item{
	&.ant-pagination-item-active {
		border-color:${primary} !important;

		a {
			color: ${primary} !important;
		}
	}
}

.ant-pagination-next{
	&:hover{
		.ant-pagination-item-link{
			color: ${primary} !important; 
    		border-color: ${primary} !important;
		}
	}
}

.ant-pagination-prev{
	&:hover{
		.ant-pagination-item-link{
			color: ${primary} !important; 
    		border-color: ${primary} !important;
		}
	}
}

.ant-table {
	&.ant-table-default {
		&.ant-table-scroll-position-left {
			overflow: scroll;
		}
	}
}

.ant-table-body, .ant-table-thead {
	margin: 0 !important;

	th {
		font-family: 'Kanit';
		font-weight: 500;
		font-style: normal;
		font-size: 18px;
		color: ${white} !important;
		background-color: ${primary} !important;

		@media only screen and (max-width: 1024px) {
			font-weight: 300;
			font-size: 16px;
		  }
		
		  @media only screen and (max-width: 768px) {
			font-weight: 300;
			font-size: 12px;
		  }
	}

	td {
		font-family: 'Kanit';
		font-weight: 300;
		font-style: normal;
		font-size: 16px;

		white-space: pre;

		@media only screen and (max-width: 1024px) {
			font-size: 12px;
		  }
		
		  @media only screen and (max-width: 768px) {
			font-size: 10px;
		  }
	}
}

.ant-descriptions { 
	.ant-descriptions-title {
		font-family: 'Kanit';
		font-weight: 500;
		font-style: normal;
		font-size: 24px;
		color: ${primary} !important;
	}

	.ant-descriptions-item-label {
		font-family: 'Kanit';
		font-weight: 300;
		font-style: normal;
		font-size: 18px;
		color: ${primary} !important;
	}

	.ant-descriptions-item-content{
		font-family: 'Kanit';
		font-weight: 300;
		font-style: normal;
		font-size: 18px;
	}
}

.ant-time-picker-input, .ant-input, .ant-input-number{
	&:focus, &:hover{
		border-color: ${primary} !important;
		box-shadow: 0 0 0 2px rgba(68, 25, 59, 0.2) !important;
	}
}

label.ant-radio-button-wrapper{
	margin: 10px !important;
	&:focus, &:hover{
		color: ${primary} !important;
		border-color: ${primary} !important;
		box-shadow: 0 0 0 2px rgba(68, 25, 59, 0.2) !important;

		&:before{
			background-color: ${primary} !important;
		}
	}
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled{
	&:focus, &:hover{
		color: rgba(0, 0, 0, 0.25) !important;
    	background-color: #f5f5f5 !important;
    	border-color: rgba(0, 0, 0, 0) !important;

		&:before{
			background-color: rgba(0, 0, 0, 0) !important;
		}
	}
}

label.ant-radio-button-wrapper-checked{
	border-color: ${primary} !important;
	box-shadow: 0 0 0 2px rgba(68, 25, 59, 0.2) !important;
	color: ${white} !important;
	background-color: ${primary} !important;
	&:before{
		background-color: ${primary} !important;
	}
	&:hover{
		color: ${white} !important;
	}
}

.ant-select-selection{
	&:focus, &:hover{
		border-color: ${primary} !important;
		box-shadow: 0 0 0 2px rgba(68, 25, 59, 0.2) !important;
	}
}

.ant-table-column-sorter-down.on, .ant-table-column-sorter-up.on{
	svg{
		fill: ${secondary} !important;
	}
}

.permission_form_modal {
	max-width: 1200px;
}

 /* Custom Modal */
 body{
	.ant-table-pagination{
		&.ant-pagination{
			margin: 16px;
		}
	}
	.ant-modal-content{
		border-radius: 0;
		.ant-modal-close-x{
			color: ${white};
		}
		.ant-modal-header{
			border-radius: 0;
			background: ${purple};
			.ant-modal-title{
				font-size: 24px;
				color: ${white};
				text-align: center;
			}
		}
	}
 }
 
.anticon{
	&.custom{
		svg{
			fill: ${primary} !important;
		}
	}
	 &.disabled{
		svg{
			fill: #0005 !important;
		}
	 }
 }

.fixedWidthTable table {
	table-layout: fixed;
  }
  
.fixedWidthTable .ant-table-tbody > tr > td {
	word-wrap: break-word;
	word-break: break-all;
	overflow: scroll hidden;
  }

.ant-modal-wrap {
	div.ant-tag {
		cursor: pointer;
	  }
}
.ant-switch-checked {
    background-color: ${secondary} !important;
}

.ant-menu-item {
	&:hover{
		color: ${secondary} !important;
		border-bottom: 2px solid ${secondary} !important;
	}
}

.ant-menu-item-selected{
	color: ${secondary} !important;
    border-bottom: 2px solid ${secondary} !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
	border-color: ${primary} !important;
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
	background-color: ${primary} !important;
	border-color: ${primary} !important;
}

.ant-drawer-content{
	overflow: visible !important;
}
`;

export default GlobalStyle;
