import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active, width, height, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="512px"
    height="512px"
    viewBox="0 0 410.487 410.486"
    style={{ enableBackground: 'new00410.487410.486' }}
    xmlSpace="preserve"
    className=""
  >
    <g>
      <g>
        <path
          d="M388.807,13.012H21.68C9.706,13.012,0,22.716,0,34.692v341.103c0,11.982,9.706,21.68,21.68,21.68h367.127   c11.973,0,21.68-9.697,21.68-21.68V34.697C410.487,22.722,400.78,13.012,388.807,13.012z M77.331,371.46H17.346v-50.955h59.985   V371.46z M77.331,307.504H17.346v-65.035h59.985V307.504z M77.331,229.451H17.346v-60.706h59.985V229.451z M77.331,155.735H17.346   v-53.114h59.985V155.735z M161.887,371.46h-71.55v-50.955h71.55V371.46z M161.887,307.504h-71.55v-65.035h71.55V307.504z    M161.887,229.451h-71.55v-60.706h71.55V229.451z M161.887,155.735h-71.55v-53.114h71.55V155.735z M239.936,371.46H174.89v-50.955   h65.046V371.46z M239.936,307.504H174.89v-65.035h65.046V307.504z M239.936,229.451H174.89v-60.706h65.046V229.451z    M239.936,155.735H174.89v-53.114h65.046V155.735z M313.643,371.46h-60.704v-50.955h60.704V371.46z M313.643,307.504h-60.704   v-65.035h60.704V307.504z M313.643,229.451h-60.704v-60.706h60.704V229.451z M313.643,155.735h-60.704v-53.114h60.704V155.735z    M393.14,371.46h-66.484v-50.955h66.484V371.46z M393.14,307.504h-66.484v-65.035h66.484V307.504z M393.14,229.451h-66.484v-60.706   h66.484V229.451z M393.14,155.735h-66.484v-53.114h66.484V155.735z M106.335,214.118l14.36-14.358l-14.36-14.361l5.003-5.004   l14.362,14.362l14.367-14.362l5.003,5.004l-14.37,14.361l14.37,14.358l-5.003,5.009l-14.367-14.366l-14.362,14.366L106.335,214.118   z"
          fill={active ? white : secondary}
        />
      </g>
    </g>
  </svg>
);
