import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { MainContext } from '../context/main';

const RedirectPage = () => {
  const { profile, isAuthenticate } = useContext(MainContext);

  return isAuthenticate() ? (
    // <Redirect to="/dashboard/report" />
    <Redirect to="/dashboard/profile" />
  ) : (
    <Redirect to="/" />
  );
};

export default RedirectPage;
