import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Icon,
  Button,
  Modal,
  Spin,
  Upload,
  InputNumber,
  Select,
  Popover,
  Tag,
  Row,
  Table
} from 'antd';
import moment from 'moment';
import { MainContext } from '../../context/main';
import {
  dummyRequest,
  isAllow,
  isImage,
  getBase64,
  isValidRatio,
  isValidSize,
  beforeUpload,
  normFile,
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps
} from '../../utils';
import { LanguageContext } from '../../context/language';
import { TagContainer } from '../PermissionScreen/style';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import PopOverTag from '../../components/PopOverTag';

const { TextArea } = Input;
const { Option } = Select;

const format = 'HH:mm';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12, offset: 1 }
  }
};

const DynamicFieldSet = ({ form, open, toggle, courseId, onSubmit, allow }) => {
  const { t } = useContext(LanguageContext);

  const [course, setCourse] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [isGetCourseListLoading, setIsGetCourseListLoading] = useState(false);
  const [bundleCourse, setBundleCourse] = useState([]);
  const [removeBundleCourses, setRemoveBundleCourses] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 5 });
  const [currentType, setCurrentType] = useState('');
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const { request, constants } = useContext(MainContext);
  const { getFieldDecorator } = form;

  const getCourseList = async (params) => {
    setIsGetCourseListLoading(true);
    const coursesRes = await request('/courses', {
      query: {
        ...params,
        excludes: ['special']
      }
    });

    const referenceCourseList = _.get(coursesRes, ['reference_data'], []);
    const totalCourseList = _.get(coursesRes, ['total_data'], 0);

    setCourseList(referenceCourseList);
    setPagination({ ...pagination, total: totalCourseList });
    setIsGetCourseListLoading(false);
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getCourseList({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  const popOverChange = (id) => {
    if (_.includes(bundleCourse, id)) {
      setBundleCourse(_.filter(bundleCourse, i => i !== id));

      const oldCourse = _.find(
        _.get(course, ['bundles']),
        o => _.get(o, ['bundle_course', '_id']) === id
      );

      if (oldCourse) {
        setRemoveBundleCourses([...new Set([...removeBundleCourses, id])]);
      }

      return;
    }
    setBundleCourse([...new Set([...bundleCourse, id])]);
  };

  const columns = [
    {
      title: t(constants.COURSE_TITLE),
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps(
        `${t(constants.SEARCH)}... ${t(constants.COURSE_TITLE)}`
      ),
      render: title => t(title)
    },
    {
      title: t(constants.COURSE_TYPE),
      dataIndex: 'type',
      key: 'type',
      sorter: () => {}
    },
    {
      title: t(constants.COURSE_PRICE),
      dataIndex: 'price',
      key: 'price',
      sorter: () => {}
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <PopOverTag
          activeTextItem="Add"
          inactiveTextItem="Remove"
          activeTextStatus="Bundle"
          inactiveTextStatus="None"
          allow={allow}
          status={_.includes(bundleCourse, o.id)}
          onChange={popOverChange}
          id={o.id}
        />
      )
    }
  ];

  const getData = async () => {
    if (!courseId) {
      setCurrentType('');
      setPreviewImage(null);
      setCourse(null);
      setBundleCourse([]);

      return;
    }
    setIsGetDataLoading(true);
    const res = await request('/courses', {
      query: {
        id: courseId
      }
    });

    const referenceData = _.get(res, ['reference_data', 0], []);

    const picture = _.get(referenceData, ['picture']);

    const type = _.get(referenceData, ['type']);

    const bundles = _.get(referenceData, ['bundles'], []);

    setIsGetDataLoading(false);
    setPreviewImage(picture || null);
    setCurrentType(type);
    setCourse(referenceData);
    setBundleCourse(
      _.map(bundles, o => _.get(o, ['bundle_course', '_id'], null))
    );
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      setPreviewImage(null);
      getData();
      setRemoveBundleCourses([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentType === 'special' && open) {
      getCourseList();
      setPagination({ ...pagination, current: 1 });
    }
  }, [open, currentType]);

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (
        currentType === 'special'
        && _.toSafeInteger(_.get(bundleCourse, ['length'])) <= 0
      ) {
        return;
      }

      if (!err) {
        setIsSubmitLoading(true);

        const body = new FormData();

        if (!course) {
          body.append('title_en', values.title_en);
          body.append('title_th', values.title_th);
          body.append('description_en', values.description_en);
          body.append('description_th', values.description_th);
          body.append('price', values.price);

          if (values.type) {
            body.append('type', values.type);
          }

          if (values.type === 'special') {
            _.times(_.size(bundleCourse), (i) => {
              body.append('new_bundles[]', bundleCourse[i]);
            });
          }

          body.append(
            'picture',
            _.get(values, ['picture', 0, 'originFileObj'])
          );
        } else {
          body.append('id', course.id);

          if (values.title_en !== _.get(course, ['title', 'en'])) {
            body.append('title_en', values.title_en);
          }

          if (values.title_th !== _.get(course, ['title', 'th'])) {
            body.append('title_th', values.title_th);
          }

          if (values.description_en !== _.get(course, ['description', 'en'])) {
            body.append('description_en', values.description_en);
          }

          if (values.description_th !== _.get(course, ['description', 'th'])) {
            body.append('description_th', values.description_th);
          }

          if (_.toSafeInteger(values.price) !== _.toSafeInteger(course.price)) {
            body.append('price', values.price);
          }

          _.times(_.size(bundleCourse), (i) => {
            if (
              !_.find(
                _.get(course, ['bundles']),
                o => _.get(o, ['bundle_course', '_id']) === bundleCourse[i]
              )
            ) {
              body.append('new_bundles[]', bundleCourse[i]);
            }
          });

          _.times(_.size(removeBundleCourses), (i) => {
            body.append('remove_bundles[]', removeBundleCourses[i]);
          });

          if (previewImage !== course.picture) {
            body.append(
              'picture',
              _.get(values, ['picture', 0, 'originFileObj'])
            );
          }
        }

        const res = await request('/courses', {
          method: !course ? 'post' : 'put',
          body
        });

        setIsSubmitLoading(false);

        if (res) {
          toggle();
          onSubmit();
        }
      }
    });
  };

  const validateImage = async (rule, value, callback) => {
    try {
      const fileValue = _.get(value, [0, 'originFileObj']);
      if (!fileValue) {
        if (previewImage === _.get(course, ['picture'])) {
          return callback();
        }
        return callback(t(constants.ERROR_IMAGE));
      }

      setPreviewImage(null);

      const fileType = _.get(fileValue, ['type']);

      const validFileType = isImage(fileType);

      if (!validFileType) return callback(t(constants.ERROR_IMAGE_TYPE));

      const fileSize = _.get(fileValue, ['size']);

      const validFileSize = isValidSize(fileSize);

      if (!validFileSize) return callback(t(constants.ERROR_IMAGE_LARGE));

      const valid = await isValidRatio(fileValue, 1);

      if (!valid) {
        return callback(
          `${t(constants.ERROR_IMAGE_RATIO)} = 1 : 1 (800pixel X 800pixel) ${t(
            constants.REQUIRED
          )}`
        );
      }

      getBase64(fileValue, imgUrl => setPreviewImage(imgUrl));
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const handleTypeChange = (value) => {
    setCurrentType(value);

    return value;
  };

  return (
    <Modal
      width={650}
      visible={open}
      title={
        course
          ? t(isAllow(allow.PUT) ? constants.EDIT_COURSE : constants.DETAIL)
          : t(constants.ADD_COURSE)
      }
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        isAllow(allow.PUT) && (
          <Button
            key="submit"
            type="primary"
            loading={isSubmitLoading}
            onClick={handleSubmit}
          >
            {t(constants.SUBMIT)}
          </Button>
        )
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Form>
          <Form.Item
            {...formItemLayoutHalf}
            label={
              <TextNormal color={purple}>{t(constants.COURSE_TYPE)}</TextNormal>
            }
            required={false}
            hasFeedback
          >
            {getFieldDecorator('type', {
              initialValue: _.get(course, 'type', 'optional'),
              getValueFromEvent: handleTypeChange
            })(
              <Select
                style={{ width: '100%' }}
                disabled={!isAllow(allow.PUT) || !!_.get(course, 'type')}
                placeholder={t(constants.INPUT_COURSE_TYPE)}
                min={0}
              >
                <Option value="main">{t(constants.COURSE_MAIN)}</Option>
                <Option value="optional">{t(constants.COURSE_OPTIONAL)}</Option>
                <Option value="special">{t(constants.COURSE_SPECIAL)}</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_TITLE_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('title_en', {
              initialValue: _.get(course, ['title', 'en']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_COURSE_TITLE_EN)
                }
              ]
            })(
              <Input
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_COURSE_TITLE_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_TITLE_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('title_th', {
              initialValue: _.get(course, ['title', 'th']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_COURSE_TITLE_TH)
                }
              ]
            })(
              <Input
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_COURSE_TITLE_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_DESCRIPTION_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('description_en', {
              initialValue: _.get(course, ['description', 'en']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_COURSE_DESCRIPTION_EN)
                }
              ]
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_COURSE_DESCRIPTION_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_DESCRIPTION_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('description_th', {
              initialValue: _.get(course, ['description', 'th']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_COURSE_DESCRIPTION_TH)
                }
              ]
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_COURSE_DESCRIPTION_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_PRICE)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('price', {
              initialValue: _.get(course, 'price'),
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_COURSE_PRICE)
                }
              ]
            })(
              <InputNumber
                style={{ width: '100%' }}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_COURSE_PRICE)}
                min={0}
              />
            )}
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label={(
              <TextNormal color={purple}>
                {t(constants.COURSE_PICTURE)}
              </TextNormal>
)}
            help={`${t(
              constants.ERROR_IMAGE_RATIO
            )} = 1 : 1 (800pixel X 800pixel) ${t(constants.REQUIRED)}`}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('picture', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  validator: validateImage
                }
              ]
            })(
              <Upload
                disabled={!isAllow(allow.PUT)}
                name="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                <Button>
                  <Icon type="upload" />
                  {t(constants.INPUT_COURSE_PICTURE)}
                </Button>
                <div
                  style={{
                    width: '100%',
                    margin: '20px 0 0',
                    padding: '10px',
                    border: 'solid 1px #0005',
                    borderRadius: '10px'
                  }}
                >
                  <img
                    src={previewImage || '/content/img/placeholder2.jpg'}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
              </Upload>
            )}
          </Form.Item>
          {currentType === 'special' && (
            <Form.Item
              // {...formItemLayout}
              validateStatus={
                currentType === 'special'
                && _.toSafeInteger(_.get(bundleCourse, ['length'])) <= 0
                  ? 'error'
                  : 'success'
              }
              help={
                currentType === 'special'
                && _.toSafeInteger(_.get(bundleCourse, ['length'])) <= 0
                  ? `* ${t(constants.ERROR_BUNDLE_COURSE)}`
                  : ''
              }
              label={(
                <TextNormal color={purple}>
                  {t(constants.BUNDLE_COURSE)}
                </TextNormal>
)}
              required={false}
              // hasFeedback
            >
              <Table
                className="components-table-demo-nested"
                rowKey="id"
                columns={columns}
                loading={isGetCourseListLoading}
                onChange={handleTableChange}
                pagination={pagination}
                dataSource={courseList}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
