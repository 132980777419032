import React, { useState, useContext } from 'react';
import { Layout, Descriptions, Table, Divider, Tag, Switch } from 'antd';
import _ from 'lodash';
import { ContainerBlock } from './style';
import Dashboard from '../../components/Dashboard';
import Button from '../../components/Button';
import { MainContext } from '../../context/main';
import { getPermissionColor } from '../../utils';
import Form from './form';
import { LanguageContext } from '../../context/language';
import { useToggle, useWindowWidth } from '../../hooks';

const { Content } = Layout;

const HomeScreen = () => {
  const { t } = useContext(LanguageContext);
  const { userProfile, logout, constants } = useContext(MainContext);
  const { open, toggle } = useToggle();
  const data = _.get(userProfile, ['permission', 'endpoints'], []);

  const columns = [
    {
      title: t(constants.ENDPOINT),
      dataIndex: 'path',
      key: 'path',
      width: '25%',
      render: text => <a href="javascript:;">{text}</a>
    },
    {
      title: t(constants.ENDPOINT_NAME),
      dataIndex: 'name',
      key: 'name',
      width: '25%'
    },
    {
      title: t(constants.GET),
      key: 'get',
      dataIndex: 'allow',
      width: '12.5%',
      render: (allow) => {
        const color = getPermissionColor(allow.GET);

        return <Tag color={color}>{allow.GET}</Tag>;
      }
    },
    {
      title: t(constants.POST),
      key: 'post',
      dataIndex: 'allow',
      width: '12.5%',
      render: (allow) => {
        const color = getPermissionColor(allow.POST);

        return <Tag color={color}>{allow.POST}</Tag>;
      }
    },
    {
      title: t(constants.PUT),
      key: 'put',
      dataIndex: 'allow',
      width: '12.5%',
      render: (allow) => {
        const color = getPermissionColor(allow.PUT);

        return <Tag color={color}>{allow.PUT}</Tag>;
      }
    },
    {
      title: t(constants.DELETE),
      key: 'delete',
      dataIndex: 'allow',
      width: '12.5%',
      render: (allow) => {
        const color = getPermissionColor(allow.DELETE);

        return <Tag color={color}>{allow.DELETE}</Tag>;
      }
    }
  ];

  return (
    <>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '50px 50px 0', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                padding: '24px',
                minHeight: 380
              }}
            >
              {userProfile && (
                <React.Fragment>
                  <Descriptions
                    column={{ sm: 2, md: 2, lg: 2, xl: 2, xxl: 4 }}
                    title={t(constants.USER_INFO)}
                  >
                    <Descriptions.Item label={t(constants.USERNAME)}>
                      {_.get(userProfile, 'username')}
                    </Descriptions.Item>
                    <Descriptions.Item label={t(constants.NAME)}>
                      {`${_.get(userProfile, ['full_name'])}`}
                    </Descriptions.Item>
                    <Descriptions.Item label={t(constants.POSITION)}>
                      {_.get(userProfile, ['permission', 'type'])}
                    </Descriptions.Item>
                    <Descriptions.Item label={t(constants.BRANCH)}>
                      {_.get(userProfile, ['branch', 'name'])}
                    </Descriptions.Item>
                  </Descriptions>
                  <Button onClick={toggle} className="logout_btn">
                    {t(constants.CHANGE_PASSWORD)}
                  </Button>
                  <hr />
                  <Descriptions title={t(constants.USER_PERMISSION)} />
                  <Table
                    rowKey="_id"
                    columns={columns}
                    dataSource={data}
                    pagination={{ pageSize: 5 }}
                    scroll={{ y: '25vh' }}
                  />
                  <Button onClick={logout} className="logout_btn">
                    {t(constants.LOGOUT_BUTTON)}
                  </Button>
                </React.Fragment>
              )}
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form open={open} toggle={toggle} />
    </>
  );
};

export default HomeScreen;
