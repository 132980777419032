import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ className }) => (
  <svg
    width="13px"
    height="13px"
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g
      id="Planning-services-r2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="1-home"
        transform="translate(-543.000000, -310.000000)"
        fill="#561747"
        fillRule="nonzero"
      >
        <g id="#2" transform="translate(440.000000, 300.000000)">
          <g id="Group-5" transform="translate(103.000000, 10.000000)">
            <g id="edit">
              <path
                d="M12.1579498,0.841457568 C11.0389094,-0.280485856 9.21908381,-0.280485856 8.10004341,0.841457568 L0.660086719,8.27952693 C0.601918778,8.33770177 0.565910052,8.412498 0.554830444,8.49283469 L0.00361995185,12.5761547 C-0.01299946,12.6925044 0.0285490696,12.8088541 0.108876227,12.8891908 C0.178123776,12.9584465 0.275070345,13 0.372016914,13 C0.388636326,13 0.405255738,13 0.421875149,12.9972298 L2.8815481,12.6648021 C3.08652085,12.6370998 3.23055575,12.4487241 3.20285673,12.243727 C3.17515771,12.03873 2.98680437,11.894678 2.78183163,11.9223803 L0.809661425,12.1883224 L1.1946778,9.34052528 L4.19171173,12.3379149 C4.26095928,12.4071706 4.35790585,12.4487241 4.45485242,12.4487241 C4.55179899,12.4487241 4.64874556,12.4099409 4.71799311,12.3379149 L12.1579498,4.89984551 C12.7008506,4.35688029 13,3.63662032 13,2.86926642 C13,2.10191252 12.7008506,1.38165255 12.1579498,0.841457568 Z M8.24407832,1.75563369 L9.4933041,3.00500772 L2.70427437,9.79484311 L1.45504858,8.54546907 L8.24407832,1.75563369 Z M4.45762232,11.5456289 L3.23609555,10.3239572 L10.0251253,3.53412178 L11.2466521,4.75579351 L4.45762232,11.5456289 Z M11.7673936,4.22113899 L8.7786694,1.23206009 C9.15814597,0.919024026 9.6317992,0.747269725 10.1303816,0.747269725 C10.6982115,0.747269725 11.2300326,0.968888179 11.6316684,1.3678014 C12.0333042,1.76671461 12.2521265,2.30136913 12.2521265,2.86926642 C12.2521265,3.37067817 12.0803925,3.84161739 11.7673936,4.22113899 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
