import styled from 'styled-components';
import { white } from '../../style/color';

export const HeaderStyle = styled.div`
  width: 100%;
  height: 140px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding-left: 180px;

  @media only screen and (max-width: 1024px) {
    padding-left: 140px;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 110px;
  }

  svg.header_logo {
    position: relative;
    transform: scale(0.8);
  }

  .dropdown_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 50px 0 0 50px;
    .dropdown_block {
      position: absolute;
    }

    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .util_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 50px 0 0 50px;
    display: flex;

    span.clock_wrapper {
      margin: 0 120px 0 auto;
    }

    span.clock_divide {
      animation: blink 1s infinite;
    }
  }
`;
