import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    width="51px"
    height="53px"
    viewBox="0 0 51 53"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-4.3%"
        y="-2.8%"
        width="108.0%"
        height="106.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.297339379 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      id="Planning-services-r2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="4-Staff"
        transform="translate(-668.000000, -136.000000)"
        // fill="#000000"
        fillRule="nonzero"
        stroke="#561747"
      >
        <g id="Group" transform="translate(119.000000, 93.000000)">
          <g
            id="working"
            filter="url(#filter-1)"
            transform="translate(0.000000, 27.000000)"
          >
            <g id="calendar-(1)" transform="translate(560.000000, 25.000000)">
              <path
                d="M23.7931034,18.7586207 C20.3705172,18.7586207 17.5862069,21.7285517 17.5862069,25.3793103 C17.5862069,29.030069 20.3705172,32 23.7931034,32 C27.2156897,32 30,29.030069 30,25.3793103 C30,21.7285517 27.2156897,18.7586207 23.7931034,18.7586207 Z M23.7931034,30.8965517 C20.9410345,30.8965517 18.6206897,28.4215172 18.6206897,25.3793103 C18.6206897,22.3371034 20.9410345,19.862069 23.7931034,19.862069 C26.6451724,19.862069 28.9655172,22.3371034 28.9655172,25.3793103 C28.9655172,28.4215172 26.6451724,30.8965517 23.7931034,30.8965517 Z"
                id="Shape"
              />
              <path
                d="M26.8965517,24.8275862 L24.3103448,24.8275862 L24.3103448,22.0689655 C24.3103448,21.7638621 24.0791379,21.5172414 23.7931034,21.5172414 C23.507069,21.5172414 23.2758621,21.7638621 23.2758621,22.0689655 L23.2758621,25.3793103 C23.2758621,25.6844138 23.507069,25.9310345 23.7931034,25.9310345 L26.8965517,25.9310345 C27.1825862,25.9310345 27.4137931,25.6844138 27.4137931,25.3793103 C27.4137931,25.0742069 27.1825862,24.8275862 26.8965517,24.8275862 Z"
                id="Path"
              />
              <path
                d="M13.9655172,11.5862069 L12.9310345,11.5862069 L9.31034483,11.5862069 L8.27586207,11.5862069 L3.62068966,11.5862069 L3.62068966,16.5517241 L3.62068966,17.6551724 L3.62068966,21.5172414 L3.62068966,22.6206897 L3.62068966,27.5862069 L8.27586207,27.5862069 L9.31034483,27.5862069 L13.9655172,27.5862069 L13.9655172,22.6206897 L13.9655172,21.5172414 L13.9655172,17.6551724 L17.5862069,17.6551724 L18.6206897,17.6551724 L23.2758621,17.6551724 L23.2758621,11.5862069 L18.6206897,11.5862069 L17.5862069,11.5862069 L13.9655172,11.5862069 Z M18.6206897,12.6896552 L22.2413793,12.6896552 L22.2413793,16.5517241 L18.6206897,16.5517241 L18.6206897,12.6896552 Z M9.31034483,12.6896552 L12.9310345,12.6896552 L12.9310345,16.5517241 L9.31034483,16.5517241 L9.31034483,12.6896552 Z M4.65517241,12.6896552 L8.27586207,12.6896552 L8.27586207,16.5517241 L4.65517241,16.5517241 L4.65517241,12.6896552 Z M4.65517241,17.6551724 L8.27586207,17.6551724 L8.27586207,21.5172414 L4.65517241,21.5172414 L4.65517241,17.6551724 Z M8.27586207,26.4827586 L4.65517241,26.4827586 L4.65517241,22.6206897 L8.27586207,22.6206897 L8.27586207,26.4827586 Z M12.9310345,26.4827586 L9.31034483,26.4827586 L9.31034483,22.6206897 L12.9310345,22.6206897 L12.9310345,26.4827586 Z M12.9310345,21.5172414 L9.31034483,21.5172414 L9.31034483,17.6551724 L12.9310345,17.6551724 L12.9310345,21.5172414 Z M17.5862069,16.5517241 L13.9655172,16.5517241 L13.9655172,12.6896552 L17.5862069,12.6896552 L17.5862069,16.5517241 Z"
                id="Shape"
              />
              <path
                d="M17.0689655,30.3448276 L1.03448276,30.3448276 L1.03448276,8.82758621 L25.862069,8.82758621 L25.862069,16 C25.862069,16.3051034 26.0932759,16.5517241 26.3793103,16.5517241 C26.6653448,16.5517241 26.8965517,16.3051034 26.8965517,16 L26.8965517,8.27586207 L26.8965517,2.75862069 C26.8965517,2.45351724 26.6653448,2.20689655 26.3793103,2.20689655 L23.7931034,2.20689655 L23.7931034,0.551724138 C23.7931034,0.24662069 23.5618966,0 23.2758621,0 L19.6551724,0 C19.3691379,0 19.137931,0.24662069 19.137931,0.551724138 L19.137931,2.20689655 L7.75862069,2.20689655 L7.75862069,0.551724138 C7.75862069,0.24662069 7.52741379,0 7.24137931,0 L3.62068966,0 C3.33465517,0 3.10344828,0.24662069 3.10344828,0.551724138 L3.10344828,2.20689655 L0.517241379,2.20689655 C0.231206897,2.20689655 0,2.45351724 0,2.75862069 L0,8.27586207 L0,30.8965517 C0,31.2016552 0.231206897,31.4482759 0.517241379,31.4482759 L17.0689655,31.4482759 C17.355,31.4482759 17.5862069,31.2016552 17.5862069,30.8965517 C17.5862069,30.5914483 17.355,30.3448276 17.0689655,30.3448276 Z M20.1724138,1.10344828 L22.7586207,1.10344828 L22.7586207,2.75862069 L22.7586207,4.4137931 L20.1724138,4.4137931 L20.1724138,2.75862069 L20.1724138,1.10344828 Z M4.13793103,1.10344828 L6.72413793,1.10344828 L6.72413793,2.75862069 L6.72413793,4.4137931 L4.13793103,4.4137931 L4.13793103,2.75862069 L4.13793103,1.10344828 Z M1.03448276,3.31034483 L3.10344828,3.31034483 L3.10344828,4.96551724 C3.10344828,5.27062069 3.33465517,5.51724138 3.62068966,5.51724138 L7.24137931,5.51724138 C7.52741379,5.51724138 7.75862069,5.27062069 7.75862069,4.96551724 L7.75862069,3.31034483 L19.137931,3.31034483 L19.137931,4.96551724 C19.137931,5.27062069 19.3691379,5.51724138 19.6551724,5.51724138 L23.2758621,5.51724138 C23.5618966,5.51724138 23.7931034,5.27062069 23.7931034,4.96551724 L23.7931034,3.31034483 L25.862069,3.31034483 L25.862069,7.72413793 L1.03448276,7.72413793 L1.03448276,3.31034483 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
