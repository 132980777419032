import React from 'react';
import PT from 'prop-types';
import { NavButtonStyle } from './style';

const NavButton = ({ children, onClick, active, className, style }) => (
  <NavButtonStyle
    className={className}
    style={style}
    active={active}
    onClick={onClick}
  >
    {children}
  </NavButtonStyle>
);

NavButton.propTypes = {
  onClick: PT.func
};

NavButton.defaultProps = {
  onClick: () => {}
};
export default NavButton;
