import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="44"
    viewBox="0 0 40 44"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={active ? secondary : primary} d="M-33-33H84V991H-33z" />
      <g fill={active ? white : secondary} fillRule="nonzero">
        <path d="M20 22.5c2.899 0 5.373-1.075 7.424-3.223 2.05-2.148 3.076-4.74 3.076-7.777s-1.026-5.629-3.076-7.778C25.373 1.574 22.898.5 20 .5c-2.898 0-5.373 1.074-7.424 3.222C10.526 5.871 9.5 8.463 9.5 11.5s1.025 5.629 3.076 7.777C14.627 21.425 17.102 22.5 20 22.5z" />
        <path d="M39.402 33.48a28.25 28.25 0 0 0-.388-3.014 23.402 23.402 0 0 0-.734-3 14.212 14.212 0 0 0-1.191-2.695 9.711 9.711 0 0 0-1.718-2.24c-.646-.617-1.436-1.11-2.367-1.478-.933-.368-1.963-.553-3.09-.553-.165 0-.553.198-1.163.594-.608.397-1.296.84-2.063 1.328-.766.487-1.764.93-2.991 1.326-1.228.396-2.46.594-3.698.594-1.237 0-2.47-.198-3.698-.594-1.227-.396-2.225-.839-2.991-1.326-.766-.489-1.454-.931-2.063-1.328-.61-.396-.997-.594-1.164-.594-1.126 0-2.156.185-3.088.553-.933.369-1.722.862-2.368 1.479A9.724 9.724 0 0 0 2.91 24.77a14.233 14.233 0 0 0-1.191 2.695c-.296.922-.54 1.921-.734 3a28.083 28.083 0 0 0-.388 3.013A41.337 41.337 0 0 0 .5 36.341c0 2.21.674 3.958 2.022 5.238C3.87 42.86 5.662 43.5 7.896 43.5h24.208c2.234 0 4.025-.64 5.374-1.92 1.348-1.281 2.022-3.028 2.022-5.24 0-.976-.032-1.93-.098-2.86z" />
      </g>
    </g>
  </svg>
);
