import React, { useState, useContext, useEffect } from 'react';
import { Layout, Table, Tag, Icon } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import Dashboard from '../../components/Dashboard';
import { ContainerBlock } from '../../style/main';
import { MainContext } from '../../context/main';
import {
  getPermissionColor,
  isAllow,
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage
} from '../../utils';
import { useToggle } from '../../hooks';
import Form from './form';
import { LanguageContext } from '../../context/language';

const { Content } = Layout;

const PermissionScreen = ({ allow, isBranchesFilter }) => {
  const { t } = useContext(LanguageContext);
  const { open, toggle } = useToggle();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionId, setPermissionId] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const { request, constants } = useContext(MainContext);

  const getData = async (params) => {
    setIsLoading(true);
    const res = await request('/permissions', {
      query: {
        ...params
      }
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);

    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const openForm = permission => (e) => {
    const id = _.get(permission, 'id');

    if (!id) return;

    setPermissionId(id);
    toggle();
  };

  const expandedRowRender = (endpointsData) => {
    const endpoints = _.get(endpointsData, 'endpoints', []);
    const columns = [
      {
        title: t(constants.ENDPOINT),
        dataIndex: 'path',
        key: 'path',
        render: text => <a href="javascript:;">{text}</a>
      },
      {
        title: t(constants.ENDPOINT_NAME),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t(constants.GET),
        key: 'get',
        dataIndex: 'allow',
        render: (allowStatus) => {
          const color = getPermissionColor(allowStatus.GET);

          return <Tag color={color}>{allowStatus.GET}</Tag>;
        }
      },
      {
        title: t(constants.POST),
        key: 'post',
        dataIndex: 'allow',
        render: (allowStatus) => {
          const color = getPermissionColor(allowStatus.POST);

          return <Tag color={color}>{allowStatus.POST}</Tag>;
        }
      },
      {
        title: t(constants.PUT),
        key: 'put',
        dataIndex: 'allow',
        render: (allowStatus) => {
          const color = getPermissionColor(allowStatus.PUT);

          return <Tag color={color}>{allowStatus.PUT}</Tag>;
        }
      },
      {
        title: t(constants.DELETE),
        key: 'delete',
        dataIndex: 'allow',
        render: (allowStatus) => {
          const color = getPermissionColor(allowStatus.DELETE);

          return <Tag color={color}>{allowStatus.DELETE}</Tag>;
        }
      }
    ];

    return (
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={endpoints}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: t(constants.PERMISSION),
      dataIndex: 'type',
      key: 'type',
      width: '30%'
      // ...getColumnSearchProps(
      //   `${t(constants.SEARCH)}... ${t(constants.PERMISSION)}`
      // )
    },
    {
      title: t(constants.CREATED),
      dataIndex: 'created',
      key: 'created',
      width: '30%',
      render: date => moment(date).format('D MMMM YYYY')
    },
    {
      title: t(constants.UPDATED),
      dataIndex: 'updated',
      key: 'updated',
      width: '30%',
      render: date => moment(date).format('D MMMM YYYY')
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <span>
          <Icon
            onClick={!isAllow(allow.PUT) ? () => {} : openForm(o)}
            type="edit"
            className={!isAllow(allow.PUT) ? 'disabled' : 'custom'}
          />
        </span>
      )
    }
  ];

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getData({
      // ...getFilter(filters),
      // ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  return (
    <>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '50px 50px 0', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                loading={isLoading}
                expandedRowRender={expandedRowRender}
                dataSource={data}
                onChange={handleTableChange}
                pagination={pagination}
                scroll={{ y: '50vh' }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form
        permissionId={permissionId}
        allow={allow}
        open={open}
        toggle={toggle}
        onClose={getData}
      />
    </>
  );
};

export default PermissionScreen;
