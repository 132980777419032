import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Icon,
  Button,
  Modal,
  Spin,
  Upload,
  InputNumber,
  Select,
  DatePicker,
  Table,
  Switch,
  Row,
  Col,
  TimePicker,
} from 'antd';
import moment from 'moment';
import { MainContext } from '../../context/main';
import {
  dummyRequest,
  isAllow,
  isImage,
  getBase64,
  isValidRatio,
  isValidSize,
  beforeUpload,
  normFile,
  normDate,
  normTime,
  minutesDuration,
  getFilter,
  getSort,
  getPage,
  getTime,
} from '../../utils';
import { LanguageContext } from '../../context/language';
import PopOverTag from '../../components/PopOverTag';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';

const { TextArea } = Input;
const { Option } = Select;

const format = 'HH:mm';
const dateFormat = 'DD/MM/YYYY';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12, offset: 1 },
  },
};

const notiItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const DynamicFieldSet = ({
  form,
  open,
  toggle,
  promotionId,
  onSubmit,
  allow,
}) => {
  const { t } = useContext(LanguageContext);

  const [promotion, setPromotion] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [isGetBranchListLoading, setIsGetBranchListLoading] = useState(false);
  const [availableBranches, setAvailableBranches] = useState([]);
  const [removeAvailableBranches, setRemoveAvailableBranches] = useState([]);
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const { request, constants } = useContext(MainContext);
  const { getFieldDecorator } = form;

  const getBranchList = async (params) => {
    setIsGetBranchListLoading(true);
    const res = await request('/static/branches', {});
    const branchesRes = _.get(res, ['reference_data']);
    // console.log({ branchesRes });

    setBranchList(branchesRes);

    if (!promotionId) {
      setAvailableBranches(_.map(branchesRes, o => _.get(o, ['id'], null)));
    }

    setIsGetBranchListLoading(false);
  };

  const popOverChange = (id) => {
    if (_.includes(availableBranches, id)) {
      setAvailableBranches(_.filter(availableBranches, i => i !== id));

      const oldBranch = _.find(
        _.get(promotion, ['branches']),
        o => _.get(o, ['branch', '_id']) === id
      );

      if (oldBranch) {
        setRemoveAvailableBranches([
          ...new Set([...removeAvailableBranches, id]),
        ]);
      }

      return;
    }
    setAvailableBranches([...new Set([...availableBranches, id])]);
  };

  const columns = [
    {
      title: t(constants.BRANCH),
      dataIndex: 'title',
      key: 'title',
      render: title => t(title),
    },
    {
      title: '',
      key: 'unique',
      render: o => (
        <PopOverTag
          activeTextItem="Add"
          inactiveTextItem="Remove"
          activeTextStatus="Available"
          inactiveTextStatus="None"
          allow={allow}
          status={_.includes(availableBranches, o.id)}
          onChange={popOverChange}
          id={o.id}
        />
      ),
    },
  ];

  const getData = async () => {
    if (!promotionId) {
      setPreviewImage(null);
      setPromotion(null);
      // setAvailableBranches(_.map(branchList, o => _.get(o, ['id'], null)));
      return;
    }
    setIsGetDataLoading(true);
    const res = await request('/promotions', {
      query: {
        id: promotionId,
        includes: ['is_notified', 'branches'],
      },
    });

    const referenceData = _.get(res, ['reference_data', 0], []);

    const branches = _.get(referenceData, ['branches'], []);
    const picture = _.get(referenceData, ['picture']);

    setIsGetDataLoading(false);
    setPreviewImage(picture || null);

    setAvailableBranches(
      _.map(branches, o => _.get(o, ['branch', '_id'], null))
    );
    setPromotion(referenceData);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      setPreviewImage(null);
      getData();
      getBranchList();
    }
  }, [open]);

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setIsSubmitLoading(true);

        const body = new FormData();

        if (!promotion) {
          body.append('title_en', values.title_en);
          body.append('title_th', values.title_th);
          body.append('description_en', values.description_en);
          body.append('description_th', values.description_th);

          body.append('start_date', _.get(values, ['start_date', '_d']));

          body.append('end_date', _.get(values, ['end_date', '_d']));

          body.append(
            'picture',
            _.get(values, ['picture', 0, 'originFileObj'])
          );

          if (values.rule_en) {
            body.append('rule_en', values.rule_en);
          }

          if (values.rule_th) {
            body.append('rule_th', values.rule_th);
          }

          if (!_.isNil(values.highlight)) {
            body.append('highlight', values.highlight);
          }

          if (values.notify_date) {
            body.append(
              'notify_date',
              moment(values.notify_date).format('YYYY-MM-DD')
            );

            if (values.notify_time) {
              body.append(
                'notify_time',
                minutesDuration(moment(values.notify_time).format('H:mm'))
              );
            }
          }

          // if (values.notify_date && values.notify_time) {
          //   body.append(
          //     'notify_at',
          //     moment(values.notify_date)
          //       .add(
          //         minutesDuration(moment(values.notify_time).format('H:mm')),
          //         'minute'
          //       )
          //       .format()
          //   );
          // }

          // if (values.benefit_en) {
          //   body.append('benefit_en', values.benefit_en);
          // }

          // if (values.benefit_th) {
          //   body.append('benefit_th', values.benefit_th);
          // }

          _.times(_.size(availableBranches), (i) => {
            if (availableBranches[i]) {
              body.append('new_branches[]', availableBranches[i]);
            }
          });
        } else {
          body.append('id', promotion.id);

          if (values.title_en !== _.get(promotion, ['title', 'en'])) {
            body.append('title_en', values.title_en);
          }

          if (values.title_th !== _.get(promotion, ['title', 'th'])) {
            body.append('title_th', values.title_th);
          }

          if (
            values.description_en !== _.get(promotion, ['description', 'en'])
          ) {
            body.append('description_en', values.description_en);
          }

          if (
            values.description_th !== _.get(promotion, ['description', 'th'])
          ) {
            body.append('description_th', values.description_th);
          }

          if (values.rule_en !== _.get(promotion, ['rule', 'en'])) {
            body.append('rule_en', values.rule_en);
          }

          if (values.rule_th !== _.get(promotion, ['rule', 'th'])) {
            body.append('rule_th', values.rule_th);
          }

          if (_.isBoolean(values.highlight)) {
            body.append('highlight', values.highlight);
          }

          const notify_date_change = _.get(values, ['notify_date', '_d'])
            && moment.utc(_.get(values, ['notify_date', '_d'])).toISOString()
              !== _.get(promotion, ['notify_date']);

          if (notify_date_change) {
            body.append(
              'notify_date',
              moment(values.notify_date).format('YYYY-MM-DD')
            );
          }

          if (
            minutesDuration(moment(values.notify_time).format('H:mm'))
            !== _.get(promotion, ['notify_time'])
          ) {
            body.append(
              'notify_time',
              minutesDuration(moment(values.notify_time).format('H:mm'))
            );
          }

          // if (values.benefit_en !== _.get(promotion, ['benefit', 'en'])) {
          //   body.append('benefit_en', values.benefit_en);
          // }

          // if (values.benefit_th !== _.get(promotion, ['benefit', 'th'])) {
          //   body.append('benefit_th', values.benefit_th);
          // }

          if (
            _.get(values, ['start_date', '_d'])
            && moment.utc(_.get(values, ['start_date', '_d'])).toISOString()
              !== _.get(promotion, ['start_date'])
          ) {
            body.append('start_date', _.get(values, ['start_date', '_d']));
          }

          if (
            _.get(values, ['end_date', '_d'])
            && moment.utc(_.get(values, ['end_date', '_d'])).toISOString()
              !== _.get(promotion, ['end_date'])
          ) {
            body.append('end_date', _.get(values, ['end_date', '_d']));
          }

          if (previewImage !== promotion.picture) {
            body.append(
              'picture',
              _.get(values, ['picture', 0, 'originFileObj'])
            );
          }

          _.times(_.size(availableBranches), (i) => {
            if (
              availableBranches[i]
              && !_.find(
                _.get(promotion, ['branches']),
                o => _.get(o, ['branch', '_id']) === availableBranches[i]
              )
            ) {
              body.append('new_branches[]', availableBranches[i]);
            }
          });

          _.times(_.size(removeAvailableBranches), (i) => {
            body.append('remove_branches[]', removeAvailableBranches[i]);
          });
        }

        const res = await request('/promotions', {
          method: !promotion ? 'post' : 'put',
          body,
        });

        setIsSubmitLoading(false);

        if (res) {
          toggle();
          onSubmit();
        }
      }
    });
  };

  const validateImage = async (rule, value, callback) => {
    try {
      const fileValue = _.get(value, [0, 'originFileObj']);
      if (!fileValue) {
        if (previewImage === _.get(promotion, ['picture'])) {
          return callback();
        }
        return callback(t(constants.ERROR_IMAGE));
      }

      setPreviewImage(null);

      const fileType = _.get(fileValue, ['type']);

      const validFileType = isImage(fileType);

      if (!validFileType) return callback(t(constants.ERROR_IMAGE_TYPE));

      const fileSize = _.get(fileValue, ['size']);

      const validFileSize = isValidSize(fileSize);

      if (!validFileSize) return callback(t(constants.ERROR_IMAGE_LARGE));

      const valid = await isValidRatio(fileValue);

      if (!valid) {
        return callback(
          `${t(
            constants.ERROR_IMAGE_RATIO
          )} = 16 : 9 (1920pixel X 1080pixel) ${t(constants.REQUIRED)}`
        );
      }

      getBase64(fileValue, imgUrl => setPreviewImage(imgUrl));
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const validatePicker = (
    key,
    compareKey,
    mode = 'has',
    errMsg = constants.ERROR_NOTIFY_PROMOTION
  ) => (rule, value, callback) => {
    const a = form.getFieldValue(key);
    const b = form.getFieldValue(compareKey);

    if (mode === 'has') {
      if (a && !b) {
        callback(t(errMsg));
      }
    }

    if (mode === 'mustBefore') {
      if (moment(b).isBefore(a, 'day')) {
        callback(t(errMsg));
      }
    }

    callback();
  };

  const disabledNotifyField = () => {
    if (!isAllow(allow.PUT)) return true;

    if (promotion) {
      if (_.get(promotion, ['is_notified'])) return true;
    }

    return false;
  };

  return (
    <Modal
      width={650}
      visible={open}
      title={
        promotion
          ? t(isAllow(allow.PUT) ? constants.EDIT_PROMOTION : constants.DETAIL)
          : t(constants.ADD_PROMOTION)
      }
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        isAllow(allow.PUT) && (
          <Button
            key="submit"
            type="primary"
            loading={isSubmitLoading}
            onClick={handleSubmit}
          >
            {t(constants.SUBMIT)}
          </Button>
        ),
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Form>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_TITLE_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('title_en', {
              initialValue: _.get(promotion, ['title', 'en']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_PROMOTION_TITLE_EN),
                },
              ],
            })(
              <Input
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_TITLE_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_TITLE_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('title_th', {
              initialValue: _.get(promotion, ['title', 'th']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_PROMOTION_TITLE_TH),
                },
              ],
            })(
              <Input
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_TITLE_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_DESCRIPTION_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('description_en', {
              initialValue: _.get(promotion, ['description', 'en']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_PROMOTION_DESCRIPTION_EN),
                },
              ],
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_DESCRIPTION_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_DESCRIPTION_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('description_th', {
              initialValue: _.get(promotion, ['description', 'th']),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  message: t(constants.ERROR_PROMOTION_DESCRIPTION_TH),
                },
              ],
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_DESCRIPTION_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_RULE_EN)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('rule_en', {
              initialValue: _.get(promotion, ['rule', 'en']),
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_RULE_EN)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_RULE_TH)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('rule_th', {
              initialValue: _.get(promotion, ['rule', 'th']),
            })(
              <TextArea
                rows={4}
                disabled={!isAllow(allow.PUT)}
                placeholder={t(constants.INPUT_PROMOTION_RULE_TH)}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.HIGHLIGHT_PROMOTION)}
              </TextNormal>
)}
          >
            {getFieldDecorator('highlight', {
              valuePropName: 'checked',
              initialValue: _.get(promotion, ['highlight'], false),
            })(<Switch />)}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_START_DATE)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('start_date', {
              initialValue: _.get(promotion, ['start_date'])
                ? moment(_.get(promotion, ['start_date']))
                : null,
              getValueFromEvent: normDate(dateFormat),
              rules: [
                {
                  required: !promotionId,
                  message: t(constants.ERROR_PROMOTION_START_DATE),
                },
                {
                  validator: validatePicker(
                    'start_date',
                    'end_date',
                    'mustBefore',
                    constants.ERROR_PROMOTION_DATE
                  ),
                },
              ],
            })(
              <DatePicker
                disabled={!isAllow(allow.PUT)}
                style={{ width: '100%' }}
                format={dateFormat}
                suffixIcon={<div />}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_END_DATE)}
              </TextNormal>
)}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('end_date', {
              initialValue: _.get(promotion, ['end_date'])
                ? moment(_.get(promotion, ['end_date']))
                : null,
              getValueFromEvent: normDate(dateFormat),
              rules: [
                {
                  required: !promotionId,
                  message: t(constants.ERROR_PROMOTION_END_DATE),
                },
                {
                  validator: validatePicker(
                    'start_date',
                    'end_date',
                    'mustBefore',
                    constants.ERROR_PROMOTION_DATE
                  ),
                },
              ],
            })(
              <DatePicker
                disabled={!isAllow(allow.PUT)}
                style={{ width: '100%' }}
                format={dateFormat}
                suffixIcon={<div />}
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayoutHalf}
            label={(
              <TextNormal color={purple}>
                {t(constants.NOTIFY_PROMOTION)}
              </TextNormal>
)}
            required={false}
            // hasFeedback
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <TextNormal color={purple}>{t(constants.DATE)}</TextNormal>
                  }
                  {...notiItemLayout}
                  required={false}
                  hasFeedback
                >
                  {getFieldDecorator('notify_date', {
                    initialValue: _.get(promotion, ['notify_date'])
                      ? moment(_.get(promotion, ['notify_date']))
                      : null,
                    getValueFromEvent: normDate(dateFormat),
                    validateTrigger: [],
                    rules: [
                      {
                        validator: validatePicker('notify_date', 'notify_time'),
                      },
                    ],
                  })(
                    <DatePicker
                      disabled={disabledNotifyField()}
                      style={{ width: '100%' }}
                      format={dateFormat}
                      suffixIcon={<div />}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <TextNormal color={purple}>{t(constants.TIME)}</TextNormal>
                  }
                  {...notiItemLayout}
                  required={false}
                  hasFeedback
                >
                  {getFieldDecorator('notify_time', {
                    initialValue: _.get(promotion, ['notify_time'])
                      ? moment(
                        getTime(_.get(promotion, ['notify_time'])),
                        format
                      )
                      : null,
                    getValueFromEvent: normTime(format),
                    validateTrigger: [],
                    rules: [
                      {
                        validator: validatePicker('notify_time', 'notify_date'),
                      },
                    ],
                  })(
                    <TimePicker
                      style={{
                        width: '100%',
                      }}
                      disabled={disabledNotifyField()}
                      minuteStep={60}
                      format={format}
                      suffixIcon={<div />}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label={(
              <TextNormal color={purple}>
                {t(constants.AVAILABLE_BRANCH)}
              </TextNormal>
)}
            required={false}
            // hasFeedback
          >
            <Table
              className="components-table-demo-nested"
              rowKey="id"
              columns={columns}
              loading={isGetBranchListLoading}
              pagination={{ pageSize: 5 }}
              dataSource={branchList}
            />
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label={(
              <TextNormal color={purple}>
                {t(constants.PROMOTION_PICTURE)}
              </TextNormal>
)}
            help={`${t(
              constants.ERROR_IMAGE_RATIO
            )} = 16 : 9 (1920pixel X 1080pixel) ${t(constants.REQUIRED)}`}
            required={false}
            hasFeedback
          >
            {getFieldDecorator('picture', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  validator: validateImage,
                },
              ],
            })(
              <Upload
                disabled={!isAllow(allow.PUT)}
                name="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                <Button>
                  <Icon type="upload" />
                  {t(constants.INPUT_PROMOTION_PICTURE)}
                </Button>
                <div
                  style={{
                    width: '100%',
                    margin: '20px 0 0',
                    padding: '10px',
                    border: 'solid 1px #0005',
                    borderRadius: '10px',
                  }}
                >
                  <img
                    src={previewImage || '/content/img/placeholder.jpg'}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
