import React, { useState, useRef, useContext, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import _ from 'lodash';
import CardOfPlaning from '../../components/CardOfPlaning';
import { TableBlock, ShiftBlock } from './style';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple } from '../../style/color';
import PlaningDayModalForm from './PlaningDayModalForm';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import { useWindowWidth } from '../../hooks';
import { getTime } from '../../utils';

const PlaningOfDay = ({
  allow,
  currentMonth,
  planingOfMonth,
  setPlaningOfMonth,
  // getData,
  isLoading,
  allowPermission,
}) => {
  const formRef = useRef(null);

  const { request, constants, currentBranch, branches } = useContext(
    MainContext
  );
  const { t } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const dayOfWeek = _.toLower(moment().locale('en').format('dddd'));
  const [breakPeriod, setBreakPeriod] = useState([]);
  const [modalVisible, setModalVisible] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [allBranches, setAllBranches] = useState([]);

  const getData = async () => {
    const res = await request('/branches/all');

    setAllBranches(_.get(res, ['reference_data'], []));
  };

  const dayRange = moment(currentMonth, 'YYYY-MM').daysInMonth();

  useEffect(() => {
    const matchBranch = _.find(branches, o => o.id === currentBranch);
    if (matchBranch) {
      const open = _.get(matchBranch, ['open', dayOfWeek, 'time']);
      const close = _.get(matchBranch, ['close', dayOfWeek, 'time']);
      const duration = (close - open) + 60;

      setBreakPeriod(
        _.times((duration) / 60, t => ({
          title: getTime(open + 60 * t),
          value: open + 60 * t,
        }))
      );
    }
  }, [currentBranch]);

  const resetAllFields = () => {
    if (formRef.current) {
      const { resetFields } = formRef.current;
      resetFields();
    }
  };

  const handleClickCard = (data) => {
    const id = _.get(data, '_id');
    resetAllFields();
    if (id && formRef.current) {
      const { setFieldsValue } = formRef.current;

      setFieldsValue({
        id,
        work_shift: _.get(data, ['work_shift'], ''),
        branch_change: _.get(data, ['branch_change', '_id'], ''),
        day_off: _.get(data, ['day_off', 'type_of_day_off'], ''),
        holiday_detail: _.get(data, ['day_off', 'holiday_detail'], ''),
        is_reception: _.get(data, ['is_reception'], false),
        // break_period: _.get(data, ['break_period']),
        break_period_start: _.get(data, ['break_period_start']),
        break_period_end: _.get(data, ['break_period_end']),
      });
    }

    setModalVisible({
      ...data,
      title: _.get(data, ['title']),
      currentMonth,
    });
  };

  const handleModalClose = () => {
    resetAllFields();
    setModalVisible('');
  };

  const handleModalCreate = () => {
    if (formRef.current) {
      const { validateFields } = formRef.current;

      validateFields(async (err, values) => {
        if (!err) {
          const id = _.trim(_.get(values, 'id'));

          let body = _.omitBy(values, o => !o);

          if (_.size(body) <= 0) {
            return false;
          }

          if (!_.isNil(values.is_reception)) body.is_reception = values.is_reception;

          if (body.day_off) {
            const dayOff = body.day_off;
            _.set(body, ['day_off', 'type_of_day_off'], dayOff);
            if (dayOff === 'holiday') {
              _.set(body, ['day_off', 'holiday_detail'], body.holiday_detail);
            }
          } else {
            body = _.omit(body, ['day_off']);
          }
          body = _.omit(body, ['holiday_detail']);

          if (id) {
            const oldDayOff = _.get(modalVisible, [
              'day_off',
              'type_of_day_off',
            ]);
            if (oldDayOff && !body.day_off) {
              _.set(body, 'cancel_day_off', 1);
            }

            const oldBranchChange = _.get(modalVisible, [
              'branch_change',
              'id',
            ]);
            if (oldBranchChange && !body.branch_change) {
              _.set(body, 'cancel_branch_change', 1);
            }
          } else if (!id) {
            _.set(body, 'month_plan_id', modalVisible.planingMonthId);
            _.set(body, 'staff_id', modalVisible.staffId);
            _.set(body, 'day', modalVisible.date);
          }

          setLoading(true);

          const res = await request('/day-plan', {
            method: id ? 'put' : 'post',
            // method: 'post',
            body: id ? body : _.omit(body, ['id']),
          });
          setLoading(false);

          if (_.get(res, ['success'])) {
            let branchChange;
            if (body.branch_change) {
              const matchBranchChange = _.find(
                allBranches,
                o => _.get(o, ['id']) === body.branch_change
              );

              branchChange = {
                id: _.get(matchBranchChange, ['id']),
                _id: _.get(matchBranchChange, ['id']),
                name: _.get(matchBranchChange, ['title']),
              };
            }

            setPlaningOfMonth({
              ...planingOfMonth,
              staffs: _.map(_.get(planingOfMonth, ['staffs']), (o) => {
                if (id) {
                  _.set(
                    o,
                    ['staff', 'day_plan'],
                    _.map(_.get(o, ['staff', 'day_plan'], []), (d) => {
                      if (d._id === id) {
                        // console.log({ res, d, id, body });
                        const newData = _.get(res, ['reference_data', 0], {});
                        newData._id = id;
                        if (branchChange) newData.branch_change = branchChange;
                        return newData;
                      }
                      return d;
                    })
                  );

                  return o;
                }

                const newData = _.get(res, ['reference_data', 0], {});

                if (_.get(newData, ['staff']) === _.get(o, ['staff', '_id'])) {
                  // console.log({ n: _.get(res, ['reference_data', 0], {}), o });
                  _.set(
                    o,
                    ['staff', 'day_plan'],
                    [..._.get(o, ['staff', 'day_plan'], []), newData]
                  );

                  return o;
                }

                return o;
              }),
            });
            resetAllFields();
            setModalVisible('');
          }
        }
      });
    }
  };

  const handleRemove = () => {
    if (formRef.current) {
      const { validateFields } = formRef.current;
      validateFields(async (err, values) => {
        if (!err) {
          const id = _.trim(_.get(values, 'id'));
          if (id) {
            setLoading(true);
            const res = await request('/day-plan', {
              method: 'delete',
              query: { id },
            });
            setLoading(false);

            // getData();
            if (_.get(res, ['success'])) {
              // console.log({ res, planingOfMonth });
              setPlaningOfMonth({
                ...planingOfMonth,
                staffs: _.map(_.get(planingOfMonth, ['staffs']), o => ({
                  ...o,
                  staff: {
                    ..._.get(o, ['staff'], {}),
                    day_plan: _.filter(
                      _.get(o, ['staff', 'day_plan'], []),
                      d => d._id !== id
                    ),
                  },
                })),
              });
              resetAllFields();
              setModalVisible('');
            }
          }
        }
      });
    }
    // resetAllFields();
    // setModalVisible('');
  };

  const currentMonthString = moment(currentMonth).format('YYYY-MM');

  const dayColumns = _.map(_.range(dayRange), (numberOfDay) => {
    const date = moment(
      `${currentMonthString}-${numberOfDay + 1}`,
      'YYYY-MM-DD'
    );

    const dateString = date.format('YYYY-MM-DD');
    return {
      title: `${date.format('ddd, Do')}`,
      width: 150,
      key: `day-${numberOfDay}`,
      render: (obj) => {
        const dayPlan = _.find(
          _.get(obj, ['staff', 'day_plan'], []),
          (plan) => {
            const planAt = moment(plan.day).format('YYYY-MM-DD');
            return moment(planAt).isSame(dateString);
          }
        );

        return (
          <CardOfPlaning
            onClick={handleClickCard.bind(this, {
              ...dayPlan,
              staffId: _.get(obj, ['staff', 'id']),
              title: _.get(obj, ['staff', 'nickname']),
              planingMonthId: obj.month_plan,
              date,
            })}
            date={date}
            dataSource={
              dayPlan && {
                ...dayPlan,
              }
            }
          />
        );
      },
    };
  });

  const columns = [
    {
      title: 'staff',
      width: 250,
      key: 'staff',
      fixed: 'left',
      render: obj => (
        // console.log({ obj });
        <div
          style={{
            width: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // paddingLeft: '10px'
          }}
        >
          <TextBold color={purple} fontSize="18px">
            {_.get(
              obj,
              ['staff', 'nickname'],
              t({ en: 'ไม่ระบุ', th: 'ไม่ระบุ' })
            )}
          </TextBold>
          <span>
            <TextNormal fontSize="14px">
              {_.get(
                obj,
                ['staff', 'full_name'],
                t({ en: 'ไม่ระบุ', th: 'ไม่ระบุ' })
              )}
            </TextNormal>
          </span>
        </div>
      ),
    },
    ...dayColumns,
  ];
  // const data = _.filter(
  //   _.get(planingOfMonth, 'staffs', []),
  //   o => !!_.get(o, ['staff'])
  // );

  useEffect(() => {
    setDataSource(
      _.filter(
        _.get(planingOfMonth, 'staffs', []),
        o => !!_.get(o, ['staff'])
      )
    );
  }, [planingOfMonth]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TableBlock>
        <Table
          loading={isLoading}
          bordered
          // dataSource={_.flatten(_.map(_.range(30), () => data))}
          // dataSource={data}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: dayRange * 150 + 400 + 220, y: '40vh' }}
        />
      </TableBlock>
      <PlaningDayModalForm
        allBranches={allBranches}
        loading={loading}
        breakPeriod={breakPeriod}
        allowPermission={allowPermission}
        allow={allow}
        ref={formRef}
        visible={modalVisible}
        onCancel={handleModalClose}
        onCreate={handleModalCreate}
        onRemove={handleRemove}
      />
    </>
  );
};

export default PlaningOfDay;
