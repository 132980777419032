import React, { useState, useContext, useEffect } from 'react';
import { Layout, Popconfirm, Table, Divider, Icon } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock
} from '../../style/main';
import { TextBold } from '../../style/fontStyle';
import { purple } from '../../style/color';
import AddButton from '../../components/Button/AddButton';
import Plus from '../../assets/svg/Plus';
import Dashboard from '../../components/Dashboard';
import { useDropdown } from '../../components/Dropdown';
import Filter, { useFilter } from '../../components/Filter';
import { MainContext } from '../../context/main';
import {
  getFilter,
  getSort,
  getPage,
  isAllow,
  getColumnSearchProps
} from '../../utils';
import { useToggle, useDebounce } from '../../hooks';
import Form from './form';
import { LanguageContext } from '../../context/language';

const { Content } = Layout;

const UserScreen = ({ allow, isBranchesFilter, allowPermission }) => {
  const { t } = useContext(LanguageContext);
  const { open, toggle } = useToggle();
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { request, constants, currentBranch, isDesktop } = useContext(
    MainContext
  );

  const { open: desc, toggle: changeDesc } = useToggle();
  const { value: currentSortKey, handleSelect: handleSortKey } = useDropdown();
  const {
    value: currentFilterKey,
    handleSelect: handleFilterKey
  } = useDropdown();

  const { value: currentFilterValue, handler } = useFilter();

  const getData = async (params) => {
    if (!currentBranch && currentBranch === '') {
      return;
    }
    setIsLoading(true);
    const res = await request('/users', {
      query: {
        branch: currentBranch,
        ...params
      }
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);

    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [currentBranch]);

  const openForm = user => (e) => {
    const id = _.get(user, 'id');
    setUserId(id);
    toggle();
  };

  const handleDelete = id => async (e) => {
    setIsLoading(true);
    const res = await request('/users', {
      method: 'delete',
      query: {
        id
      }
    });
    setIsLoading(false);
    await getData();
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    getData({
      ...getFilter(filters),
      ...getSort(sorter),
      ...getPage(paginate)
    });
  };

  const columns = [
    {
      title: t(constants.USERNAME),
      dataIndex: 'username',
      key: 'username',
      width: '22.5%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.USERNAME)}`
        )),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.NAME),
      dataIndex: 'full_name',
      key: 'full_name',
      width: '22.5%',
      ...(!isDesktop
        && getColumnSearchProps(`${t(constants.SEARCH_BY)} ${t(constants.NAME)}`)),
      ...(!isDesktop && { sorter: () => {} })
    },
    {
      title: t(constants.USER_PERMISSION),
      dataIndex: 'permission',
      key: 'permission',
      width: '22.5%',
      render: permission => _.get(permission, ['type'])
    },
    {
      title: t(constants.BRANCH),
      dataIndex: 'branch',
      key: 'branch',
      width: '22.5%',
      render: branch => _.get(branch, ['name'])
    },
    {
      title: '',
      width: '10%',
      key: 'unique',
      render: o => (
        <span>
          <Icon
            onClick={
              !isAllow(allow.PUT, allowPermission.PUT) ? () => {} : openForm(o)
            }
            type="edit"
            className={
              !isAllow(allow.PUT, allowPermission.PUT) ? 'disabled' : 'custom'
            }
          />
          <Divider type="vertical" />
          <Popconfirm
            disabled={!isAllow(allow.DELETE, allowPermission.DELETE)}
            title={t(constants.SURE_DELETE)}
            onConfirm={handleDelete(o.id)}
          >
            <Icon
              className={
                !isAllow(allow.DELETE, allowPermission.DELETE)
                  ? 'disabled'
                  : 'custom'
              }
              type="delete"
            />
          </Popconfirm>
        </span>
      )
    }
  ];

  const debouncedFilterValue = useDebounce(currentFilterValue, 500);

  useEffect(() => {
    if (currentSortKey && currentFilterKey && !_.isNil(debouncedFilterValue)) {
      getData({
        ...(debouncedFilterValue && {
          [currentFilterKey]: debouncedFilterValue
        }),
        sort: { id: currentSortKey, desc }
      });
    }
  }, [debouncedFilterValue, currentFilterKey]);

  useEffect(() => {
    if (currentSortKey) {
      getData({
        sort: { id: currentSortKey, desc }
      });
    }
  }, [desc, currentSortKey]);

  const sortItems = [
    { id: 'username', title: t(constants.USERNAME) },
    { id: 'full_name', title: t(constants.NAME) }
  ];

  const filterItems = [
    { id: 'username', title: t(constants.USERNAME) },
    { id: 'full_name', title: t(constants.NAME) }
  ];

  return (
    <>
      <HeaderContentBlock>
        <AddButtonBlock>
          {isAllow(allow.POST, allowPermission.POST) && (
            <AddButton onClick={openForm(null)}>
              <Plus style={{ marginRight: '15px' }} />
              <TextBold color={purple}>{`${t(constants.ADD_USER)}`}</TextBold>
            </AddButton>
          )}
        </AddButtonBlock>
      </HeaderContentBlock>

      <Filter
        sortItems={sortItems}
        currentSortKey={currentSortKey}
        handleSortKey={handleSortKey}
        filterItems={filterItems}
        currentFilterKey={currentFilterKey}
        handleFilterKey={handleFilterKey}
        desc={desc}
        changeDesc={changeDesc}
        filterValue={currentFilterValue}
        onChange={handler}
      />
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                loading={isLoading}
                pagination={pagination}
                scroll={{ y: '35vh' }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form userId={userId} open={open} toggle={toggle} onSubmit={getData} />
    </>
  );
};

export default UserScreen;
