import moment from 'moment';

// numberOfweek (0 - 7)
// moment format
export const weekdaysInMonth = (numberDayOfWeek = 0, format, inMonth) => {
  const day = inMonth || moment();
  day.startOf('month').day(numberDayOfWeek);
  if (day.date() > 7) day.add(7, 'd');
  const month = day.month();
  const dateInMonth = [];
  while (month === day.month()) {
    dateInMonth.push(format ? day.format(format) : day);
    day.add(7, 'd');
  }
  return dateInMonth;
};
