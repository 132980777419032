import _ from 'lodash';

export const getOptionalCourseIdWithSpecialCourseSelected = (
  specialCourses,
  value
) => _.chain(value)
  .map(specialCourseId => _.chain(specialCourses)
    .find(o => _.get(o, ['course', 'id']) === specialCourseId)
    .get(['course', 'bundles'], [])
    .map((o) => {
      const bundleCourseId = _.get(o, ['bundle_course', 'id']);
      return bundleCourseId;
    })
    .value())
  .flatten()
  .value();
