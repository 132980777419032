import styled from 'styled-components';
import posed from 'react-pose';
import { white } from '../../style/color';

const ButtonAnimate = posed.div({
  pressable: true,
  init: { scale: 1, opacity: 1 },
  press: {
    scale: 0.8,
    opacity: 0.5
  }
});

export const NavButtonStyle = styled(ButtonAnimate)`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => (props.active ? '#9E4188' : '')};
  color: ${props => (props.active ? white : '#9E4188')};
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    height: 100px;
    font-size: 12px;
  }

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1024px) {
      width: 40px;
      height: 40px;
    }
  }
`;
