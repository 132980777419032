import styled from 'styled-components';
import { primary } from '../../style/color';

export const MaterialInputStyled = styled.div`
  position: relative;
  margin-bottom: 45px;
  width: 100%;

  input {
    color: #561747;
    font-size: 18px;

    display: block;
    width: 100%;
    height: 45px;
    /* text-indent: 80px; */
    border: none;
    border-bottom: 1px solid #561747;
    background: transparent;
  }

  input:focus {
    outline: none;
    border-bottom: 1px solid ${primary};
  }

  label {
    color: #561747;
    font-size: ${({ valid }) => (valid ? 18 : 22)}px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: ${({ valid }) => (valid ? -20 : 12)}px;
    transition: 0.2s ease all;
  }

  input:focus ~ label {
    top: -20px;
    font-size: 18px;
    color: ${primary};
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    /* background: #ffffff; */
    background: ${primary};
    transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  .highlight {
    position: absolute;
    height: 60%;
    width: 100%;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.1;
  }

  .search-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 10px;
    fill: #561747;
  }

  input:focus ~ .search-icon {
    fill: ${primary};
  }
`;
