import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { CardBlock, PlusBlock, CardActivityBlock } from './style';
import Plus from '../../assets/svg/Plus';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple } from '../../style/color';
import Pencil from '../../assets/svg/Pencil';
import { LanguageContext } from '../../context/language';
import { MainContext } from '../../context/main';

const CardOfPlaning = ({ dataSource, onClick, date }) => {
  // dataSource.planing_day && console.log({ dataSource });
  // dataSource && console.log(dataSource.dateOfDayOff);

  const { t } = useContext(LanguageContext);
  const { constants } = useContext(MainContext);

  const typeOfDayOff = _.get(dataSource, ['day_off', 'type_of_day_off']);

  let actionType;
  let textFirst;
  let textSecond;

  const currentDate = moment(date).add(1, 'day');

  const disabled = moment(currentDate).isBefore();

  if (typeOfDayOff && typeOfDayOff !== '') {
    actionType = typeOfDayOff;
    if (typeOfDayOff === 'day_off') {
      actionType = 'dayOff';
      textFirst = 'Day Off';
    } else if (typeOfDayOff === 'holiday') {
      textFirst = 'Holiday';
    } else if (typeOfDayOff === 'sick_leave') {
      textFirst = 'Sick Leave';
    } else if (typeOfDayOff === 'office') {
      textFirst = 'Office';
    }
  } else if (_.get(dataSource, 'branch_change')) {
    const branchChange = _.get(dataSource, ['branch_change', 'name']);
    actionType = 'branch_change';
    textFirst = `Go to ${branchChange}`;
  } else if (_.get(dataSource, 'work_shift') === 'morning') {
    actionType = 'morning';
    textFirst = 'Morning Shift';
    if (_.get(dataSource, 'is_reception')) {
      textSecond = t(constants.RECEPTION);
    }
  } else if (_.get(dataSource, 'work_shift') === 'evening') {
    actionType = 'evening';
    textFirst = 'Evening Shift';
    if (_.get(dataSource, 'is_reception')) {
      textSecond = t(constants.RECEPTION);
    }
  } else if (_.get(dataSource, 'work_shift') === 'afternoon') {
    actionType = 'afternoon';
    textFirst = 'Afternoon Shift';
    if (_.get(dataSource, 'is_reception')) {
      textSecond = t(constants.RECEPTION);
    }
  }

  return (
    <CardBlock disabled={disabled} onClick={disabled ? () => {} : onClick}>
      {actionType ? (
        <CardActivityBlock
          disable={disabled}
          actionType={actionType}
          isReception={_.get(dataSource, ['is_reception'])}
        >
          {!disabled && <Pencil className="staff_planning_pencil" />}
          {textFirst && (
            <TextBold
              style={{ whiteSpace: 'initial' }}
              fontSize="12px"
              color={purple}
            >
              {textFirst}
            </TextBold>
          )}
          {textSecond && (
            <TextNormal
              style={{ whiteSpace: 'initial' }}
              fontSize="12px"
              color={purple}
            >
              {textSecond}
            </TextNormal>
          )}
        </CardActivityBlock>
      ) : (
        <PlusBlock>
          {!disabled && <Plus disabled={disabled} width="10px" height="10px" />}
        </PlusBlock>
      )}
    </CardBlock>
  );
};

export default React.memo(CardOfPlaning);
