import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useContext
} from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import { decode } from '../utils/index';


const SocketContext = createContext();

const getToken = () => {
  const tokenObj = window.localStorage.getItem(
    process.env.REACT_APP_TOKEN_STORAGE
  );

  if (!tokenObj) return null;

  const token = decode(tokenObj);

  return JSON.parse(token);
  // return token;
};

const socket = io(process.env.REACT_APP_EVENT_URL, {
  path: '/api/socket-service',
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: `Bearer ${process.env.REACT_APP_SOCKET_TOKEN}`
      }
    }
  }
});

const SocketProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('onConnect');
      setConnected(true);
    });

    const onDisconnect = socket.on('disconnect', () => {
      console.log('disconnect');
      setConnected(false);
    });

    socket.on('booked', (d) => {
      setData(d);
    });
  }, []);

  return (
    <SocketContext.Provider
      value={{ socket, isConnect: connected, socketData: data }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
