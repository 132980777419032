import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Spin,
  Button,
  Modal,
  Select,
  Table,
  Row,
  Col,
  TimePicker,
  Badge
} from 'antd';
import moment from 'moment';
import { MainContext } from '../../context/main';
import {
  isPassword,
  getTime,
  isPhoneNumber,
  minutesDuration,
  normTime,
  timeBetween
} from '../../utils';
import { LanguageContext } from '../../context/language';
import PosedButton from '../../components/Button';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';

const format = 'HH:mm';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 }
  }
};

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14, offset: 1 }
  }
};

const DynamicFieldSet = ({
  customerId,
  form,
  staffData,
  toggle,
  onSubmit,
  currentDate
}) => {
  const { t } = useContext(LanguageContext);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { request, constants } = useContext(MainContext);

  const workStart = _.get(staffData, ['work_start', 'current']);
  const workEnd = _.get(staffData, ['work_end', 'current']);

  const isWorking = timeBetween(workStart, workEnd);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const body = {
          id: _.get(staffData, ['staff', '_id']),
          changed_work_date: currentDate
        };

        const start = minutesDuration(
          _.get(values, ['changed_work_start', '_i'])
        );
        const end = minutesDuration(_.get(values, ['changed_work_end', '_i']));

        if (
          _.toSafeInteger(workStart) !== start
          || _.toSafeInteger(workEnd) !== end
        ) {
          body.changed_work_start = start;
          body.changed_work_end = end;
        }

        setIsSubmitLoading(true);

        const res = await request('/staff', {
          method: 'put',
          body
        });

        setIsSubmitLoading(false);
        toggle();
        onSubmit();
      }
    });
  };

  const validateTime = (rule, value, callback) => {
    const workStartValue = form.getFieldValue('changed_work_start');
    const workEndValue = form.getFieldValue('changed_work_end');

    const workStartTime = minutesDuration(_.get(workStartValue, ['_i'], ''));
    const workEndTime = minutesDuration(_.get(workEndValue, ['_i'], ''));

    if (workEndTime < workStartTime) {
      callback(t(constants.ERROR_WORKING_TIME));
    }

    callback();
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      width={650}
      visible={!!staffData}
      title={t(constants.CHANGE_WORKING)}
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
        >
          {t(constants.PUT)}
        </Button>
      ]}
    >
      <Form>
        <Form.Item
          label={
            <TextNormal color={purple}>{t(constants.NICKNAME)}</TextNormal>
          }
          hasFeedback
        >
          {getFieldDecorator('nickname', {
            initialValue: _.get(staffData, ['staff', 'nickname'])
          })(<Input disabled />)}
        </Form.Item>
        <Row>
          <TextBold color={gray}>{t(constants.WORKING_HOURS)}</TextBold>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              {...formItemLayoutHalf}
              label={(
                <TextNormal color={purple}>
                  {t(constants.WORKING_START)}
                </TextNormal>
)}
              required={false}
              hasFeedback
            >
              {getFieldDecorator('changed_work_start', {
                initialValue: workStart
                  ? moment(getTime(workStart), format)
                  : null,
                getValueFromEvent: normTime(format),
                rules: [
                  {
                    required: true
                  },
                  {
                    validator: validateTime
                  }
                ]
              })(
                <TimePicker
                  //   disabled={!isAllow(allow.PUT)}
                  minuteStep={30}
                  format={format}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayoutHalf}
              label={(
                <TextNormal color={purple}>
                  {t(constants.WORKING_END)}
                </TextNormal>
)}
              required={false}
              hasFeedback
            >
              {getFieldDecorator('changed_work_end', {
                initialValue: workEnd ? moment(getTime(workEnd), format) : null,
                getValueFromEvent: normTime(format),
                rules: [
                  {
                    required: true
                  },
                  {
                    validator: validateTime
                  }
                ]
              })(
                <TimePicker
                  //   disabled={!isAllow(allow.PUT)}
                  minuteStep={30}
                  format={format}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <TextNormal fontSize="16px" color={gray}>
              Status
            </TextNormal>
          </Col>
          <Col
            style={{
              lineHeight: 2
            }}
            span={21}
          >
            <Badge
              color={isWorking ? '#87d068' : '#f50'}
              text={isWorking ? 'Active' : 'Inactive'}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
