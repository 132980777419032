import React, { useEffect } from 'react';
import PT from 'prop-types';
import { ButtonStyle } from './style';

const Button = ({
  onClick,
  children,
  variant,
  active,
  className,
  disabled,
  style
}) => {
  useEffect(() => {
    // Update the document title using the browser API
  });
  return (
    <ButtonStyle
      style={style}
      className={className}
      variant={variant}
      active={active}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
    >
      {children}
    </ButtonStyle>
  );
};

Button.propTypes = {
  onClick: PT.func,
  children: PT.node.isRequired,
  variant: PT.string,
  active: PT.bool,
  className: PT.string,
  disabled: PT.bool
};

Button.defaultProps = {
  onClick: () => undefined,
  variant: 'primary',
  active: false,
  className: '',
  disabled: false
};

export default Button;
