import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Form, Button, Modal, Select, Card, Radio, DatePicker } from 'antd';
import { MainContext } from '../../context/main';

import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
};

const buttonItemLayout = {
  wrapperCol: { span: 14, offset: 4 }
};

const DynamicFieldSet = (props) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { request, branches } = useContext(MainContext);

  const [selectedBranch, setSelectedBranch] = useState(
    _.get(branches, [0, 'id']) === 'all'
      ? _.get(branches, [1, 'id'])
      : _.get(branches, [0, 'id'])
  );

  const [radioValue, setRadioValue] = useState('daily');

  const [selectedDate, setSelectedDate] = useState(null);
  const [validateStatus, setValidateStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate) {
      setValidateStatus('error');
      return;
    }
    setIsSubmitLoading(true);

    const fileName = `booking_${radioValue}_report_${
      radioValue === 'daily'
        ? selectedDate
        : moment(selectedDate).format('YYYY-MM')
    }.xlsx`;

    try {
      await request('/report/booking', {
        type: 'download',
        fileName,
        query: {
          branch: selectedBranch,
          type: radioValue,
          date: selectedDate
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const onRadioChange = ({ target }) => {
    setRadioValue(_.get(target, ['value']));
    setSelectedDate(null);
  };

  const onBranchChange = (val) => {
    setSelectedBranch(val);
  };

  function onDateChange(date, dateString) {
    if (validateStatus) {
      setValidateStatus('');
    }

    setSelectedDate(moment(date).format('YYYY-MM-DD'));
  }

  return (
    <Form layout="horizontal">
      <Form.Item
        label={<TextNormal color={purple}>Report Type</TextNormal>}
        {...formItemLayout}
      >
        <Radio.Group defaultValue={radioValue} onChange={onRadioChange}>
          <Radio.Button value="daily">Daily Report</Radio.Button>
          <Radio.Button value="monthly">Monthly Report</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<TextNormal color={purple}>Select Branch</TextNormal>}
        {...formItemLayout}
      >
        <Select
          defaultValue={selectedBranch}
          style={{ width: 190 }}
          onChange={onBranchChange}
        >
          {_.map(branches, (o) => {
            if (_.get(o, ['id']) === 'all') return null;

            return (
              <Option key={_.get(o, ['id'])} value={_.get(o, ['id'])}>
                {_.get(o, ['title'])}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label={(
          <TextNormal color={purple}>
            Select
            {' '}
            {radioValue === 'daily' ? 'Date' : 'Month'}
          </TextNormal>
)}
        {...formItemLayout}
        validateStatus={validateStatus}
        help="* Required"
      >
        {radioValue === 'daily' ? (
          <DatePicker onChange={onDateChange} />
        ) : (
          <MonthPicker onChange={onDateChange} placeholder="Select month" />
        )}
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button onClick={handleSubmit} loading={isSubmitLoading} type="primary">
          Download
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
