import React, { useContext } from 'react';
import moment from 'moment';
import {
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  DatePicker,
  Checkbox
} from 'antd';
import _ from 'lodash';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import getDayOfWeek from '../../utils/getDayOfWeek';

const { Option } = Select;

const ModalForm = ({ visible, onCancel, onCreate, form, staffData, }) => {
  const { t } = useContext(LanguageContext);
  const { constants, branches } = useContext(MainContext);
  const { getFieldDecorator } = form;

  const days = getDayOfWeek();
  return (
    <Modal
      visible={!!visible}
      title={t(constants.ADD_STAFF_TO_PLAN)}
      okText={t(constants.ADD)}
      cancelText={t(constants.CANCEL)}
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('id')(<Input />)}
        </Form.Item>
        <Form.Item
          label={<TextNormal color={purple}>{t(constants.STAFF)}</TextNormal>}
          hasFeedback
        >
          {getFieldDecorator('staff_id', {
            validateTrigger: [],
            rules: [
              { required: true, message: t(constants.ERROR_SELECT_STAFF) }
            ]
          })(
            <Select
              placeholder={`${t(constants.SELECT)} ${t(constants.STAFF)}`}
            >
              {_.map(staffData, o => (
                <Option key={o.id} value={o.id}>
                  {t(o.full_name)}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Row>
          <TextBold fontSize="14px" color={gray}>
            {t(constants.SHIFT_INFO)}
          </TextBold>
        </Row>
        <Form.Item label="">
          {getFieldDecorator('shift_default', {
            rules: [
              {
                required: true,
                message: t(constants.ERROR_SELECT_SHIFT_DEFAULT)
              }
            ]
          })(
            <Radio.Group>
              <Radio.Button value="morning">
                {t(constants.MORNING)}
              </Radio.Button>
              <Radio.Button value="afternoon">
                {t(constants.AFTERNOON)}
              </Radio.Button>
              <Radio.Button value="evening">
                {t(constants.EVENING)}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <Row>
          <TextBold fontSize="14px" color={gray}>
            {t(constants.MAIN_HOLIDAY)}
          </TextBold>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item label="" hasFeedback>
              {getFieldDecorator('day_off_first', {
                rules: [
                  {
                    required: true,
                    message: t(constants.ERROR_MAIN_HOLIDAY)
                  }
                ]
              })(
                <Select
                  placeholder={`${t(constants.SELECT)} ${t(
                    constants.MAIN_HOLIDAY
                  )}`}
                >
                  {_.map(days, (day, keyOfDay) => (
                    <Option key={`day-${keyOfDay}`} value={keyOfDay}>
                      {day}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="" hasFeedback>
              {getFieldDecorator('day_off_second', {
                rules: [
                  {
                    required: true,
                    message: t(constants.ERROR_MAIN_HOLIDAY)
                  }
                ]
              })(
                <Select
                  placeholder={`${t(constants.SELECT)} ${t(
                    constants.MAIN_HOLIDAY
                  )}`}
                >
                  {_.map(days, (day, keyOfDay) => (
                    <Option key={`day-${keyOfDay}`} value={keyOfDay}>
                      {day}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(ModalForm);
