import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Input, Spin, Button, Modal, Select, Card } from 'antd';
import { MainContext } from '../../context/main';
import { isPassword, isUsername } from '../../utils';
import { LanguageContext } from '../../context/language';
import PosedButton from '../../components/Button';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';

const { Option } = Select;

const DynamicFieldSet = ({ userId, form, open, toggle, onSubmit }) => {
  const { t } = useContext(LanguageContext);
  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [isGetResetPasswordLoading, setIsGetResetPasswordLoading] = useState(
    false
  );
  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [availablePermission, setAvailablePermission] = useState([]);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [isGetPermissionLoading, setIsGetPermissionLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { request, constants, branches, userProfile } = useContext(MainContext);

  const getAvailablePermission = async () => {
    setIsGetPermissionLoading(true);
    const response = await request('/static/permission');

    setIsGetPermissionLoading(false);
    setAvailablePermission(response);
  };

  const getData = async () => {
    if (!userId) {
      setUser(null);

      return;
    }
    setIsGetDataLoading(true);
    const res = await request('/users', {
      query: {
        id: userId
      }
    });

    const referenceData = _.get(res, ['reference_data', 0], {});

    setIsGetDataLoading(false);
    setUser(referenceData);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      setNewPassword('');
      getAvailablePermission();
      getData();
    }
  }, [open]);

  const handleConfirmBlur = (e) => {
    const value = _.get(e, ['target', 'value']);
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t(constants.ERROR_CONFIRM_PASSWORD));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const handleResetPassword = async () => {
    setIsGetResetPasswordLoading(true);
    const res = await request('/auth/reset_password', {
      query: {
        id: userId
      }
    });
    const newUserPassword = _.get(res, ['reference_data', 0, 'new_password']);

    setNewPassword(newUserPassword);
    setIsGetResetPasswordLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        delete values.confirm;
        let body = {};

        if (!user) {
          body = _.omitBy({ ...values }, v => v === '');
        } else {
          body.id = user.id;

          if (values.full_name !== user.full_name) {
            body.full_name = values.full_name;
          }

          if (values.branch_id !== _.get(user, ['branch', '_id'])) {
            body.branch_id = values.branch_id;
          }

          if (
            values.permission_id !== 'admin'
            && values.permission_id !== _.get(user, ['permission', '_id'])
          ) {
            body.permission_id = values.permission_id;
          }
        }

        setIsSubmitLoading(true);

        const res = await request('/users', {
          method: user ? 'put' : 'post',
          body
        });

        setIsSubmitLoading(false);
        toggle();
        onSubmit();
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      width={650}
      visible={open}
      title={user ? t(constants.EDIT_USER) : t(constants.ADD_USER)}
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
        >
          {t(constants.SUBMIT)}
        </Button>
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Form>
          <Form.Item
            label={
              <TextNormal color={purple}>{t(constants.USERNAME)}</TextNormal>
            }
            help={!_.get(user, 'username') ? t(constants.ERROR_USERNAME) : ''}
            hasFeedback
          >
            {getFieldDecorator('username', {
              initialValue: _.get(user, 'username'),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  pattern: isUsername,
                  required: true,
                  whitespace: false,
                  message: t(constants.ERROR_USERNAME)
                }
              ]
            })(
              <Input
                disabled={!!_.get(user, 'username')}
                placeholder={t(constants.INPUT_USERNAME)}
              />
            )}
          </Form.Item>
          <Form.Item
            label={(
              <TextNormal color={purple}>
                {t(constants.USER_FULL_NAME)}
              </TextNormal>
)}
            help={t(constants.ERROR_USER_FULL_NAME)}
            hasFeedback
          >
            {getFieldDecorator('full_name', {
              initialValue: _.get(user, 'full_name'),
              validateTrigger: ['onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: false,
                  message: t(constants.ERROR_USER_FULL_NAME)
                }
              ]
            })(<Input placeholder={t(constants.INPUT_USER_FULL_NAME)} />)}
          </Form.Item>
          <Form.Item
            label={(
              <TextNormal color={purple}>
                {t(constants.USER_PERMISSION)}
              </TextNormal>
)}
            help={t(constants.ERROR_USER_PERMISSION)}
            hasFeedback
          >
            {getFieldDecorator('permission_id', {
              initialValue:
                _.get(user, ['permission', 'type']) === 'admin'
                  ? 'admin'
                  : _.get(user, ['permission', '_id']),
              validateTrigger: [],
              rules: [
                { required: true, message: t(constants.ERROR_USER_PERMISSION) }
              ]
            })(
              <Select
                loading={isGetPermissionLoading}
                placeholder={t(constants.INPUT_USER_PERMISSION)}
              >
                {_.map(availablePermission, o => (
                  <Option key={o.id} value={o.id}>
                    {o.title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={
              <TextNormal color={purple}>{t(constants.USER_BRANCH)}</TextNormal>
            }
            help={t(constants.ERROR_USER_BRANCH)}
            hasFeedback
          >
            {getFieldDecorator('branch_id', {
              initialValue: _.get(user, ['branch', '_id']),
              validateTrigger: [],
              rules: [
                { required: true, message: t(constants.ERROR_USER_BRANCH) }
              ]
            })(
              <Select
                disabled={
                  user && _.toSafeInteger(_.get(branches, ['length'])) === 1
                }
                placeholder={t(constants.INPUT_USER_BRANCH)}
              >
                {_.map(
                  branches,
                  o => o.id !== 'all' && (
                  <Option key={o.id} value={o.id}>
                    {t(o.title)}
                  </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          {!user ? (
            <React.Fragment>
              <Form.Item
                label={(
                  <TextNormal color={purple}>
                    {t(constants.PASSWORD)}
                  </TextNormal>
)}
                help={t(constants.ERROR_PASSWORD)}
                hasFeedback
              >
                {getFieldDecorator('password', {
                  validateTrigger: [],
                  rules: [
                    {
                      pattern: isPassword,
                      message: t(constants.ERROR_PASSWORD)
                    },
                    {
                      required: true,
                      message: t(constants.INPUT_PASSWORD)
                    },
                    {
                      validator: validateToNextPassword
                    }
                  ]
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item
                label={(
                  <TextNormal color={purple}>
                    {t(constants.CONFIRM_PASSWORD)}
                  </TextNormal>
)}
                help={t(constants.ERROR_PASSWORD)}
                hasFeedback
              >
                {getFieldDecorator('confirm', {
                  validateTrigger: [],
                  rules: [
                    {
                      pattern: isPassword,
                      message: t(constants.ERROR_PASSWORD)
                    },
                    {
                      required: true,
                      message: t(constants.INPUT_CONFIRM_PASSWORD)
                    },
                    {
                      validator: compareToFirstPassword
                    }
                  ]
                })(<Input.Password onBlur={handleConfirmBlur} />)}
              </Form.Item>
            </React.Fragment>
          ) : (
            _.get(userProfile, ['permission', 'type']) === 'admin' && (
              <React.Fragment>
                <Spin spinning={isGetResetPasswordLoading}>
                  <Card
                    title={(
                      <TextNormal color={purple}>
                        {t(constants.NEW_PASSWORD)}
                      </TextNormal>
)}
                  >
                    <h1>{newPassword}</h1>
                  </Card>
                </Spin>
                <br />
                <PosedButton
                  onClick={handleResetPassword}
                  className="reset_btn"
                >
                  {t(constants.RESET_PASSWORD)}
                </PosedButton>
              </React.Fragment>
            )
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
