import React, { useState, useContext, useEffect } from 'react';
import { Layout, Popconfirm, Table, Divider, Icon, Menu } from 'antd';
import _ from 'lodash';

import { ContainerBlock } from '../../style/main';

import { MainContext } from '../../context/main';

import Form from './form';

import { ReportStyle } from './style';

const { Content } = Layout;

const ReportScreen = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [currentKey, setCurrentKey] = useState('one');

  const { request } = useContext(MainContext);

  const handleMenu = ({ key }) => {
    setCurrentKey(key);
  };

  const getCustomerReportList = async () => {
    setIsLoading(true);
    const res = await request('/customers');
    const totalData = _.get(res, ['total_data']);

    const files = _.times(_.ceil(totalData / 1000), (i) => {
      const endChunk = 1000 * (i + 1);

      const fileName = `customer_report${i + 1 <= 9 ? '0' : ''}${i
        + 1}_(${1000 * i}-${
        endChunk > totalData ? totalData : endChunk
      }/${totalData}).xlsx`;

      return { fileName, page: i + 1 };
    });

    setFileList(files);
    setIsLoading(false);
  };

  const handleDownload = ({ fileName, page }) => async () => {
    await request('/report/customers', {
      type: 'download',
      fileName,
      query: {
        page
      }
    });
  };

  useEffect(() => {
    if (currentKey === 'one') {
      getCustomerReportList();
    }
  }, [currentKey]);

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName'
    },
    {
      title: '',
      key: 'download',
      render: o => <a onClick={handleDownload(o)}>Download</a>
    }
  ];

  return (
    <>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '50px 50px 0', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380
              }}
            >
              <Menu
                onClick={handleMenu}
                selectedKeys={[currentKey]}
                mode="horizontal"
              >
                <Menu.Item key="one">
                  <Icon type="user" />
                  Customer Report
                </Menu.Item>
                <Menu.Item key="two">
                  <Icon type="schedule" />
                  Booking Report
                </Menu.Item>
              </Menu>
              <ReportStyle>
                {currentKey === 'one' && (
                  <Table
                    loading={isLoading}
                    dataSource={fileList}
                    columns={columns}
                  />
                )}
                {currentKey === 'two' && <Form />}
              </ReportStyle>
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
    </>
  );
};

export default ReportScreen;
