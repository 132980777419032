import React, { useState, useContext, useEffect } from 'react';
import { Layout, Table, Icon, Divider, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock,
} from '../../style/main';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';
import AddButton from '../../components/Button/AddButton';
import Plus from '../../assets/svg/Plus';
import Dashboard from '../../components/Dashboard';
import { MainContext } from '../../context/main';
import {
  formatPhoneNumber,
  isAllow,
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage,
  getTime,
} from '../../utils';
import { LanguageContext } from '../../context/language';
import Filter, { useFilter } from '../../components/Filter';
import { useDropdown } from '../../components/Dropdown';
import { useToggle, useDebounce } from '../../hooks';
import Form from './form';

const { Content } = Layout;

const PromotionScreen = ({ allow, isBranchesFilter }) => {
  const { t } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const { open, toggle } = useToggle();
  const { request, constants, isDesktop } = useContext(MainContext);

  const { open: desc, toggle: changeDesc } = useToggle(true);
  const { value: currentSortKey, handleSelect: handleSortKey } = useDropdown();
  const {
    value: currentFilterKey,
    handleSelect: handleFilterKey,
  } = useDropdown();

  const { value: currentFilterValue, handler } = useFilter();

  const getData = async (params = {}) => {
    setIsLoading(true);
    const res = await request('/promotions', {
      query: {
        ...getPage(pagination),
        ...params,
      },
    });

    const referenceData = _.get(res, ['reference_data'], []);
    const totalData = _.get(res, ['total_data'], 0);

    setData(referenceData);
    setPagination({ ...pagination, total: totalData });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const openForm = promotion => (e) => {
    const id = _.get(promotion, 'id');
    setPromotionId(id);
    toggle();
  };

  const handleTableChange = (paginate, filters, sorter) => {
    const pager = { ...paginate };
    pager.current = paginate.current;

    setPagination(pager);

    const sortQuery = !isDesktop
      ? getSort(sorter)
      : { sort: { id: currentSortKey, desc } };

    getData({
      ...getFilter(filters),
      ...sortQuery,
      ...getPage(paginate),
    });
  };

  const resetQuery = () => {
    setPagination({ current: 1, pageSize: 10 });
    handler({ target: { value: '' } });
  };

  const onSubmit = () => {
    setUpdated(updated + 1);
    // handleSortKey('end_date');
    // resetQuery();
    // getData({
    //   sort: { id: currentSortKey, desc },
    // });
  };

  const handleDelete = id => async (e) => {
    setIsLoading(true);
    const res = await request('/promotions', {
      method: 'delete',
      query: {
        id,
      },
    });
    setIsLoading(false);
    onSubmit();
    // resetQuery();
    // await getData({
    //   sort: { id: currentSortKey, desc },
    // });
  };

  const columns = [
    {
      title: t(constants.PROMOTION_TITLE),
      dataIndex: 'title',
      key: 'title',
      width: !isDesktop ? '40%' : '60%',
      ...(!isDesktop
        && getColumnSearchProps(
          `${t(constants.SEARCH_BY)} ${t(constants.PROMOTION_TITLE)}`
        )),
      render: title => (
        <div style={{ width: '200px' }}>
          <TextNormal fontSize="14px" color={purple}>
            {`${t(constants.PROMOTION_TITLE_EN)} : `}
          </TextNormal>
          <div>{`${title.en}`}</div>
          <TextNormal fontSize="14px" color={purple}>
            {`${t(constants.PROMOTION_TITLE_TH)} : `}
          </TextNormal>
          <div>{`${title.th}`}</div>
        </div>
      ),
    },
    {
      title: t(constants.PROMOTION_START_DATE),
      dataIndex: 'start_date',
      key: 'start_date',
      width: !isDesktop ? '25%' : '15%',
      ...(!isDesktop && { sorter: () => {} }),
      render: date => moment(date).format('D MMMM YYYY'),
    },
    {
      title: t(constants.PROMOTION_END_DATE),
      dataIndex: 'end_date',
      key: 'end_date',
      width: !isDesktop ? '25%' : '15%',
      ...(!isDesktop && { sorter: () => {} }),
      render: date => moment(date).format('D MMMM YYYY'),
    },
    {
      title: '',
      key: '',
      render: o => (
        <span>
          <Icon
            onClick={openForm(o)}
            type={!isAllow(allow.PUT) ? 'zoom-in' : 'edit'}
            className="custom"
          />
          <Divider type="vertical" />
          <Popconfirm
            disabled={!isAllow(allow.DELETE)}
            title={t(constants.SURE_DELETE)}
            onConfirm={handleDelete(o.id)}
          >
            <Icon
              className={!isAllow(allow.DELETE) ? 'disabled' : 'custom'}
              type="delete"
            />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const debouncedFilterValue = useDebounce(currentFilterValue, 500);

  useEffect(() => {
    if (currentSortKey && currentFilterKey && !_.isNil(debouncedFilterValue)) {
      setPagination({ current: 1, pageSize: 10 });
      getData({
        ...(debouncedFilterValue && {
          [currentFilterKey]: debouncedFilterValue,
        }),
        sort: { id: currentSortKey, desc },
      });
    }
  }, [debouncedFilterValue, currentFilterKey]);

  useEffect(() => {
    if (currentSortKey) {
      resetQuery();
      getData({
        sort: { id: currentSortKey, desc },
      });
    }
  }, [desc, currentSortKey, updated]);

  const sortItems = [
    { id: 'end_date', title: t(constants.PROMOTION_END_DATE) },
    { id: 'start_date', title: t(constants.PROMOTION_START_DATE) },
  ];

  const filterItems = [{ id: 'title', title: t(constants.PROMOTION_TITLE) }];

  return (
    <>
      <HeaderContentBlock>
        <AddButtonBlock>
          {isAllow(allow.POST) && (
            <AddButton onClick={openForm(null)}>
              <Plus style={{ marginRight: '15px' }} />
              <TextBold color={purple}>
                {`${t(constants.ADD_PROMOTION)}`}
              </TextBold>
            </AddButton>
          )}
        </AddButtonBlock>
      </HeaderContentBlock>

      <Filter
        sortItems={sortItems}
        currentSortKey={currentSortKey}
        handleSortKey={handleSortKey}
        filterItems={filterItems}
        currentFilterKey={currentFilterKey}
        handleFilterKey={handleFilterKey}
        desc={desc}
        changeDesc={changeDesc}
        filterValue={currentFilterValue}
        onChange={handler}
      />
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0 50px', marginTop: 0 }}>
            <div
              style={{
                // margin: '16px 0',
                background: '#fff',
                minHeight: 380,
              }}
            >
              <Table
                className="fixedWidthTable"
                rowKey="id"
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
                dataSource={data}
                scroll={{ y: '35vh' }}
              />
            </div>
          </Content>
        </Layout>
      </ContainerBlock>
      <Form
        promotionId={promotionId}
        open={open}
        toggle={toggle}
        onSubmit={onSubmit}
        allow={allow}
      />
    </>
  );
};

export default PromotionScreen;
