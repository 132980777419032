import React, { useState, useContext, useEffect, useRef } from 'react';
import { Layout, Table, Icon, Drawer, Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useSSE } from 'react-hooks-sse';
import {
  ContainerBlock,
  HeaderContentBlock,
  AddButtonBlock,
} from '../../style/main';
import { TextBold, TextNormal } from '../../style/fontStyle';
import { purple } from '../../style/color';
import AddButton from '../../components/Button/AddButton';

import Plus from '../../assets/svg/Plus';
import Calendar from '../../assets/svg/Calendar';
import Pencil from '../../assets/svg/Pencil';
import ChangeWorkingModal from './ChangeWorkingModal';
import { MainContext } from '../../context/main';
import { SocketContext, SocketProvider } from '../../context/socket';
import {
  formatPhoneNumber,
  isAllow,
  getColumnSearchProps,
  getFilter,
  getSort,
  getPage,
  getTime,
} from '../../utils';
import { LanguageContext } from '../../context/language';
import { useToggle, useDebounce, useWindowWidth } from '../../hooks';
import MonthPickerComponent, {
  useBigPicker,
} from '../../components/MonthPickerComponent';
import FilterButton, { useFilterButton } from '../../components/FilterButton';
import { PlusBlock } from '../../components/CardOfPlaning/style';
import BookingTable from './BookingTable';
import ModalForm from './ModalForm';
// import Form from './form';
import {
  DrawerWrapper,
  DrawerContentWrapper,
  DrawerToggle,
  DrawerContentContainer,
  DrawerContent,
  Underline,
  ContentBlock,
  ContentBlockWrapper,
  ContentBlockButton,
} from './style';

const { Content } = Layout;

const BookingScreen = ({ allow, isBranchesFilter, allowPermission }) => {
  const { t } = useContext(LanguageContext);
  const { request, currentBranch, constants, isDesktop, getToken } = useContext(
    MainContext
  );
  const { socketData, isConnect } = useContext(SocketContext);
  const tableBlockRef = useRef(null);
  const formRef = useRef(null);

  const [bookedData, setBookedData] = useState([]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setFormIsLoading] = useState(false);
  const [tableBlockWidth, setTableBlockWidth] = useState(0);

  const [roomData, setRoomData] = useState(false);
  // const bookedEvent = useSSE('booked');
  const [openModal, setOpenModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const { open, toggle } = useToggle();
  const {
    open: openChangeWorkingModal,
    toggle: toggleChangeWorkingModal,
  } = useToggle();
  const [staffData, setStaffData] = useState(null);

  const { current, setCurrent } = useBigPicker();
  const currentDate = moment(current).format('YYYY-MM-DD');
  // const currentDate = moment('2023-01-29').format('YYYY-MM-DD');

  const { value: currentFilter, handler: handleFilter } = useFilterButton();

  const getData = async (loading = true) => {
    setIsLoading(loading);

    // const res = await request(`/booking/${currentDate}`, {
    //   query: {
    //     branch: currentBranch
    //   }
    // });
    const res = await request(`/booking/${currentDate}`, {
      query: {
        // branch: '5d70af2c7cf9b342455ef178'
        branch: currentBranch,
      },
      // error_alert: 'none'
    });

    // console.log({currentBranch});

    // console.log('getData', {
    //   res
    // });
    setData({ ...res });

    setIsLoading(false);
  };

  const resetAllFields = () => {
    if (formRef.current) {
      const { resetFields } = formRef.current;
      resetFields();
    }
  };

  const getBookingData = async () => {
    const res = await request('/booking', {
      query: {
        date: currentDate,
        branch: currentBranch,
        // branch: '5d70af2c7cf9b342455ef178',
        includes: ['branch', 'staff', 'customer', 'courses', 'half'],
      },
      error_alert: 'none',
    });

    const normalBooking = _.get(res, ['reference_data'], []);
    const halfBooking = _.get(res, ['half_booking'], []);

    setBookedData([...normalBooking, ...halfBooking]);
  };

  const handleToggleChangeWorkingModal = staff => (e) => {
    if (openChangeWorkingModal) {
      setStaffData(null);
    } else {
      setStaffData(staff);
    }
  };

  const hendleSetTableBlockWidth = () => {
    if (tableBlockRef.current) {
      setTableBlockWidth(tableBlockRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    const eventBranch = _.get(socketData, ['branch']);
    const eventDate = _.get(socketData, ['date']);

    if (
      eventBranch
      && eventBranch === currentBranch
      && eventDate
      && moment(currentDate).isSame(eventDate)
    ) {
      getData(false);
      getBookingData();
    }
  }, [socketData]);

  useEffect(() => {
    hendleSetTableBlockWidth();
    window.addEventListener('resize', hendleSetTableBlockWidth);
    return () => {
      window.removeEventListener('resize', hendleSetTableBlockWidth);
    };
  }, []);

  useEffect(() => {
    if (currentBranch && currentBranch !== 'all') {
      getData();
      getBookingData();
    }
  }, [currentBranch, currentDate]);

  const handleClickRoom = handleData => async () => {
    if (formRef.current) {
      resetAllFields();
      const { setFieldsValue } = formRef.current;
      const staff = {};
      // console.log({ handleData });
      if (_.get(handleData, ['bookedMatch'])) {
        const tempCourses = _.get(handleData, ['bookedMatch', 'courses']);
        const specialCourses = _.chain(tempCourses)
          .filter(course => course.type === 'special')
          .map(course => course.id)
          .value();
        const mainCourses = _.chain(tempCourses)
          .filter(course => course.type === 'main')
          .map(course => course.id)
          .value();
        const optionalCourses = _.chain(tempCourses)
          .filter(course => course.type === 'optional')
          .map(course => course.id)
          .value();

        setFieldsValue({
          booking_id: _.get(handleData, ['bookedMatch', 'id']),
          staff: _.get(handleData, ['bookedMatch', 'staff', 'id']),
          customer_id: _.get(handleData, ['bookedMatch', 'customer', 'id']),
          first_name: _.get(handleData, [
            'bookedMatch',
            'customer',
            'first_name',
          ]),
          last_name: _.get(handleData, [
            'bookedMatch',
            'customer',
            'last_name',
          ]),
          phone: _.get(handleData, ['bookedMatch', 'customer', 'telephone']),
          customer_no: _.get(handleData, [
            'bookedMatch',
            'customer',
            'customer_no',
          ]),
          status: _.get(handleData, ['bookedMatch', 'status']),
          booking_by: _.get(handleData, ['bookedMatch', 'booking_by']),
          show_status: _.get(handleData, ['bookedMatch', 'show_status']),
          is_new: _.get(handleData, ['bookedMatch', 'is_new']),
          special: specialCourses,
          main: mainCourses,
          optional: optionalCourses,
          note: _.get(handleData, ['bookedMatch', 'note']),
        });
        _.set(
          staff,
          ['staffId'],
          _.get(handleData, ['bookedMatch', 'staff', 'id'])
        );
      }
      setRoomData({ ...handleData, ...staff });
    }

    try {
      const id = _.get(handleData, ['bookedMatch', 'id']);
      const waitForAccept = _.get(handleData, [
        'bookedMatch',
        'wait_for_accept',
      ]);

      if (waitForAccept) {
        const res = await request('/booking', {
          method: 'put',
          body: { booking_id: id, accept: true },
        });

        if (_.get(res, 'success')) {
          getBookingData();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCancel = () => {
    setRoomData(false);
    resetAllFields();
  };
  const onCreate = () => {
    // console.log('object');
    if (formRef.current) {
      // console.log('formRef.current', { roomData });
      const { validateFields } = formRef.current;
      validateFields(async (err, values) => {
        if (!err) {
          // console.log({ values, roomData });
          let dataForSave = {};

          if (values.booking_id) {
            // booking_type
            dataForSave = {
              booking_id: values.booking_id,
              staff_id: values.staff,
              courses: _.flatten([
                _.get(values, 'special', []),
                _.get(values, 'main', []),
                _.get(values, 'optional', []),
              ]),
              note: _.get(values, 'note'),
              status: _.get(values, 'status'),
              show_status: _.get(values, 'show_status'),
              is_new: _.get(values, 'is_new'),
            };
            if (roomData.type === 'half') {
              _.set(dataForSave, ['booking_type'], 'half');
            }
          } else {
            dataForSave = {
              branch_id: currentBranch,
              // branch_id: '5d70af2c7cf9b342455ef178',
              booking_date: currentDate,
              booking_time: _.get(roomData, 'time', 0),
              staff_id: values.staff,
              courses: _.flatten([
                _.get(values, 'special', []),
                _.get(values, 'main', []),
                _.get(values, 'optional', []),
              ]),
              booking_by: values.booking_by,
              note: _.get(values, 'note'),
              status: _.get(values, 'status'),
              show_status: _.get(values, 'show_status'),
              is_new: _.get(values, 'is_new'),
            };

            if (roomData.type === 'half') {
              _.set(dataForSave, ['booking_type'], 'half');
              _.set(dataForSave, ['half_slot'], _.get(roomData, 'room', 0));
            } else {
              _.set(dataForSave, ['booking_room'], _.get(roomData, 'room', 0));
            }

            if (values.customer_id) {
              _.set(dataForSave, ['customer_id'], values.customer_id);
              if (values.customer_no) {
                _.set(dataForSave, ['update_customer_no'], values.customer_no);
              }
            } else {
              _.set(
                dataForSave,
                ['new_customer_first_name'],
                values.first_name
              );
              _.set(dataForSave, ['new_customer_last_name'], values.last_name);
              _.set(dataForSave, ['new_customer_telephone'], values.phone);
              if (values.customer_no) {
                _.set(dataForSave, ['new_customer_no'], values.customer_no);
              }
            }
          }
          // console.log({ dataForSave });
          setFormIsLoading(true);
          const res = await request('/booking', {
            method: values.booking_id ? 'put' : 'post',
            body: dataForSave,
          });
          setFormIsLoading(false);
          // console.log({ res });
          if (_.get(res, 'success')) {
            if (values.booking_id) {
              getData();
              // const newBookedData = _.filter(
              //   bookedData,
              //   o => o.id === values.booking_id
              // );

              // const newBookedData = _.get(res, ['reference_data', 0]);

              // console.log({ bookedData, newBookedData });

              // // setBookedData(newBookedData);
              getBookingData();
            }

            setRoomData(false);
            resetAllFields();
          }
        }
      });
    }
  };

  const onDelete = async () => {
    // console.log('object');
    if (formRef.current) {
      // console.log('formRef.current', { roomData });
      const { validateFields } = formRef.current;
      validateFields(async (err, values) => {
        if (!err) {
          const res = await request(`/booking?id=${values.booking_id}`, {
            method: 'delete',
          });
          // console.log({ res });
          if (_.get(res, 'success')) {
            getData();
            getBookingData();

            setRoomData(false);
          }
        }
      });
    }
  };

  // useEffect(() => {
  //   console.log({
  //     bookedData,
  //     data,
  //     roomData,
  //     staffData,
  //   });
  // }, [bookedData, data, roomData, staffData]);

  return (
    <>
      <HeaderContentBlock>
        <MonthPickerComponent
          mode="full"
          current={current}
          setCurrent={setCurrent}
        />
        <AddButtonBlock responsive>
          <FilterButton handler={handleFilter} currentFilter={currentFilter} />
        </AddButtonBlock>
      </HeaderContentBlock>
      <ContainerBlock>
        <Layout>
          <Content style={{ padding: '0', marginTop: 0 }}>
            <Spin spinning={isLoading}>
              <div
                ref={tableBlockRef}
                style={{
                  // margin: '16px 0',
                  background: '#fff',
                  minHeight: 380,
                  // maxHeight: 325,
                  width: '100%',
                }}
              >
                <BookingTable
                  // key={isLoading}
                  currentDate={currentDate}
                  currentFilter={currentFilter}
                  data={data}
                  bookedData={bookedData}
                  tableBlockWidth={tableBlockWidth}
                  handleClickRoom={handleClickRoom}
                />
              </div>
            </Spin>
          </Content>
        </Layout>
      </ContainerBlock>
      <ModalForm
        allowPermission={allowPermission}
        allow={allow}
        ref={formRef}
        visible={!!roomData}
        onCancel={onCancel}
        onCreate={onCreate}
        onDelete={onDelete}
        data={data}
        roomData={roomData}
        isFormLoading={isFormLoading}
      />
      <DrawerWrapper>
        <Drawer
          placement="left"
          closable
          onClose={toggle}
          // visible
          visible={open}
          // mask={false}
          zIndex={0}
          getContainer={false}
          width={650}
          // style={{ position: 'absolute' }}
          bodyStyle={{
            padding: 0,
            height: '100vh',
            // overflow: 'visible hidden'
            overflow: 'visible',
          }}
        >
          <DrawerContentWrapper>
            <DrawerToggle active={open}>
              <AddButton
                style={{
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 0,
                }}
                onClick={toggle}
              >
                <Calendar />
              </AddButton>
            </DrawerToggle>
            <DrawerContentContainer>
              <DrawerContent>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <TextNormal fontSize="24px" color={purple}>
                    Staff's Today
                  </TextNormal>
                </div>
                <Underline />
                <ContentBlockWrapper>
                  {_.map(_.get(data, ['available_staff']), (o, i) => (
                    <ContentBlock key={i}>
                      <ContentBlockButton
                        onClick={handleToggleChangeWorkingModal(o)}
                      >
                        <Pencil />
                      </ContentBlockButton>
                      <TextNormal fontSize="20px" color={purple}>
                        {_.get(o, ['staff', 'nickname'])}
                      </TextNormal>
                      <TextNormal fontSize="20px" color={purple}>
                        {`${getTime(
                          _.get(o, ['work_start', 'current'])
                        )} - ${getTime(_.get(o, ['work_end', 'current']))}`}
                      </TextNormal>
                    </ContentBlock>
                  ))}
                </ContentBlockWrapper>
              </DrawerContent>
            </DrawerContentContainer>
          </DrawerContentWrapper>
        </Drawer>
      </DrawerWrapper>
      <ChangeWorkingModal
        currentDate={currentDate}
        staffData={staffData}
        toggle={handleToggleChangeWorkingModal(null)}
        onSubmit={getData}
      />
    </>
  );
};

const WrappedBookingScreen = props => (
  <SocketProvider>
    <BookingScreen {...props} />
  </SocketProvider>
);

// export default WrappedBookingScreen;
export default BookingScreen;
