import PermissionScreen from '../screen/PermissionScreen';
import UsersScreen from '../screen/UsersScreen';
import BranchScreen from '../screen/BranchScreen';
import PermissionIcon from '../assets/svg/Access';
import UserIcon from '../assets/svg/Users';
import BranchIcon from '../assets/svg/Branch';
import CourseIcon from '../assets/svg/Course';
import PromotionIcon from '../assets/svg/Promotion';
import StaffIcon from '../assets/svg/Staff';
import StaffScreen from '../screen/StaffScreen';
import CourseScreen from '../screen/CourseScreen';
import PromotionScreen from '../screen/PromotionScreen';
import StaffPlaningIcon from '../assets/svg/StaffPlaningIcon';
import StaffPlaningScreen from '../screen/StaffPlaningScreen';
import CustomerIcon from '../assets/svg/Customer';
import CustomerScreen from '../screen/CustomerScreen';
import BookingIcon from '../assets/svg/Booking';
import BookingScreen from '../screen/BookingScreen';
import ReportIcon from '../assets/svg/Report';
import ReportScreen from '../screen/ReportScreen';

const JUST_ALL = ['all'];
const ALL_AND_SAME_BRANCH = ['all', 'same_branch'];

export const getRoutes = ({ path, name, allow }) => {
  if (!allow.GET) return null;

  switch (path) {
    case '/booking':
      return {
        order: 2,
        icon: BookingIcon,
        component: BookingScreen,
        path: '/dashboard/booking',
        name,
        allow,
        allowPermission: {
          POST: ALL_AND_SAME_BRANCH,
          PUT: ALL_AND_SAME_BRANCH,
          DELETE: ALL_AND_SAME_BRANCH
        },
        isBranchesFilter: true
      };
    case '/staff-planing':
      return {
        order: 2,
        icon: StaffPlaningIcon,
        component: StaffPlaningScreen,
        path: '/dashboard/staff-planing',
        name,
        allow,
        allowPermission: {
          POST: ALL_AND_SAME_BRANCH,
          PUT: ALL_AND_SAME_BRANCH,
          DELETE: ALL_AND_SAME_BRANCH
        },
        isBranchesFilter: true
      };
    case '/staff':
      return {
        order: 3,
        icon: StaffIcon,
        component: StaffScreen,
        path: '/dashboard/staff',
        name,
        allow,
        allowPermission: {
          POST: ALL_AND_SAME_BRANCH,
          PUT: ALL_AND_SAME_BRANCH,
          DELETE: ALL_AND_SAME_BRANCH
        },
        isBranchesFilter: true
      };
    case '/customers':
      return {
        order: 4,
        icon: CustomerIcon,
        component: CustomerScreen,
        path: '/dashboard/customers',
        name,
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: JUST_ALL,
          DELETE: JUST_ALL
        },
        isBranchesFilter: false
      };
    case '/branches':
      return {
        order: 9,
        icon: BranchIcon,
        component: BranchScreen,
        path: '/dashboard/branches',
        name: 'shop management',
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: ALL_AND_SAME_BRANCH,
          DELETE: ALL_AND_SAME_BRANCH
        },
        isBranchesFilter: true
      };
    case '/courses':
      return {
        order: 12,
        icon: CourseIcon,
        component: CourseScreen,
        path: '/dashboard/courses',
        name: 'course management',
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: JUST_ALL,
          DELETE: JUST_ALL
        },
        isBranchesFilter: false
      };
    case '/promotions':
      return {
        order: 13,
        icon: PromotionIcon,
        component: PromotionScreen,
        path: '/dashboard/promotions',
        name: 'promotion',
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: JUST_ALL,
          DELETE: JUST_ALL
        },
        isBranchesFilter: false
      };
    case '/report':
      return {
        order: 18,
        icon: ReportIcon,
        component: ReportScreen,
        path: '/dashboard/report',
        name,
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: JUST_ALL,
          DELETE: JUST_ALL
        },
        isBranchesFilter: false
      };
    case '/users':
      return {
        order: 19,
        icon: UserIcon,
        component: UsersScreen,
        path: '/dashboard/users',
        name,
        allow,
        allowPermission: {
          POST: ALL_AND_SAME_BRANCH,
          PUT: ALL_AND_SAME_BRANCH,
          DELETE: JUST_ALL
        },
        isBranchesFilter: true
      };
    case '/permissions':
      if (allow.GET !== 'all') return null;
      return {
        order: 20,
        icon: PermissionIcon,
        component: PermissionScreen,
        path: '/dashboard/permissions',
        name,
        allow,
        allowPermission: {
          POST: JUST_ALL,
          PUT: JUST_ALL,
          DELETE: JUST_ALL
        },
        isBranchesFilter: false
      };
    default:
      return null;
  }
};
