import React from 'react';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import { Input, Icon } from 'antd';
import moment from 'moment';
import Button from '../components/Button';

export const minutesDuration = (time /* 09:00 */) => {
  const splitTime = _.split(time, ':');
  const hours = _.get(splitTime, 0);
  const minutes = _.get(splitTime, 1);

  return _.toSafeInteger(hours) * 60 + _.toSafeInteger(minutes);
};

export const timeBetween = (start, end) => {
  const cur = moment().format('H:mm');

  const curMinute = minutesDuration(cur);

  return curMinute >= start && curMinute <= end;
};

export const getTime = m => moment()
  .hours(0)
  .minutes(0)
  .seconds(0)
  .add(m, 'minutes')
  .format('H:mm');

export const getFullPath = path => `${process.env.REACT_APP_HOST}/${path}`;

export const getRatio = (a, b) => _.round(a > b ? a / b : b / a, 1);

const div = (a, b) => (a > b ? a / b : b / a);

export const isValidRatio = (fileValue, ratio = 1.8) => new Promise((resolve) => {
  const reader = new FileReader();

  // Read the contents of Image File.
  reader.readAsDataURL(fileValue);
  reader.onload = (e) => {
    // Initiate the JavaScript Image object.
    const image = new Image();

    // Set the Base64 string return from FileReader as source.
    image.src = _.get(e, ['target', 'result']);

    // Validate the File Height and Width.
    image.onload = (event) => {
      // const img = _.get(event, ['path', 0]);
      // if (!img) resolve(false);

      const { height, width } = image;

      const validRatio = getRatio(
        _.toSafeInteger(height),
        _.toSafeInteger(width)
      );
      // console.log('onload ::', {event, image, validRatio});

      if (_.isArray(ratio)) {
        resolve(_.includes(ratio, validRatio));
      } else {
        resolve(validRatio === ratio);
      }
    };
  };
});

export const isImage = fileType => fileType === 'image/jpeg'
  || fileType === 'image/jpg'
  || fileType === 'image/png';

export const isValidSize = fileSize => _.toSafeInteger(fileSize) <= imageLimitSize;

export const getBase64 = (img, callback) => {
  const { FileReader } = window;
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  const fileList = _.get(e, ['fileList']);

  if (_.toSafeInteger(_.get(fileList, ['length'])) > 1) {
    fileList.shift();
  }

  return e && e.fileList;
};

export const normTime = (format = 'HH:mm') => (_time, timeString) => (timeString ? moment(timeString, format) : null);
export const normDate = (format = 'DD/MM/YYYY') => (_date, dateString) => (dateString ? moment(dateString, format) : null);

export const beforeUpload = newFile => false;

export const isJpgOrPng = fileType => fileType === 'image/jpeg' || fileType === 'image/png';
export const isLt2M = fileSize => (_.toSafeInteger(fileSize) === 0
  ? false
  : _.toSafeInteger(fileSize) / 1024 / 1024 < 2);

export const dummyRequest = e => setTimeout(() => {
  const { onSuccess } = e;

  onSuccess('OK');
});

export const getPage = paginateObj => ({
  page: _.get(paginateObj, 'current', 1),
  per_page: _.get(paginateObj, 'pageSize', 10)
});

export const getSort = (sortObj) => {
  const sortId = _.get(sortObj, ['columnKey']);
  const sortDesc = _.get(sortObj, ['order']);

  const sortOpt = {};

  if (sortId) {
    const newSortObj = {};
    newSortObj.id = sortId;
    newSortObj.desc = sortDesc === 'descend';

    sortOpt.sort = newSortObj;
  }

  return sortOpt;
};

export const getFilter = (filterObj) => {
  const newFilter = Object.entries(filterObj).reduce((acc, cur, i) => {
    const key = _.get(cur, [0]);
    const value = _.get(cur, [1, 0], '');

    if (!key || !value) return acc;

    acc[key] = value;
    return acc;
  }, {});

  return newFilter;
};

export const encode = (data) => {
  if (!data) return null;
  const cdata = CryptoJS.AES.encrypt(data, process.env.REACT_APP_SECRET);
  return cdata;
};
export const decode = (data) => {
  if (!data) return null;
  const cdata = CryptoJS.AES.decrypt(
    _.toString(data),
    process.env.REACT_APP_SECRET
  );

  return cdata.toString(CryptoJS.enc.Utf8);
};

export const serialize = (obj) => {
  if (!obj) return null;
  return Object.entries(obj)
    .map((i) => {
      let params;

      if (Array.isArray(i[1])) {
        params = i[1]
          .map(val => [`${i[0]}[]`, encodeURIComponent(val)].join('='))
          .join('&');
        return params;
      }

      if (typeof i[1] === 'object' && i[1] !== null) {
        params = Object.entries(i[1])
          .map(ii => [
            encodeURIComponent(`${i[0]}[${ii[0]}]`),
            encodeURIComponent(ii[1])
          ].join('='))
          .join('&');
        return params;
      }

      params = [i[0], encodeURIComponent(i[1])].join('=');

      return params;
    })
    .join('&');
};

export const getPermissionColor = (allowStatus) => {
  switch (allowStatus) {
    case 'all':
      return 'geekblue';
    case 'same_branch':
      return 'green';
    default:
      return 'volcano';
  }
};

export const imageLimitSize = 2 * 1024 * 1024;

export const isUsername = /^[A-Z]{2}[0-9]{5}$/;

export const isBranchCode = /^[A-Z]{3}[0-9]{3}$/;

export const isEndpointsPath = /\/(\w+)/i;

export const isEmail = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,10}$/;

export const isPhoneNumber = /0[0-9]{8,9}$/;

export const isPassword = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}/;

export const isAllow = (allow, permission = ['all']) => _.includes(permission, allow);

export const formatPhoneNumber = phone => phone.replace(/(\d{2,3})(\d{3})(\d{4})/, '$1 $2-$3');

export const getColumnSearchProps = (placeholder) => {
  let inputRef;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(n) => {
            inputRef = n;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon="search"
            size="small"
            style={{ padding: '7px 25px' }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ padding: '7px 25px' }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.select());
      }
    }
  };
};

export function isArraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (
    _.toSafeInteger(_.get(a, ['length']))
    !== _.toSafeInteger(_.get(b, ['length']))
  ) return false;

  for (let i = 0; i < _.toSafeInteger(_.get(a, ['length'])); i += 1) {
    if (!_.includes(b, a[i])) return false;
  }
  return true;
}

export const validateDuration = errMsg => (rule, value, callback) => {
  if (_.toSafeInteger(value) > 360) callback(errMsg);

  callback();
};
