import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { Card, Col, Row } from 'antd';
import Dropdown, { useDropdown } from '../Dropdown';
import AscIcon from '../../assets/svg/Ascend';
import DescIcon from '../../assets/svg/Descend';
import { MainContext } from '../../context/main';
import { LanguageContext } from '../../context/language';
import { useWindowWidth } from '../../hooks';
import { FilterBlock, SortBlock, IconButton } from './style';

export const useFilter = () => {
  const [state, setState] = useState('');

  const handler = (e) => {
    setState(_.get(e, ['target', 'value']));
  };

  return {
    value: state,
    handler
  };
};

const Filter = ({
  sortItems,
  currentSortKey,
  handleSortKey,
  filterItems,
  currentFilterKey,
  handleFilterKey,
  filterValue,
  onChange,
  desc,
  changeDesc
}) => {
  const { t } = useContext(LanguageContext);
  const { constants } = useContext(MainContext);

  return (
    useWindowWidth() >= 1600 && (
      <FilterBlock>
        <div style={{ background: '#ECECEC', padding: '15px', width: '100%' }}>
          <Row gutter={16}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={16}>
              <div className="filter_wrapper">
                <Dropdown
                  prefixCurrent={`${t(constants.SEARCH_BY)} : `}
                  height={50}
                  current={currentFilterKey}
                  onClick={handleFilterKey}
                  data={filterItems}
                />
              </div>
              <div className="filter_input_wrapper">
                <input
                  onChange={onChange}
                  value={filterValue}
                  placeholder={t(constants.INPUT_SEARCH_WORDS)}
                />
              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={8}>
              <SortBlock>
                <Dropdown
                  prefixCurrent={`${t(constants.SORT_BY)} : `}
                  height={50}
                  current={currentSortKey}
                  onClick={handleSortKey}
                  data={sortItems}
                />
                <IconButton onClick={changeDesc}>
                  {desc ? <DescIcon /> : <AscIcon />}
                </IconButton>
              </SortBlock>
            </Col>
          </Row>
        </div>
      </FilterBlock>
    )
  );
};

export default Filter;
