import styled from 'styled-components';
import _ from 'lodash';
import { white } from './color';

export const ContainerBlock = styled.div`
  position: 'relative';
  width: 100%;
  /* padding: 0 50px; */

  .ant-layout {
    background: transparent;
  }

  .ant-layout-header {
    background: transparent;
  }

  .add_btn {
    /* bottom: 20px;
    position: absolute; */
  }
`;

export const HeaderContentBlock = styled.div`
  padding: 30px 60px 60px;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const AddButtonBlock = styled.div`
  position: absolute;
  right: 60px;
  ${({ responsive }) => responsive
    && `@media only screen and (max-width: 1024px) {
    position: relative;
    right: -60px;
  }`}
`;
