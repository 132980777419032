import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="39"
    viewBox="0 0 48 39"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={active ? secondary : primary} d="M-30-703H87V321H-30z" />
      <g fill={active ? white : secondary} fillRule="nonzero">
        <path d="M45.794 37.593h-.8v-20.87A6.53 6.53 0 0 0 48 11.23a.704.704 0 0 0-.093-.35l-5.579-9.768A2.211 2.211 0 0 0 40.413 0H7.587a2.21 2.21 0 0 0-1.915 1.112l-5.58 9.769A.704.704 0 0 0 0 11.23a6.53 6.53 0 0 0 3.006 5.494v20.87h-.8a.703.703 0 0 0 0 1.406h43.588a.703.703 0 0 0 0-1.407zm-44.34-25.66H3.71a.703.703 0 0 0 0-1.407H1.915L6.893 1.81a.802.802 0 0 1 .694-.403h32.826c.286 0 .552.154.694.403l4.978 8.716h-1.794a.703.703 0 0 0 0 1.407h2.254a5.13 5.13 0 0 1-5.072 4.42 5.129 5.129 0 0 1-5.072-4.42h4.884a.703.703 0 0 0 0-1.407H6.715a.703.703 0 0 0 0 1.407H11.6a5.129 5.129 0 0 1-5.072 4.42 5.13 5.13 0 0 1-5.072-4.42zm33.442 0a5.129 5.129 0 0 1-5.072 4.42 5.129 5.129 0 0 1-5.071-4.42h10.143zm-11.649 0a5.129 5.129 0 0 1-5.071 4.42 5.129 5.129 0 0 1-5.072-4.42h10.143zm16.583 25.66h-7.612v-2.352h7.612v2.352zm0-3.76h-7.612V20.956h7.612v12.879zm3.758 3.76h-2.352V20.252a.703.703 0 0 0-.703-.704h-9.018a.703.703 0 0 0-.703.704v17.341h-26.4V17.406a6.493 6.493 0 0 0 2.115.354c2.54 0 4.746-1.46 5.825-3.584a6.534 6.534 0 0 0 5.824 3.584c2.54 0 4.746-1.46 5.824-3.584a6.534 6.534 0 0 0 5.824 3.584c2.54 0 4.746-1.46 5.824-3.584a6.534 6.534 0 0 0 5.825 3.584c.74 0 1.45-.126 2.115-.354v20.187z" />
        <path d="M27.32 19H7.68c-.375 0-.68.321-.68.717v14.566c0 .396.305.717.68.717h19.64c.375 0 .68-.321.68-.717V19.717c0-.396-.305-.717-.68-.717zm-.681 14.566H8.36V20.434H26.64v13.132z" />
      </g>
    </g>
  </svg>
);
