import styled from 'styled-components';
import { NavButtonStyle } from '../NavButton/style';
import { white, primary, secondary } from '../../style/color';

export const FilterBlock = styled.div`
  padding: 0px 60px;
  margin-top: -60px;
  width: 100%;
  min-height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .filter_input_wrapper {
    display: flex;
    width: 65%;
    height: 100%;
    position: relative;
    margin-left: auto;

    input {
      width: 100%;
      height: 50px;
      font-size: 1.3rem;
      text-indent: 10px;
      font-family: 'Kanit';
      line-height: 50px;
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  .filter_wrapper {
    display: flex;
    width: 35%;
    height: 100%;
    position: relative;
    .dropdown_block {
      width: 100%;
      position: absolute;
      z-index: 1;
      font-size: 1.2rem;

      :after {
        top: 10px;
      }
    }

    .dropdown_block,
    .active_block,
    .parent_dropdown_list,
    .dropdown_list {
      color: ${white};
      background: ${primary};

      h6 {
        color: ${white};

        @media only screen and (max-width: 1024px) {
          font-size: 0.7rem;
        }

        @media only screen and (max-width: 768px) {
          font-size: 0.5rem;
        }
      }

      :hover {
        background: ${secondary};
      }
    }
  }
`;

export const SortBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .dropdown_block {
    width: 80%;
    position: absolute;
    z-index: 1;
    font-size: 1.2rem;

    :after {
      top: 10px;
    }
  }

  .dropdown_block,
  .active_block,
  .parent_dropdown_list,
  .dropdown_list {
    color: ${white};
    background: ${primary};

    h6 {
      color: ${white};
    }

    :hover {
      background: ${secondary};
    }
  }
`;

export const IconButton = styled(NavButtonStyle)`
  width: 70px;
  height: auto;
  margin-left: auto;
  margin-right: 10px;
  background: ${primary};

  :hover {
    background: ${secondary};
  }

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 0px;
  }
`;
