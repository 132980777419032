import styled from 'styled-components';
import { white } from '../../style/color';

export const ContainerBlock = styled.div`
  width: 100%;
  /* padding: 0 50px; */

  .ant-layout {
    background: transparent;
  }

  .ant-layout-header {
    background: transparent;
  }
`;
