import React, { useState, useContext } from 'react';
import { Spin } from 'antd';
import { LoginStyle, ContainerBlock, LoginWrapper } from './style';
import Button from '../../components/Button';
import { MainContext } from '../../context/main';
import MaterialInput from '../../components/MaterialInput';
import Logo from '../../assets/svg/NewLogo';
import LoginHead from '../../assets/svg/LoginHead';
import { LanguageContext } from '../../context/language';

const LoginScreen = () => {
  const { t } = useContext(LanguageContext);
  const { login, mainLoading, constants } = useContext(MainContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const handleChange = field => ({ target }) => {
    switch (field) {
      case 'username':
        setUsername(target.value);
        break;

      case 'password':
        setPassword(target.value);
        break;

      case 'remember':
        setRemember(prevState => !prevState);
        break;

      default:
        break;
    }
  };

  return (
    <Spin spinning={mainLoading} size="large">
      <ContainerBlock>
        <Logo className="login_logo" />
        <LoginWrapper>
          <div className="svg_wrapper">
            <LoginHead />
          </div>
          <LoginStyle remember={remember}>
            <div className="login_form">
              <MaterialInput
                label={t(constants.USERNAME)}
                value={username}
                onChange={handleChange('username')}
                type="text"
              />

              <MaterialInput
                label={t(constants.PASSWORD)}
                value={password}
                onChange={handleChange('password')}
                type="password"
              />

              <div className="check_box_group">
                <div onClick={handleChange('remember')} className="check_box" />
                <p className="label">{t(constants.LOGIN_REMEMBER)}</p>
              </div>
            </div>
            <Button
              onClick={() => {
                login({ username, password, remember });
              }}
              className="login_btn"
            >
              {t(constants.LOGIN_BUTTON)}
            </Button>
          </LoginStyle>
        </LoginWrapper>
      </ContainerBlock>
    </Spin>
  );
};

export default LoginScreen;
