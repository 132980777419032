import React from 'react';
import { white, secondary, primary } from '../../style/color';

export default ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new00512512' }}
    xmlSpace="preserve"
    width="512px"
    height="512px"
  >
    <g>
      <g>
        <path
          d="M472,72H40C17.944,72,0,89.944,0,112v288c0,22.056,17.944,40,40,40h432c22.056,0,40-17.944,40-40V112   C512,89.944,494.056,72,472,72z M16,400V136h104v288H40C26.766,424,16,413.233,16,400z M472,424H136V136h312c4.418,0,8-3.582,8-8   s-3.582-8-8-8H16v-8c0-13.233,10.766-24,24-24h432c13.234,0,24,10.767,24,24v8h-16c-4.418,0-8,3.582-8,8s3.582,8,8,8h16v264   C496,413.233,485.234,424,472,424z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M40,96c-2.1,0-4.17,0.85-5.66,2.34C32.85,99.83,32,101.89,32,104c0,2.109,0.85,4.17,2.34,5.66   C35.83,111.149,37.9,112,40,112s4.16-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66   C44.17,96.85,42.1,96,40,96z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M64,96c-2.1,0-4.17,0.85-5.66,2.34C56.85,99.83,56,101.89,56,104c0,2.109,0.85,4.17,2.34,5.66   C59.83,111.149,61.9,112,64,112s4.16-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66   C68.17,96.85,66.1,96,64,96z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M88,96c-2.1,0-4.17,0.85-5.66,2.34C80.85,99.83,80,101.89,80,104c0,2.109,0.85,4.17,2.34,5.66   C83.83,111.149,85.9,112,88,112s4.17-0.851,5.66-2.34C95.15,108.17,96,106.1,96,104c0-2.101-0.85-4.17-2.34-5.66   C92.17,96.85,90.11,96,88,96z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M40,168h56c4.418,0,8-3.582,8-8s-3.582-8-8-8H40c-4.418,0-8,3.582-8,8S35.582,168,40,168z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M96,184H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h56c4.418,0,8-3.582,8-8S100.418,184,96,184z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M96,216H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h56c4.418,0,8-3.582,8-8S100.418,216,96,216z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M72,248H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S76.418,248,72,248z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M72,280H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S76.418,280,72,280z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M48,312h-8c-4.418,0-8,3.582-8,8s3.582,8,8,8h8c4.418,0,8-3.582,8-8S52.418,312,48,312z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M216,240c10.684,0,20.729,4.16,28.284,11.716c3.124,3.123,8.189,3.123,11.313,0c3.125-3.125,3.125-8.189,0-11.314   c-21.834-21.834-57.362-21.832-79.195,0C165.825,250.979,160,265.042,160,280s5.825,29.021,16.402,39.599   c10.917,10.917,25.257,16.375,39.598,16.375s28.681-5.458,39.598-16.375c3.125-3.125,3.125-8.189,0-11.314   c-3.124-3.123-8.189-3.123-11.313,0C236.729,315.84,226.684,320,216,320s-20.729-4.16-28.284-11.716   C180.161,300.729,176,290.685,176,280s4.161-20.729,11.716-28.284C195.271,244.16,205.316,240,216,240z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M328.021,300.668c14.125-6.18,24.019-20.29,24.019-36.668c0-22.056-17.944-40-40-40H280c-4.418,0-8,3.582-8,8   c0,0.268,0.013,0.533,0.039,0.795V328c0,4.418,3.582,8,8,8s8-3.582,8-8v-88h24c13.233,0,24,10.767,24,24s-10.767,24-24,24   c-3.236,0-6.153,1.949-7.391,4.938s-0.554,6.431,1.734,8.719l32,32c1.562,1.562,3.609,2.343,5.657,2.343s4.095-0.781,5.657-2.343   c3.125-3.125,3.125-8.189,0-11.314L328.021,300.668z"
          className="active-path"
          fill={active ? white : secondary}
        />
        <path
          d="M458.046,224.256c-3.529-0.91-7.23,0.672-9,3.859l-33.007,59.412l-33.007-59.412c-1.77-3.188-5.47-4.775-9-3.859   c-3.529,0.914-5.993,4.099-5.993,7.744v96c0,4.418,3.582,8,8,8s8-3.582,8-8v-65.127l25.007,45.012   c1.411,2.54,4.088,4.115,6.993,4.115s5.583-1.575,6.993-4.115l25.007-45.012V328c0,4.418,3.582,8,8,8s8-3.582,8-8v-96   C464.039,228.354,461.575,225.17,458.046,224.256z"
          className="active-path"
          fill={active ? white : secondary}
        />
      </g>
    </g>
  </svg>
);
