import React from 'react';

const Logo = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 635.2 177.47"
    width="635.2px"
    height="177.47px"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            d="M437.8,169.5v2.8H427.7c-16.9-.5-25.2-6-36.7-24.7-5.2-8.4-11.1-18.3-11.1-18.3,11.1-.5,22.5-5.6,22.5-18.3,0-9.6-5.1-18.2-16.8-18.2a58.54,58.54,0,0,0-9.6,1.1v63.5c0,6.4.8,8.7,1.5,9.5,1.4,1.7,3.6,2.5,6.4,2.5h4v3H350v-3h3.8c3.2,0,5.5-1.1,6.9-3.2.5-.8,1.2-3,1.2-8.8v-55c0-6.4-.8-8.7-1.5-9.5-1.4-1.7-3.6-2.5-6.5-2.5h-3.8v-3h32.3c9.9,0,16,.1,19.9,1.3,7.1,2.2,15.5,8.5,15.5,21.5,0,9.6-5.8,19-21,22.6,0,0,6.6,10.9,12.5,18.9,8.2,11.5,16.6,16.3,28.5,17.8"
            fill="#37160d"
          />
          <path
            d="M177,90.4h3.7c4.2.1,7,2.3,7.4,3.7s.7,4.1.7,8.3v55c0,6.1-.7,8.4-1.3,9.2-1.4,1.9-3.7,2.8-6.9,2.8h-3.7v3h37.9v-3h-3.7c-2.7,0-6.5-1.4-7.4-3.7-.5-1.3-.7-4.1-.7-8.3v-55c0-6.1.7-8.4,1.4-9.2,1.4-1.9,3.7-2.8,6.8-2.8h3.7v-3H177Z"
            fill="#37160d"
          />
          <path
            d="M177,90.4h3.7c4.2.1,7,2.3,7.4,3.7s.7,4.1.7,8.3v55c0,6.1-.7,8.4-1.3,9.2-1.4,1.9-3.7,2.8-6.9,2.8h-3.7v3h37.9v-3h-3.7c-2.7,0-6.5-1.4-7.4-3.7-.5-1.3-.7-4.1-.7-8.3v-55c0-6.1.7-8.4,1.4-9.2,1.4-1.9,3.7-2.8,6.8-2.8h3.7v-3H177Z"
            fill="#37160d"
          />
          <path
            d="M604.8,87.4v3s3.8-.1,5.4.7a5,5,0,0,1,3,4.6c0,1.8-1.2,4.6-3.5,8.4l-15.6,25.3-16.6-26.5c-3-4.7-3.6-6.8-3.6-7.8,0-2.7,1.9-4.8,9.5-4.8v-3H545.6v2.9c4.2,0,6.6.9,9.7,4.2a77.68,77.68,0,0,1,5.4,7.7l22.5,35.5v19.9q0,6.9-1.2,8.7c-1.3,2-3.6,2.9-6.8,2.9h-3.7v3h37.6v-3h-3.4c-3.1,0-5.4-.8-6.8-2.4-.7-.8-1.5-3-1.5-9.3V136.3l19.8-32.2a53.37,53.37,0,0,1,6.1-8.7,18.08,18.08,0,0,1,5.3-4,11.72,11.72,0,0,1,6.6-1.4V87H604.8Z"
            fill="#37160d"
          />
          <path
            d="M530.4,166.7c-1.2-1.1-3.3-3.9-6.1-10.7L495.5,85.7h-4.1l-29.1,69.5c-2.5,6-4.7,10-6.7,11.9-.9.9-3,1.7-6.9,2.3v2.8h8.5c5.7,0,7.7-3.3,10.5-7.4,6.5-9.6,19.3-27.1,33.1-30l8.7,21.7c2,4.8,2.4,7.2,2.4,8.4,0,2.9-2.9,4.5-8.4,4.5v2.9h34.9v-2.9c-3.3-.1-6-.8-8-2.7M475,142.5l14.7-35.3,9.9,24.4c-9.3.6-18.1,5.8-24.6,10.9"
            fill="#37160d"
          />
          <path
            d="M319.7,166.7c-1.2-1.1-3.3-3.9-6.1-10.7L284.8,85.7h-4.1l-29.1,69.5c-2.5,6-4.7,10-6.7,11.9-.9.9-3,1.7-6.9,2.3v2.8h8.5c5.7,0,7.7-3.3,10.5-7.4,6.5-9.6,19.3-27.1,33.1-30l8.7,21.7c2,4.8,2.4,7.2,2.4,8.4,0,2.9-2.9,4.5-8.4,4.5v2.9h34.9v-2.9c-3.3-.1-5.9-.8-8-2.7m-55.4-24.2L279,107.2l9.9,24.4c-9.3.6-18,5.8-24.6,10.9"
            fill="#37160d"
          />
          <path
            d="M134.7,67.3c-9.5,2.6-16.3,7.1-24,18.9-6.7,10.4-34.1,58-34.1,58L63.3,85.7H49.6c-1.1,11.4-4.5,32.8-7.1,43.3-7.4,29.9-19.8,41.8-31.6,43A20.54,20.54,0,0,1,0,170.6v3.1c14.8,6.8,31,4.5,40-7.7,7.8-10.6,11.2-19.6,14.7-31.3.7-2.7,4-15.7,4.5-20.3L74,172.3h4.9l35.6-72.6h.5v57.6c0,5.8-.7,8.1-1.2,8.9-1.3,2-3.6,3-7,3h-3.7v3h38.3v-3h-3.7c-3.1,0-5.3-.8-6.7-2.4-.7-.8-1.5-3.1-1.5-9.5V85.9c0-6.4,11.4-14.7,21.3-14.8,3.9,0,6.6.8,10.5,1.9V70.3c-6.2-2.5-17.7-5.4-26.6-3"
            fill="#37160d"
          />
          <polygon
            points="223.1 26.8 205.7 0.3 200.1 0.3 200.1 39 206.2 39 206.2 12.4 223.7 39 229.3 39 229.3 0.3 223.1 0.3 223.1 26.8"
            fill="#37160d"
          />
          <path
            d="M166.8.3h-4.9L147.7,38.9h6.6l2.6-7.7h14.9l2.6,7.7h6.5ZM170,26H158.7l5.7-16.3Z"
            fill="#37160d"
          />
          <polygon
            points="336.2 5.9 346.8 5.9 346.8 39 352.9 39 352.9 5.9 363.5 5.9 363.5 0.3 336.2 0.3 336.2 5.9"
            fill="#37160d"
          />
          <rect x="466.1" y="0.3" width="6.1" height="38.6" fill="#37160d" />
          <path
            d="M309.1,16.7c-6.1-3.3-6.6-5.1-6.6-7.2,0-2.6,2.3-4.2,5.8-4.2A13.87,13.87,0,0,1,318,9.5V2.8A18.12,18.12,0,0,0,308.5,0c-7,0-12.2,4.1-12.2,9.7,0,4.8,2.3,7.8,8.4,11.2,7.9,4.3,8.7,6,8.7,8.6,0,2.8-2.4,4.9-5.8,4.9a13.87,13.87,0,0,1-11.1-5.9V36l.1.1a19.46,19.46,0,0,0,11.1,3.5c6.9,0,12-4.3,12-10.1-.1-5.1-1.5-7.9-10.6-12.8"
            fill="#37160d"
          />
          <path
            d="M266.9.3H262L247.8,38.9h6.5l2.6-7.7h14.9l2.6,7.7h6.5ZM270.2,26H258.9l5.7-16.3Z"
            fill="#37160d"
          />
          <path
            d="M436,16.7c-6.1-3.3-6.6-5.1-6.6-7.2,0-2.6,2.3-4.2,5.8-4.2a13.87,13.87,0,0,1,9.7,4.2V2.8A18.12,18.12,0,0,0,435.4,0c-7,0-12.2,4.1-12.2,9.7,0,4.8,2.3,7.8,8.4,11.2,7.9,4.3,8.7,6,8.7,8.6,0,2.8-2.4,4.9-5.8,4.9a13.87,13.87,0,0,1-11.1-5.9V36l.1.1a19.46,19.46,0,0,0,11.1,3.5c6.9,0,12-4.3,12-10.1,0-5.1-1.5-7.9-10.6-12.8"
            fill="#37160d"
          />
          <path
            d="M393.8.3h-4.9L374.7,38.9h6.6l2.6-7.7h14.9l2.6,7.7h6.5ZM397.1,26H385.8l5.7-16.3Z"
            fill="#37160d"
          />
          <path
            d="M510.4.3h-4.9L491.3,38.9h6.6l2.6-7.7h14.9l2.6,7.7h6.6ZM513.7,26H502.4l5.7-16.3Z"
            fill="#37160d"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
