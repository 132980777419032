import React, { useState, useEffect, useRef } from 'react';
import { Table, Icon } from 'antd';
import _ from 'lodash';

import moment from 'moment';
import {
  CardBlock,
  Card,
  CardActive,
  StaffLabel,
  AccountType,
  CustomerDetail,
  TableBlock,
  TimeText,
  IconExpand,
  BookingBy,
} from './style';
import { PlusBlock } from '../../components/CardOfPlaning/style';
import Plus from '../../assets/svg/Plus';
import Booking from '../../assets/svg/Booking';
import { minutesDuration } from '../../utils';
import Pencil from '../../assets/svg/Pencil';

const isHalf = roomType => roomType === 'half';
const getTimeActive = (dateSelect, obj) => {
  const numberOfTimeStart = _.get(obj, ['branchPeriod', 'time'], 0);
  const numberOfTimeEnd = _.get(obj, ['branchPeriod', 'time'], 0)
    + _.get(obj, ['branchPeriod', 'duration'], 0);
  const timeStart = moment().startOf('day').add(numberOfTimeStart, 'm');
  const timeEnd = moment().startOf('day').add(numberOfTimeEnd, 'm');

  const currentTime = moment();
  const currentDateFormat = moment().format('YYYY-MM-DD');

  const timeActive = currentTime.isBetween(timeStart, timeEnd);

  if (moment(currentDateFormat).isSame(dateSelect) && timeActive) {
    return 0;
  }
  if (
    moment(currentDateFormat).isAfter(dateSelect)
    || (moment(currentDateFormat).isSame(dateSelect)
      && currentTime.isAfter(timeStart))
    // && currentTime.isAfter(timeStart)
  ) {
    return -1;
  }
  return 1;
};

const BookingCard = ({ children, disable, highlight }) => (
  <CardActive disable={disable} highlight={highlight}>
    {children}
  </CardActive>
);

const BookingTable = ({
  data,
  tableBlockWidth,
  handleClickRoom,
  bookedData,
  currentFilter,
  currentDate,
}) => {
  const clockRef = useRef(null);
  const [current, setCurrent] = useState(new Date());
  const [halfRoomLength, setHalfRoomLength] = useState(3);
  // // const [halfRoomLength, setHalfRoomLength] = useState(0);
  // useEffect(() => {
  //   // setTimeout(() => {
  //   //   setHalfRoomLength(0);
  //   // setTimeout(() => {
  //   //   setHalfRoomLength(3);
  //   // }, 10000);
  //   // }, 10000);
  // }, []);

  useEffect(() => {
    clockRef.current = setInterval(() => {
      setCurrent(new Date());
    }, 30000);

    return () => clockRef.current && clearInterval(clockRef.current);
    // console.log('clear', { clockRef });
  }, []);

  const rangeOdRooms = _.get(data, 'rooms', 0);
  const branchPeriods = _.get(data, 'branch_periods');

  const getBooked = ({ branchPeriod }, room, roomType) => {
    const matchBookedPeriods = _.filter(bookedData, (o) => {
      if (roomType === 'normal' && o.booking_type) {
        return false;
      }
      if (roomType === 'half' && !o.booking_type) {
        return false;
      }
      return o.booking_time === branchPeriod.time;
    });

    const matchCancelBooked = _.remove(
      matchBookedPeriods,
      o => o.status === 'cancel'
    );

    let bookedMatch = _.find(
      matchBookedPeriods,
      o => o.booking_room === room || o.half_slot === room
    );

    let bookedCancelMatch = _.find(
      matchCancelBooked,
      o => o.booking_room === room || o.half_slot === room
    );

    if (!bookedMatch) {
      const matchBookedByIndex = _.get(matchBookedPeriods, [room - 1]);

      if (
        !_.get(matchBookedByIndex, ['booking_room'])
        && !_.get(matchBookedByIndex, ['half_slot'])
      ) {
        bookedMatch = matchBookedByIndex;
      }
    }

    if (!bookedCancelMatch) {
      const matchBookedCancelByIndex = _.get(matchCancelBooked, [room - 1]);
      if (
        !_.get(matchBookedCancelByIndex, ['booking_room'])
        && !_.get(matchBookedCancelByIndex, ['half_slot'])
      ) {
        bookedCancelMatch = _.get(matchCancelBooked, [room - 1]);
      }
    }

    return { bookedMatch, bookedCancelMatch };
  };

  const dataSource = _.map(branchPeriods, branchPeriod => ({
    branchPeriod,
    roomIndex: 1,
  }));

  const colWidth = tableBlockWidth
    ? Math.max(140, _.floor(tableBlockWidth / (rangeOdRooms + halfRoomLength)))
    : 140;

  const renderCard = (rowData, room, roomType) => {
    // console.log({ currentDate });
    const available = _.toSafeInteger(_.get(rowData, ['branchPeriod', 'available'])) >= 1
      || isHalf(roomType);

    const checkRoomActive = isHalf(roomType)
      || _.get(rowData, ['branchPeriod', 'duration'], 0) === 60;

    const timeActive = getTimeActive(currentDate, rowData);

    const { bookedMatch, bookedCancelMatch } = getBooked(
      rowData,
      room,
      roomType
    );

    // console.log({
    //   available,
    //   checkRoomActive,
    //   timeActive,
    //   rowData,
    //   room,
    //   roomType
    // });

    let filterBooked = null;

    if (bookedMatch || bookedCancelMatch) {
      switch (currentFilter) {
        case 'pending':
          filterBooked = _.get(bookedMatch, 'status') === 'pending' ? bookedMatch : null;
          break;
        case 'confirm':
          filterBooked = _.get(bookedMatch, 'status') === 'confirm' ? bookedMatch : null;
          break;
        case 'cancel':
          filterBooked = bookedCancelMatch;
          break;
        case 'show':
          filterBooked = _.get(bookedMatch, 'show_status') === 'show' ? bookedMatch : null;
          break;
        case 'no_show':
          filterBooked = _.get(bookedMatch, 'show_status') === 'no_show'
            ? bookedMatch
            : null;
          break;
        default:
          // filterBooked = bookedMatch || bookedCancelMatch;
          filterBooked = bookedMatch;
          break;
      }
    }

    let roomEl = null;
    if (!checkRoomActive) {
      roomEl = <Card />;
    } else if (!filterBooked) {
      roomEl = (
        <Card>
          {(isHalf(roomType)
            ? available && timeActive !== -1
            : available
              && timeActive === 1 /* : available && timeActive !== -1 */) ? (
          // : available && timeActive !== -1
                <PlusBlock>
                  <Plus width="10px" height="10px" />
                </PlusBlock>
            ) : <> </>}
        </Card>
      );
    } else {
      let bookingBy = _.get(filterBooked, ['booking_by']);
      switch (_.toLower(bookingBy)) {
        case 'next booking':
          bookingBy = 'New 1,600';
          break;
        case 'phone':
          bookingBy = 'Admin';
          break;

        default:
          break;
      }

      roomEl = (
        <BookingCard
          // created={_.get(filterBooked, ['created'], null)}
          // highlight={_.get(filterBooked, ['wait_for_accept'], false)}
          highlight={_.get(filterBooked, ['status']) === 'pending'}
          type="show"
          disable={
            isHalf(roomType) ? timeActive === -1 : timeActive !== 1
            // : timeActive === -1
          }
        >
          {(isHalf(roomType)
            ? checkRoomActive && available && timeActive !== -1
            : checkRoomActive
              && available
              && timeActive
                === 1 /* : checkRoomActive && available && timeActive !== 1 */) && (
          // : checkRoomActive && available && timeActive !== 1
                <Pencil className="booking_pencil" />
          )}
          <StaffLabel>
            {_.get(filterBooked, ['staff', 'nickname'], 'ไม่ระบุ')}
          </StaffLabel>
          <AccountType
            style={{ fontSize: '18px', fontWeight: 500, marginBottom: 0 }}
            type="black"
          >
            {`Ac ${_.get(filterBooked, ['customer', 'customer_no'], '')}`}
          </AccountType>
          <CustomerDetail
            style={{ fontSize: '18px', marginBottom: 0 }}
            className="first-child"
          >
            {_.get(filterBooked, ['customer', 'first_name'])}
          </CustomerDetail>
          <CustomerDetail style={{ fontSize: '14px', marginBottom: 0 }}>
            {_.get(filterBooked, ['customer', 'telephone'])}
          </CustomerDetail>
          {/* currentFilter === 'show' || currentFilter === 'no_show' ? (
            <AccountType type={_.get(filterBooked, ['show_status'])}>
              {_.get(filterBooked, ['show_status'])}
            </AccountType>
          ) : (
            <AccountType type={_.get(filterBooked, ['status'])}>
              {_.get(filterBooked, ['status'])}
            </AccountType>
          ) */}
          <AccountType
            style={{ marginBottom: 0 }}
            type={_.get(filterBooked, ['status'])}
          >
            {_.get(filterBooked, ['status'])}
          </AccountType>
          <BookingBy
            style={{ marginBottom: 0 }}
            type={_.toLower(bookingBy)}
          >
            {`${_.upperFirst(_.toLower(bookingBy))}`}
          </BookingBy>
          <AccountType
            style={{ marginBottom: 0 }}
            type={_.get(filterBooked, ['show_status'])}
          >
            {_.get(filterBooked, ['show_status'])}
          </AccountType>
          {!!_.get(filterBooked, ['is_new']) && (
            <AccountType
              style={{ fontSize: '14px', marginBottom: 0 }}
              type="is_new"
            >
              NEW
            </AccountType>
          )}
        </BookingCard>
      );
    }

    return (
      <CardBlock
        active={
          isHalf(roomType)
            ? checkRoomActive && available && timeActive !== -1
            : checkRoomActive && available && timeActive === 1
          // : checkRoomActive && available && timeActive !== -1
        }
        onClick={
          (isHalf(roomType)
            ? checkRoomActive && available && timeActive !== -1
            : checkRoomActive
              && available
              && timeActive
                === 1 /* : checkRoomActive && available && timeActive !== -1 */)
          // : checkRoomActive && available && timeActive !== -1
          || !!filterBooked
            ? handleClickRoom({
              type: roomType === 'half' && 'half',
              room,
              bookedMatch: filterBooked,
              time: _.get(rowData, ['branchPeriod', 'time'], 0),
            })
            : () => {}
        }
      >
        {roomEl}
      </CardBlock>
    );
  };

  const branchPeriodColumns = _.map(_.range(rangeOdRooms), (roomIndex) => {
    const room = roomIndex + 1;
    return {
      title: `Room #${room}`,
      key: `room-${room}`,
      width: colWidth,
      render: rowData => renderCard(rowData, room, 'normal'),
    };
  });

  const halfColumn = rangeOdRooms
    ? _.map(_.range(halfRoomLength), (count) => {
      const roomNumber = count + 1;
      return {
        title: 'Half',
        key: `half${roomNumber}`,
        fixed: 'right',
        width: colWidth,
        render: rowData => renderCard(rowData, roomNumber, 'half'),
      };
    })
    : [];

  const columns = rangeOdRooms
    ? [
      {
        title: '',
        width: 174,
        key: 'name',
        fixed: 'left',
        render: (rowData) => {
          const activeTime = getTimeActive(currentDate, rowData);
          const duration = _.get(rowData, ['branchPeriod', 'duration'], '');
          const currentTimeString = _.get(
            rowData,
            ['branchPeriod', 'time_format'],
            ''
          );
          const currentTime = moment(currentTimeString, 'H:mm');
          const nextTime = currentTime.add(duration, 'minute');
          const nextTimeString = nextTime.format('H:mm');
          return (
            <CardBlock active>
              <TimeText>
                {activeTime === 0 && (
                <Booking
                  width="17px"
                  height="17px"
                  style={{ marginRight: 8 }}
                />
                )}
                {/* `${currentTimeString} - ${nextTimeString}` */`${currentTimeString}`}
              </TimeText>
            </CardBlock>
          );
        },
      },
      ...branchPeriodColumns,
      ...halfColumn,
    ]
    : [];

  // useEffect(() => {
  //   console.log({ bookedData });
  // });

  return (
    <>
      <TableBlock>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // scroll={{ x: 'max-content', y: 300 }}
          scroll={{
            x: rangeOdRooms && colWidth * (rangeOdRooms + halfRoomLength) + 174,
            y: 500,
          }}
        />
      </TableBlock>
    </>
  );
};

export default BookingTable;
