import React, { useContext, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PT from 'prop-types';
import _ from 'lodash';
import { MainContext } from '../../context/main';
import Logo from '../../assets/svg/NewLogo';
import { HeaderStyle } from './style';
import Dropdown from '../Dropdown';
import { TextBold } from '../../style/fontStyle';
import { purple } from '../../style/color';

const Header = ({ location }) => {
  const { handleSelectBranch, currentBranch, branches, routes } = useContext(
    MainContext
  );

  const currentRouteObj = _.find(
    routes,
    o => _.get(o, ['path']) === location.pathname
  );

  const isBranchesFilter = _.get(currentRouteObj, ['isBranchesFilter']);

  const clockRef = useRef(null);
  const [current, setCurrent] = useState(new Date());

  useEffect(() => {
    clockRef.current = setInterval(() => {
      setCurrent(new Date());
    }, 60000);

    return () => clockRef.current && clearInterval(clockRef.current);
  }, []);

  return (
    <HeaderStyle>
      <div className={`dropdown_wrapper ${isBranchesFilter ? 'visible' : ''}`}>
        <Dropdown
          current={currentBranch}
          onClick={handleSelectBranch}
          data={branches}
        />
      </div>
      <Logo className="header_logo" />
      <div className="util_wrapper">
        <span className="clock_wrapper">
          <TextBold color={purple}>{moment(current).format('H')}</TextBold>
          <TextBold color={purple} className="clock_divide">
            {' '}
            :
            {' '}
          </TextBold>
          <TextBold color={purple}>{moment(current).format('mm')}</TextBold>
        </span>
      </div>
    </HeaderStyle>
  );
};

export default withRouter(Header);
