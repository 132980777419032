import React, { useState, useContext } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { DateBlock, CalendarBlock, TextUnderLine } from './style';
import LeftArrow from '../../assets/svg/LeftArrow';
import { TextBold, TextNormal } from '../../style/fontStyle';
import RightArrow from '../../assets/svg/RightArrow';
import { purple } from '../../style/color';
import { LanguageContext } from '../../context/language';
import { MainContext } from '../../context/main';

export const useBigPicker = () => {
  const [current, setCurrent] = useState(moment());

  return {
    current,
    setCurrent
  };
};

const { MonthPicker } = DatePicker;

const dateFormat = 'DD, MMMM, YYYY';
const monthFormat = 'MMMM, YYYY';

const MonthPickerComponent = ({ current, setCurrent, mode }) => {
  const { t, lang } = useContext(LanguageContext);
  const { constants } = useContext(MainContext);

  const [pickerOpen, setPickerOpen] = useState(false);

  const setNext = () => {
    const next = moment(current).add(1, mode === 'full' ? 'day' : 'months');
    setCurrent(next);
  };
  const setPrev = () => {
    const prev = moment(current).subtract(
      1,
      mode === 'full' ? 'day' : 'months'
    );
    setCurrent(prev);
  };

  const onChange = (date) => {
    setCurrent(date);
    setPickerOpen(false);
  };

  return (
    <DateBlock>
      <CalendarBlock>
        <LeftArrow onClick={setPrev} />
        <TextBold>
          {moment(current)
            .locale(lang)
            .format(mode === 'full' ? dateFormat : monthFormat)}
        </TextBold>
        <RightArrow onClick={setNext} />
      </CalendarBlock>
      {mode === 'full' ? (
        <DatePicker
          style={{ width: '278px' }}
          open={pickerOpen}
          defaultValue={current}
          value={current}
          onChange={onChange}
        />
      ) : (
        <MonthPicker
          style={{ width: '278px' }}
          open={pickerOpen}
          defaultValue={current}
          value={current}
          onChange={onChange}
        />
      )}
      <TextUnderLine
        onClick={() => {
          setPickerOpen(!pickerOpen);
        }}
      >
        <TextNormal fontSize="12px" color={purple}>
          {mode === 'full'
            ? t(constants.CLICK_TO_CHANGE_DATE)
            : t(constants.CLICK_TO_CHANGE_MONTH)}
        </TextNormal>
      </TextUnderLine>
    </DateBlock>
  );
};

export default MonthPickerComponent;
