import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Spin,
  Button,
  Modal,
  Select,
  Table,
  Row,
  Col,
  Switch,
} from 'antd';
import moment from 'moment';
import { MainContext } from '../../context/main';
import { isPassword, getTime, isPhoneNumber } from '../../utils';
import { LanguageContext } from '../../context/language';
import PosedButton from '../../components/Button';
import { TextNormal, TextBold } from '../../style/fontStyle';
import { purple, gray } from '../../style/color';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

const DynamicFieldSet = ({ customerId, form, open, toggle, onSubmit }) => {
  const { t } = useContext(LanguageContext);
  const [customer, setCustomer] = useState(null);

  const [isGetDataLoading, setIsGetDataLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { request, constants } = useContext(MainContext);

  const getData = async () => {
    if (!customerId) {
      setCustomer(null);

      return;
    }
    setIsGetDataLoading(true);
    const res = await request('/customers', {
      query: {
        id: customerId,
        includes: ['last_services', 'concent'],
      },
    });
    console.log({ res });

    const referenceData = _.get(res, ['reference_data', 0], {});

    setIsGetDataLoading(false);
    setCustomer(referenceData);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      getData();
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let body = {};

        if (!customer) {
          body = _.omitBy({ ...values }, v => v === '');
        } else {
          body.id = customer.id;

          if (values.first_name !== customer.first_name) {
            body.first_name = values.first_name;
          }

          if (values.last_name !== customer.last_name) {
            body.last_name = values.last_name;
          }

          if (values.telephone !== customer.telephone) {
            body.telephone = values.telephone;
          }

          if (values.email !== customer.email) {
            body.email = values.email;
          }

          if (values.customer_no !== customer.customer_no) {
            body.customer_no = values.customer_no;
          }

          if (customer.concent === 'accept' && !values.concent) {
            body.concent = 'deny';
          }
        }
        console.log({ body, values });

        setIsSubmitLoading(true);

        const res = await request('/customers', {
          method: customer ? 'put' : 'post',
          body,
        });

        setIsSubmitLoading(false);
        toggle();
        onSubmit();
      }
    });
  };

  const columns = [
    {
      title: t(constants.DATE),
      dataIndex: 'booking_date',
      key: 'booking_date',
      render: d => moment(d).format('DD/MM/YYYY'),
    },
    {
      title: t(constants.TIME),
      dataIndex: 'booking_time',
      key: 'booking_time',
      render: time => getTime(time),
    },
    {
      title: t(constants.BRANCH),
      key: 'branch',
      render: o => _.get(o, ['branch', 'name']),
    },
    {
      title: t(constants.STAFF),
      key: 'staff',
      render: o => _.get(o, ['staff', 'nickname']),
    },
    {
      title: t(constants.STATUS),
      key: 'status',
      render: o => _.get(o, ['status']),
    },
  ];

  const { getFieldDecorator } = form;

  return (
    <Modal
      width={650}
      visible={open}
      title={customer ? t(constants.EDIT_CUSTOMER) : t(constants.ADD_CUSTOMER)}
      onCancel={toggle}
      footer={[
        <Button key="back" onClick={toggle}>
          {t(constants.CANCEL)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
        >
          {t(constants.SUBMIT)}
        </Button>,
      ]}
    >
      <Spin spinning={isGetDataLoading} size="large">
        <Row>
          <TextBold color={gray}>{t(constants.CUSTOMER_INFO)}</TextBold>
        </Row>
        <Form>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={
                  <TextNormal color={purple}>{t(constants.NAME)}</TextNormal>
                }
                hasFeedback
              >
                {getFieldDecorator('first_name', {
                  initialValue: _.get(customer, 'first_name'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: false,
                      message: t(constants.ERROR_NAME),
                    },
                  ],
                })(<Input placeholder={t(constants.INPUT_NAME)} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={(
                  <TextNormal color={purple}>
                    {t(constants.LAST_NAME)}
                  </TextNormal>
)}
                hasFeedback
              >
                {getFieldDecorator('last_name', {
                  initialValue: _.get(customer, 'last_name'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: false,
                      message: t(constants.ERROR_LAST_NAME),
                    },
                  ],
                })(<Input placeholder={t(constants.INPUT_LAST_NAME)} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={
                  <TextNormal color={purple}>{t(constants.PHONE)}</TextNormal>
                }
                hasFeedback
              >
                {getFieldDecorator('telephone', {
                  initialValue: _.get(customer, 'telephone'),
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      pattern: isPhoneNumber,
                      whitespace: false,
                      message: t(constants.ERROR_PHONE),
                    },
                  ],
                })(<Input placeholder={t(constants.INPUT_PHONE)} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={(
                  <TextNormal color={purple}>
                    {t(constants.CUSTOMER_NO)}
                  </TextNormal>
)}
                hasFeedback
              >
                {getFieldDecorator('customer_no', {
                  initialValue: _.get(customer, 'customer_no'),
                })(<Input placeholder={t(constants.CUSTOMER_NO)} />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={<TextNormal color={purple}>{t(constants.EMAIL)}</TextNormal>}
            hasFeedback
          >
            {getFieldDecorator('email', {
              initialValue: _.get(customer, 'email'),
            })(<Input placeholder={t(constants.INPUT_EMAIL)} />)}
          </Form.Item>
          <Form.Item
            label={<TextNormal color={purple}>{t(constants.PDPA)}</TextNormal>}
          >
            {getFieldDecorator('concent', {
              initialValue: _.get(customer, 'concent') === 'accept',
              valuePropName: 'checked',
            })(<Switch disabled={_.get(customer, 'concent') !== 'accept'} />)}
          </Form.Item>
        </Form>
        <Row>
          <TextBold color={gray}>{t(constants.BOOKING_HISTORY)}</TextBold>
        </Row>

        <Table
          className="components-table-demo-nested"
          rowKey="id"
          columns={columns}
          dataSource={_.get(customer, ['last_services'], [])}
        />
      </Spin>
    </Modal>
  );
};

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(
  DynamicFieldSet
);

export default WrappedDynamicFieldSet;
